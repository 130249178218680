import React, {useEffect, useState, useCallback} from 'react';
import YalangoSvg from '../../assets/svg/yalango/yalango.svg';
import YalangoBook from '../../assets/svg/yalango/yalango_book.svg';
import YalangoQuestion from '../../assets/svg/yalango/yalango_question.svg';
import YalangoThumbsUp from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import YalangoComputer from '../../assets/svg/yalango/yalango_computer.svg';
import YalangoAI from '../../assets/svg/yalango/YalangoAI.svg';
import YalangoJourney from '../../assets/svg/yalango/YalangoJourney.svg';
import PhoneDashboard from '../../assets/img/frontpage/phone_dashboard_tilt.webp';
import ComputerLibrary from '../../assets/img/frontpage/my_library_browser.webp';
import DeckImage from '../../assets/img/frontpage/deck_2024.png';
import GamesOverview from '../../assets/img/frontpage/games_overview.webp';
import DashboardImage from '../../assets/img/frontpage/dashboard_clean_2024.png';
import QuizImage from '../../assets/img/frontpage/quiz_2024.png'; 
import FlashcardsImage from '../../assets/img/frontpage/flashcards_2024.png'; 
import InputImage from '../../assets/img/frontpage/input_2024.png'; 
import HanziImage from '../../assets/img/frontpage/hanzi_2024.png'; 
import ProgressImage from '../../assets/img/frontpage/progress_2024.png';
import ExploreImage from '../../assets/img/frontpage/explore_2024.png';
import LanguagesImage from '../../assets/img/frontpage/languages_2024.png';
import ImportImage from '../../assets/img/frontpage/import_2024.png';

import {faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import useStatistics  from '../../hooks/useStatistics';
import CountryFlag from '../../components/languages/CountryFlag';
import ReactTooltip from 'react-tooltip';
import {faGamepad} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';
import Button from '../../components/general/Button';
import {useAuth} from '../../contexts/AuthContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import {faInfo} from '@fortawesome/pro-duotone-svg-icons';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {faCardHeart} from '@fortawesome/pro-solid-svg-icons';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {faHouseHeart, faCircleDot} from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';
import HighlightImage from '../../assets/img/frontpage/frontpage-highlight.jpg';
import DashboardScreenshot from '../../assets/img/frontpage/dashboard-screenshot.png';
import Skeleton from 'react-loading-skeleton';
import RowOfSpecificDecks from '../vocabulary/components/RowOfSpecificDecks';
import RowOfSpecificTexts from '../texts/components/RowOfSpecificTexts';

const FrontPageHero = () => {
    const {allLanguages, languageFamilyOptions, selectedLanguageFamily, setSelectedLanguageFamily} = useLanguagesContext();
    const [shownLanguages, setShownLanguages] = useState(null);
    const [languagesLoading, setLanguagesLoading] = useState(true);
    const [logoLoaded, setLogoLoaded] = useState(false);
    const {getGlobalDeckGameStatsInRealtime} = useStatistics();
    const [gameStats, setGameStats] = useState(null);
    const [visualGameStats, setVisualGameStats] = useState(null);
    const [visualCountingStarted, setVisualCountingStarted] = useState(false);
    const [visualCountingEnded, setVisualCountingEnded] = useState(false);
    const {currentUser} = useAuth();
    const handleFamilyChange = (option) => {
        setSelectedLanguageFamily(option);
    } 

    useEffect(()=>{
        if (languageFamilyOptions !== null && allLanguages !== null){
            setLanguagesLoading(false);
            console.log(allLanguages);
/*             let list = [];
            for (const lang of Object.keys(allLanguages[0])){
                list.push(allLanguages[0][lang].country_flag);
            }
            console.log([...new Set(list)]); */
        }
    },[languageFamilyOptions, allLanguages]);

    useEffect(()=>{
        if (selectedLanguageFamily !== null && allLanguages !== null){
             if (selectedLanguageFamily.value === "all"){
                 setShownLanguages(allLanguages);
             } else {
                 let lang_codes = [];
                 let items = allLanguages[0];
                 for (const l of allLanguages[1]){
                    if (selectedLanguageFamily.value === allLanguages[0][l]['language_family']){
                        lang_codes.push(l);
                    }
                 }
                 setShownLanguages([items, lang_codes]);
             }
        }
    },[allLanguages, selectedLanguageFamily]);

    useEffect(()=>{
        let unsubscribe;
        const fetchData = async () => {
            unsubscribe = await getGlobalDeckGameStatsInRealtime(setGameStats);
        }
        fetchData();
        return () => {
            unsubscribe();
        }
    },[]);

    const equalObjects = useCallback((x, y) => {
        // checks if state has updated
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
          ok(x).length === ok(y).length &&
            ok(x).every(key => equalObjects(x[key], y[key]))
        ) : (x === y);
      },[]);

    useEffect(()=>{
        if (gameStats !== null){
            if (!visualCountingStarted){
                let copy = JSON.parse(JSON.stringify(gameStats));
                let next = {};
                for (const key of Object.keys(copy)){
                    next[key] = 0;
                }
                setVisualGameStats(next);
            } else {
                if (visualCountingEnded){
                    setVisualGameStats(gameStats);
                }
            }
        } 
    },[gameStats, visualGameStats, visualCountingStarted, visualCountingEnded]);
    

    useEffect(()=>{
        const sleep = m => new Promise(r => setTimeout(r, m));

        const runAnimation = async () => {
            setVisualCountingStarted(true);
            let gameSpeed = 100;
            let copy = JSON.parse(JSON.stringify(gameStats));
            for (let i=0; i<10000;i++){
                let next = {};
                for (const key of Object.keys(visualGameStats)){
                    next[key] = 0;
                }
                let changed = false;
                await sleep(10);
                if (visualGameStats !== null){
                    if (parseInt(i*gameSpeed) < (parseInt(gameStats['number_of_deck_games_answers'])+parseInt(gameStats['number_of_table_games_answers']))){
                        changed = true;
                        next['number_of_deck_games_answers'] = i*gameSpeed;
                    } else {
                        next['number_of_deck_games_answers'] = parseInt(gameStats['number_of_deck_games_answers'])+parseInt(gameStats['number_of_table_games_answers']);
                    }

                    if (i < parseInt(gameStats['total_number_of_users'])){
                        changed = true;
                        next['total_number_of_users'] = i;
                    } else {
                        next['total_number_of_users'] = parseInt(gameStats['total_number_of_users']);
                    }
                }
                setVisualGameStats(next);
                if (!changed){
                    break;
                }
            }
            setVisualCountingEnded(true);
        }
        
        
        if (visualGameStats !== null && gameStats !== null && visualCountingStarted === false && !visualCountingEnded){
            runAnimation();
        }

    },[visualGameStats, gameStats, visualCountingStarted, visualCountingEnded]);


    /* 
    <div className="m-auto p-8 mb-6">
                <h1 className="">Browse our languages</h1>
                <div className="text-center">Explore more on our <Link to="/languages">Languages page</Link>.</div>
                {!languagesLoading ?
                <>
                    <div className=" w-full ">
                        <div className="w-48 p-3 flex flex-col flex-grow">
                            <div className="font-bold text-gray-600">
                                Language family
                            </div>
                            <div>    
                                <Select onChange={handleFamilyChange} value={selectedLanguageFamily} options={languageFamilyOptions} autoFocus={false} placeholder="Language family" className=" text-gray-500 text-base" />
                            </div>
                            
                        </div>
                        {shownLanguages !== null &&
                            <div className="px-6  text-gray-700 mt-4 text-sm">
                                Showing {shownLanguages[1].length} {shownLanguages[1].length === 1 ? <>language</> : <>languages</>}
                            </div>
                        }
                        <div className="w-full p-2 min-h-48 max-h-96  overflow-y-auto m-auto grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8">
                        {shownLanguages !== null && shownLanguages[1].map((lang, index)=>(
                            <>   
                                <div key={"lang_"+index}>
                                    <Link  className="no-underline" to={'/learn/'+allLanguages[0][lang]['name']['en'].replace(' ', '-')}> 
                                        <motion.div key={"lang_div_"+index} whileHover={{scale:1.05}} className="flex shadow-lg p-3 rounded-3xl flex-col place-items-center">
                                            <div>
                                                <CountryFlag size="4em" countryCode={allLanguages[0][lang]['country_flag']} flagName={allLanguages[0][lang]['name']['en']} />
                                            </div>
                                            <div  className="text-center text-gray-700 font-bold">
                                                    {allLanguages[0][lang]['name']['en']}
                                            </div>
                                        </motion.div>
                                    </Link>
                                </div>
                            </>
                        ))
                        }
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="text-center italic p-4">Loading...</div>
                </>
                }
            </div>
            <h1>Why should I use Yalango?</h1>
            <div className="md:w-4/6 mx-auto">
                <p className="text-center ">Explore <Link to="/languages">incredible languages</Link> from all around the world, organize your new vocabulary and phrases, acquire new knowledge with <Link to="/decks">public decks</Link>, get detailed statistics about your language progression, create study circles with your friends (not possible yet), and much more.</p>   
            </div>

    */

    /*
    <div className="text-2xl text-gray-700 font-bold flex flex-row justify-center gap-6">
        <div>
            Home of <span className="font-bold text-persian-green">{visualGameStats !== null ? <>{visualGameStats['total_number_of_users'].toLocaleString()}</> : <>0</> }</span> language lovers
        </div>
    </div>
    */
    //<img alt="Yalango dashboard screenshot" className="w-1/2" src={DashboardScreenshot} />

    /*
                        <div className="text-white font-bold text-center text-base">There are <Link to="/decks?target=all&source=all">public decks</Link> too!</div>
                            <motion.div className="text-sm sm:text-base  muted-text w-full sm:w-4/6 lg:w-5/6  mx-auto">
                            It's <span className="font-bold muted-text">completely free</span> to use Yalango, but <span className="font-bold muted-text">$4</span> per month gives you access to additional premium features.
                        </motion.div>
                        */

    /* 
                    <div className="group hover:shadow-2xl relative bg-white my-8 sm:px-6 pb-6 sm:p-8 rounded-xl shadow-xl  w-full lg:w-4/6 m-auto">
                        <div className="group-hover:sm:-rotate-6  transition duration-300 ease-in-out sm:absolute transform sm:-rotate-12 sm:-left-2 text-center text-white sm:-top-2 text-4xl font-bold  bg-gradient-to-r from-persian-green to-beautiful-blue rounded-lg shadow-lg p-2">
                            Decks
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                            <div className="text-gray-700 flex flex-col gap-4 text-justify">
                                <div>Organize your language studies by creating <span className="font-bold text-beautiful-blue">flexible decks</span> for new vocabulary you're learning. Take advantage of <span className="font-bold text-beautiful-blue">powerful algorithms</span> to help you learn the items over time and track your progress.</div> 
                                <div>Take advantage of custom fields, sections, subsections, auto-translations and more.</div>
                                <div>In case you were wondering, Yalangoers have so far practiced words <span className="font-extrabold text-charcoal">{visualGameStats !== null ? <>{visualGameStats['number_of_deck_games_answers'].toLocaleString()}</> : <>0</> }</span> times.</div>
                            </div>
                            <div className="bg-gradient-to-r from-persian-green to-beautiful-blue rounded-2xl p-4 shadow-xl">
                                <img src={YalangoBook} alt="Tim from Yalango reading a book." className="w-48 m-auto" />
                                <div className="text-white font-bold text-center text-base">There are <Link to="/decks?target=all&source=all">public decks</Link> too!</div>
                            </div>
                        </div>
                    </div>
                   
                    
                    <div className="group hover:shadow-2xl relative bg-white my-8 sm:px-6 pb-6 sm:p-8 rounded-xl shadow-xl  w-full lg:w-4/6 m-auto">
                        <div className="group-hover:sm:-rotate-6  transition duration-300 ease-in-out sm:absolute transform sm:-rotate-12 sm:-left-2 text-center text-white sm:-top-2 text-4xl font-bold bg-gradient-to-r from-orange-yellow-crayola to-sandy-brown rounded-lg shadow-lg p-2">
                            Open data
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                            <div className="bg-gradient-to-r from-orange-yellow-crayola to-sandy-brown rounded-2xl p-4 shadow-xl">
                                <img src={YalangoComputer} alt="Tim from Yalango on the computer." className="w-48 m-auto" />
                                <div className="text-white font-bold text-center text-base">I'm just happily coding over here.</div>
                            </div>
                            <div className="text-gray-700 flex flex-col gap-4 text-justify">
                                <div>Yalango is designed to be a <span className="font-bold text-sandy-brown">flexible study companion</span> and we don't want to lock you into a new ecosystem. Our aim is to let you easily import and export all the data you'd like.</div>
                                <div>Our main goals will be <span className="font-bold text-sandy-brown">learning progress</span> and <span className="font-bold text-sandy-brown">flexibility</span> while developing the platform.</div>
                            </div>
                        </div>
                    </div>

                    <div className="group hover:shadow-2xl relative bg-white my-8 sm:px-6 pb-6 sm:p-8 rounded-xl shadow-xl  w-full lg:w-4/6 m-auto">
                        <div className="group-hover:sm:-rotate-6  transition duration-300 ease-in-out sm:absolute transform sm:-rotate-12 sm:-left-2 text-center text-white sm:-top-2 text-4xl font-bold bg-gradient-to-r from-sandy-brown to-burnt-sienna rounded-lg shadow-lg p-2">
                            Beta
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                            <div className="text-gray-700 flex flex-col gap-4 text-justify">
                                <div>Please note that Yalango is still under <span className="font-bold text-burnt-sienna">active development</span>. This is great because it means that you can directly impact the platform with <span className="font-bold text-burnt-sienna">your feedback</span>. However, please note that occasional bugs are to be expected in the beginning.</div> 
                                <div>We'd love to hear all your feedback, bug reports and ideas at <a href="mailto:hello@yalango.com">hello@yalango.com</a>.</div>
                                <div>We hope you'll love your stay at Yalango. Welcome onboard!</div>
                            </div>
                            <div className="bg-gradient-to-r from-sandy-brown to-burnt-sienna rounded-2xl p-4 shadow-xl">
                                <img src={YalangoQuestion} alt="Tim from Yalango reading a book." className="w-48 m-auto" />
                                <div className="text-white font-bold text-center text-base">I understand that things might be confusing.</div>
                            </div>
                        </div>
                    </div>

                     <div className="w-5/6 m-auto">
                        <h1>Public decks by other users</h1>
                        <div className="text-base lg:text-lg">
                            A deck is a collection of words and phrases you wish to study and learn. <Link to={"/decks"}>See all public decks</Link>.
                        </div>
                        <div className="flex flex-row justify-center place-items-center gap-2">
                            <div>
                                <img src={YalangoJourney} alt="Tim from Yalango on a journey." className="w-0 lg:w-32" />
                            </div>
                        <RowOfSpecificDecks listOfDeckIds={[44680448133, 44448969843, 25804613782]} />
                        </div>
                        
                    </div>
                    <div className="w-5/6 m-auto">
                        <h1>Texts written by AI</h1>
                       
                        <div className="text-base lg:text-lg">
                            Yalango utilizes AI to generate texts in your target language about a wide array of topics. <Link to={"/texts"}>Explore more texts</Link>.
                        </div>
                        <div className="flex flex-row justify-center place-items-center gap-2">
                            <div>
                                <img src={YalangoAI} alt="Tim from Yalango with AI." className="w-0 lg:w-32" />
                            </div>
                            <RowOfSpecificTexts listOfTextIds={[50151693021, 98144836016, 71150062598]} />
                        </div>
       
                    </div>
    */

                    /*

                    <div className=" lg:-mx-12 sm:p-6 -mb-16">
                    <div className="group hover:shadow-2xl relative bg-white my-8 sm:px-6 pb-6 sm:p-8 rounded-xl shadow-xl  w-full lg:w-4/6 m-auto">
                        <div className="group-hover:sm:-rotate-6   transition duration-300 ease-in-out sm:absolute transform sm:-rotate-12 sm:-left-2 text-center text-white sm:-top-2 text-4xl font-bold bg-gradient-to-r from-gray-300 to-gray-800  rounded-lg shadow-lg p-2">
                            Dashboard
                        </div>
                        <h2>Your personal language dashboard</h2>
                        <div className="grid grid-cols-1  justify-center">
                            <div className="">
                                <img alt="Yalango dashboard screenshot" className="w-full rounded-2xl shadow-xl" src={DashboardScreenshot} />
                            </div>
                            <div className="text-gray-700 flex flex-col gap-4 text-justify">
                                <div>Yalango is designed for serious language learners and polyglots. By creating a free account at Yalango, you instantly get access to a personal dashboard for every language you're studying. Your dashboard shows your language progress at a glance, offering easy navigation to your decks, tables, notes, and vocabulary.</div>
                            </div>
                        </div>
                    </div>
                    <div className="group hover:shadow-2xl relative bg-white my-8 mb-24 sm:px-6 pb-6 sm:p-8 rounded-xl shadow-xl  w-full lg:w-4/6 m-auto">
                        <div className="group-hover:sm:-rotate-6   transition duration-300 ease-in-out sm:absolute transform sm:-rotate-12 sm:-left-2 text-center text-white sm:-top-2 text-4xl font-bold bg-gradient-to-r from-charcoal to-persian-green  rounded-lg shadow-lg p-2">
                            Meet Tim
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                            <div className="bg-gradient-to-r from-charcoal to-persian-green rounded-2xl p-4 shadow-xl">
                                <img src={YalangoThumbsUp} alt="Tim from Yalango on the computer." className="w-48 m-auto" />
                                <div className="text-white font-bold text-center text-base">My name is Tim. Nice to meet you!</div>
                            </div>
                            <div className="text-gray-700 flex flex-col gap-4 text-justify">
                                <div>Meet Tim, one of your new language buddies. His job is to help you organize your language studies in order to learn more efficiently.</div>
                                <div>When you're learning a new language, you're most likely using a number of different methods simultaneously. You might use <span className="font-bold text-persian-green">Duolingo</span> to learn new words, watch <span className="font-bold text-persian-green">movies</span> for immersion, and read <span className="font-bold text-persian-green">school books</span> to understand the grammar rules.</div>
                                <div>The purpose and ultimate goal of Yalango is to <span className="font-bold text-persian-green">bring all of this together</span> and help you organize your studies across different services and learning environments.</div>
                            </div>
                        </div>
                    </div>

                                            <div className="text-2xl text-gray-600 opacity-70 animate-bounce">
                            <FontAwesomeIcon icon={faAngleDown}  />
                        </div>
                    */

                        /*
                        <div>In case you were wondering, Yalangoers have so far practiced words <span className="font-extrabold text-charcoal">{visualGameStats !== null ? <>{visualGameStats['number_of_deck_games_answers'].toLocaleString()}</> : <>0</> }</span> times.</div>
                        
                                         <div className="bg-gradient-to-r from-persian-green to-beautiful-blue rounded-2xl p-4 shadow-xl">
                        <img src={YalangoBook} alt="Tim from Yalango reading a book." className="w-48 m-auto" />
                        <div className="text-white font-bold text-center text-base">There are <Link to="/decks?target=all&source=all">public decks</Link> too!</div>
                    </div>
                     <div className="flex text-muted flex-row justify-center gap-4 text-sm">
                        <div data-for="tooltip-frontpage" data-tip="Number of users" className="flex  flex-row gap-1">
                            <div className="text-beautiful-blue">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="font-bold muted-text">
                                {gameStats !== null && gameStats['total_number_of_users'].toLocaleString()}
                            </div>
                        </div>
                        <div data-for="tooltip-frontpage" data-tip="Total number of cards" className="flex  flex-row gap-1">
                            <div>
                                <FontAwesomeIcon icon={faCardHeart} />
                            </div>
                            <div className="font-bold muted-text">
                                {gameStats !== null && gameStats['total_number_of_deck_cards'].toLocaleString()}
                            </div>
                        </div>
                        <Link className="no-underline" to="/decks">
                            <div data-for="tooltip-frontpage" data-tip="Total number of decks" className="flex text-burnt-sienna flex-row gap-1">
                                <div>
                                    <FontAwesomeIcon icon={faCardsBlank} />
                                </div>
                                <div className="font-bold muted-text">
                                    {gameStats !== null && gameStats['total_number_of_decks'].toLocaleString()}
                                </div>
                            </div>
                        </Link>
                        <div data-for="tooltip-frontpage" data-tip="Number of exercises done" className="flex text-persian-green flex-row gap-1">
                            <div>
                                <FontAwesomeIcon icon={faGamepad} />
                            </div>
                            <div className="font-bold muted-text">
                                {gameStats !== null && (gameStats['number_of_deck_games_answers'] + gameStats['number_of_table_games_answers']).toLocaleString()}
                            </div>
                        </div>
                        <Link className="no-underline" to="/languages">
                            <div data-for="tooltip-frontpage" data-tip="Supported languages" className="flex text-charcoal flex-row gap-1">
                                <div>
                                    <FontAwesomeIcon icon={faLanguage} />
                                </div>
                                <div className="font-bold muted-text">
                                    {allLanguages !== null && allLanguages[1].length}
                                </div>
                            </div>
                        </Link>
                    </div>
                    <span className="text-sm "><FontAwesomeIcon icon={faCircleDot} className="text-persian-green fa-beat-fade" style={{'--fa-animation-duration': '2s'}} /> </span> 
                    <span className="font-bold muted-text">{gameStats !== null ? gameStats['total_number_of_users'].toLocaleString() : <Skeleton height={20} count={1} className="w-8" />} </span> users have done
                       
                    <div className="">
                        <img style={logoLoaded ? {} : { display: 'none' }} onLoad={() => setLogoLoaded(true)} src={YalangoSvg} alt="Yalango logo" className="w-4/6 p-4  sm:w-2/6 m-auto" />
                    </div>
                    <div className="xl:block hidden absolute right-8 top-2">
                        <img src={PhoneDashboard} className="w-136 rotate-24 " />
                    </div>
                   
                    */

    const showOldPhotos = true;
    return (
        <>
            <ReactTooltip place="bottom" id="tooltip-frontpage" effect="solid" />
            <div className="text-center overflow-x-hidden overflow-y-hidden">
                <div className="h-full relative px-4 flex flex-col gap-4 justify-center">
                    <div className="flex flex-col 3xl:flex-row justify-between">
                        <div className="flex flex-col gap-8 justify-center">
                            <motion.div className="text-6xl  font-semibold w-full sm:w-4/6 lg:w-5/6 mx-auto">
                                Yalango
                            </motion.div>
                            <motion.div className="text-2xl  font-semibold w-full sm:w-4/6 lg:w-5/6 mx-auto">
                                Personalized language learning technology for aspiring polyglots
                            </motion.div>
                            <motion.div className="text-lg xl:w-2/3 4xl:w-2/3 mx-auto">
                                Users have done <span className="font-bold muted-text">{gameStats !== null ? (gameStats['number_of_deck_games_answers'] + gameStats['number_of_table_games_answers']).toLocaleString() : <Skeleton height={20} count={1} className="w-12" />}</span> exercises on Yalango so far.
                            </motion.div>
                            <div className=" flex flex-col place-items-center gap-4">
                        <Link className="text-4xl no-underline" to={currentUser !== null ? '/dashboard' : "/register"}>
                            <Button size="normal" width="w-64" color="green" iconPlacement={"last"} icon={faChevronRight} text={currentUser !== null ? "My dashboard" : "Get started"} />
                        </Link>
                        {currentUser === null &&
                            <>
                            <Link className="text-4xl no-underline" to={"/login"}>
                                <Button color="charcoal" width="w-64"  text={"Login"} />
                            </Link>
                            </>
                        }
                        <Link className="no-underline" to="/changelog">
                        <div className="mb-3  underline cursor-pointer font-semibold">
                            See what's new in our changelog
                        </div>
                        </Link>
                    </div>

                        </div>
                        <div>
                            <img src={DashboardImage} className="w-full" />
                        </div>
                    </div>
                    
                   
                        
                    
                </div>
                {showOldPhotos &&
                    <>
                    <div className="text-2xl my-8 text-gray-600 text-center opacity-70 animate-bounce">
                        <FontAwesomeIcon icon={faAngleDown}  />
                    </div>
                    <div className="p-2 flex flex-col gap-12 w-full sm:w-4/6 m-auto ">
                        <h1>How it works</h1>
                        <div className="flex flex-col gap-4">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>1. Create or import decks with words and phrases you wish to study</h2>
                            </div>
                            <div className="w-full m-auto  overflow-hidden">
                                <img src={DeckImage} className="w-full " />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>2. Study your decks with fun games and spaced repetition</h2>
                            </div>
                            <div className="w-full m-auto grid grid-cols-1 xl:grid-cols-2">
                                <div className="w-full flex flex-col gap-3">
                                    <div className="font-semibold text-lg">
                                        Quiz
                                    </div>
                                    <img src={QuizImage} className="w-full " />
                                </div>
                                <div className="w-full flex flex-col gap-3">
                                    <div className="font-semibold text-lg">
                                        Flashcards
                                    </div>
                                    <img src={FlashcardsImage} className="w-full " />
                                </div>
                                <div className="w-full flex flex-col gap-3">
                                    <div className="font-semibold text-lg">
                                        Write
                                    </div>
                                    <img src={InputImage} className="w-full " />
                                </div>
                                <div className="w-full flex flex-col gap-3">
                                    <div className="font-semibold text-lg">
                                        Practice characters
                                    </div>
                                    <img src={HanziImage} className="w-full " />
                                </div>
                            </div>
                        
                            
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>3. Keep track of your progress for each word</h2>
                            </div>
                            <div className="w-full m-auto  overflow-hidden">
                                <img src={ProgressImage} className="w-full " />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>4. Expand your vocabulary with public decks from other users</h2>
                            </div>
                            <div className="w-full m-auto  overflow-hidden">
                                <img src={ExploreImage} className="w-full " />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>5. Add all the languages you're learning and have a separate dashboard for each one</h2>
                                <div className="text-center">We support 181 different languages.</div>
                            </div>
                            <div className="w-full m-auto  overflow-hidden">
                                <img src={LanguagesImage} className="w-full " />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="w-full sm:w-5/6 m-auto">
                                <h2>6. Easily import decks from CSV files, Quizlet, and more</h2>
                                <div className="text-center">We also support exporting your decks, of course!</div>
                            </div>
                            <div className="w-full m-auto  overflow-hidden">
                                <img src={ImportImage} className="w-full " />
                            </div>
                        </div>
                        <div className="flex flex-col gap-8 bg-gradient-to-r from-charcoal to-persian-green rounded-2xl p-8 shadow-xl text-white">
                            <div className="w-full flex flex-col gap-4 sm:w-5/6 m-auto">
                                <div className="text-white font-semibold text-xl lg:text-3xl">Enjoy everything for free and without any ads!</div>
                                <div className="text-center">Yalango is a new platform and we want to make the platform as good as possible based on your feedback.</div>
                            </div>
                            <div className="w-full m-auto  overflow-hidden ">
                                <img src={YalangoThumbsUp} alt="Tim" className="w-96 m-auto" />
                            </div>
                        </div>
                    </div>

                    </>
                }
            </div>
           

        </>
    )
}

export default FrontPageHero;
