import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import FlashcardsCard from './FlashcardsCard';
import FlashcardsButtons from './FlashcardsButtons';
import FlashcardsMenu from './FlashcardsMenu';
import FlashcardsEndScreen from './FlashcardsEndScreen';
import FlashcardsPlayAgain from './FlashcardsPlayAgain';
import { faSchool } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../../general/Button';
import useDecks from '../../../../hooks/useDecks';
import ScoreScreen from '../../../../components/vocabulary/games/common-assets/ScoreScreen';
import useStatistics from '../../../../hooks/useStatistics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/pro-duotone-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../general/Icon';
import LoadingGame from '../common-assets/LoadingGame';
import { useAuth } from "../../../../contexts/AuthContext";
import ReactTooltip from 'react-tooltip';
import {
    motion,
    useMotionValue,
    useTransform,
    useAnimation
  } from "framer-motion"

import {useUserStatisticsContext} from '../../../../contexts/UserStatisticsContext';
import FlashcardHelpModal from './modals/FlashcardHelpModal';

function FlashcardsGame({reviewFinished, setReviewFinished, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, isPremium, score, setScore, gameScreen, setGameScreen, audioLoading, autoPlayPronunciations, globalHandleSkipAnswer, globalHandleCorrectAnswer, globalHandleWrongAnswer,  isInMix, googleTextToSpeechClick, setCards, playMode, setPlayMode, mistakes, setMistakes, laterMistakes, setLaterMistakes, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, playModeArray, backToPlayPageLink, id, type}) {
    const {addFlashcardStatForCurrentUser, 
        addReviewStatForCurrentUser} = useStatistics();
    const {activateDailyStatsNeedRefresh, activateDailyStreakNeedsRefresh} = useUserStatisticsContext();
    console.log(playMode, playModeArray, currentCardIndex);
    const [targetSide, setTargetSide] = useState(true);

    const [dataType, setDataType] = useState(type);

    // Game logic
    const [feedback, setFeedback] = useState(undefined);

    const resetValues = () => {
        setCurrentCardIndex(0);
        setScore(0);
        setNbOfAnswers(0);
        setCardsLeft(0);
    }

    // Counting docs
    useEffect(()=>{
        if (cards !== null && !isInMix){
            setCardsLeft(cards.length);
        }  
    }, [cards, isInMix]);

    useEffect(()=>{
        if (isInMix && cards !== null){
            //resetValues(); // legacy functionality
        }   
    },[cards, isInMix]);


    useEffect(()=>{
        if (autoPlayPronunciations === true && currentCardIndex !== null && cards !== null && !audioLoading && playMode === "target_first"){
            googleTextToSpeechClick(cards[currentCardIndex]);
        }
    },[autoPlayPronunciations, currentCardIndex, cards, playMode]);


    // GAME FUNCTIONS
    const flipCard = () => {
        setTargetSide(!targetSide);
    }

    const makeStatObject = ({correct}) => {
        let obj = {
            'correct': correct,
            'doc_id': cards[currentCardIndex].doc_id,
            'deck_id': cards[currentCardIndex].deck_id,
            'deck_uid': 'deck_uid' in cards[currentCardIndex] ? cards[currentCardIndex].deck_uid : null,
            'view_mode': playMode !== "mix" ? playMode : playModeArray[currentCardIndex],
            'data_type': dataType,
            'target_word': cards[currentCardIndex].target,
            'source_word': cards[currentCardIndex].source, 
            'target_iso': cards[currentCardIndex]['target_ISO_639-1'],
            'source_iso': cards[currentCardIndex]['source_ISO_639-1'], 
            'google_tts': cards[currentCardIndex].hasOwnProperty('google_tts') ? cards[currentCardIndex]['google_tts'] : null,
            'google_tts_target': cards[currentCardIndex].hasOwnProperty('google_tts_target') ? cards[currentCardIndex]['google_tts_target'] : null,
            'game': isInMix ? 'mix' : 'flashcards'
        }
        activateDailyStatsNeedRefresh();
        activateDailyStreakNeedsRefresh();
        return obj;
    }

    const failedWord = () => {
        if (cards === null){return null}
        setTargetSide(true);
        if (!isInMix){
            if (gameScreen === "game"){
                setMistakes([...mistakes, cards[currentCardIndex]]);
                incrementAnswersCounter();
                addFlashcardStatForCurrentUser(makeStatObject({correct:false}));
            }  
            else if (gameScreen === "review-mistakes"){
                addReviewStatForCurrentUser(makeStatObject({correct:false}));
                setLaterMistakes([...laterMistakes, cards[currentCardIndex]]);
                setNbOfAnswers(nbOfAnswers+1);
            }
            nextWord({'mistake': true, 'mistake_card': cards[currentCardIndex]});
        } else {
            // in mix
            if (gameScreen === "game"){
                setMistakes([...mistakes, cards[currentCardIndex]]);
                addFlashcardStatForCurrentUser(makeStatObject({correct:false}));
            }
            globalHandleWrongAnswer();
        }
    }

    const correctWord = () => {
        setTargetSide(true);
        if (!isInMix){
            addPoint();
            if (gameScreen === "game"){
                incrementAnswersCounter();
                addFlashcardStatForCurrentUser(makeStatObject({correct:true}));
            } 
            else if (gameScreen === "review-mistakes"){
                addReviewStatForCurrentUser(makeStatObject({correct:true}));
                setNbOfAnswers(nbOfAnswers+1);
                setCardsLeft(cardsLeft-1); 
            }
            nextWord({'mistake': false});
        }
        else {
            if (gameScreen === "game"){
                addFlashcardStatForCurrentUser(makeStatObject({correct:true}));
            } 
            globalHandleCorrectAnswer();
        }
    }

    const nextWord = (o) => {
        if (gameScreen === "game" && !isInMix){
            if (cardsLeft > 1){
                setCurrentCardIndex(currentCardIndex+1);
            } else {
                setGameScreen("endscreen");
            } 
        }
        else if (gameScreen === "review-mistakes" && !isInMix){
            if (currentCardIndex < cards.length-1){
                setCurrentCardIndex(currentCardIndex+1);
            } 
            else {
                if (laterMistakes.length === 0 && o['mistake'] === false){
                    setReviewFinished(true);
                    setGameScreen("endscreen");
                    setCards(null);
                }
                else {
                    let l = JSON.parse(JSON.stringify(laterMistakes));
                    if ('mistake_card' in o){
                        //async issue
                        let c = o['mistake_card'];
                        if (l.indexOf(c) === -1){
                            l.push(c);
                        }
                    }

                    setCards(l);
                    setCardsLeft(l.length);
                    setCurrentCardIndex(0);
                    setFeedback(null);
                    setLaterMistakes([]);
                }
            }
            
        }
    }

    const addPoint = () => {
        setScore(score+1);
    }

    const incrementAnswersCounter = () => {
        setNbOfAnswers(nbOfAnswers+1);
        setCardsLeft(cardsLeft-1);    
    }

    const playAgain = async () => {
        setReviewFinished(false);
        await refreshData();
        setScore(0);
        setNbOfAnswers(0);
        setCurrentCardIndex(0);
        setFeedback(null);
        setGameScreen("game");
    }

    const reviewMistakes = () => {
        setCards(mistakes);
        setScore(0);
        setNbOfAnswers(0);
        setCardsLeft(mistakes.length);
        setCurrentCardIndex(0);
        setFeedback(null);
        setGameScreen("review-mistakes");
        setMistakes([]);
    }

    // GAME BUTTON REFS
    const failedButtonRef = useRef(null);

    // KEY CODES
    document.body.onkeyup = function(e){
        // SPACE - TURN CARD
        if(e.keyCode === 32){
            if(gameScreen !== "endscreen"){
                flipCard();
            }   
        }
        // DOWN ARROW - TURN CARD
        else if (e.keyCode === 40){
            if(gameScreen !== "endscreen"){
                flipCard();
            }   
        }
        // LEFT ARROW - FAIL
        else if (e.keyCode === 37){
            if(gameScreen !== "endscreen"){
                if (failedButtonRef.current !== null){
                    //console.log(failedButtonRef);
                    failedButtonRef.current.click();
                }
                failedWord();
            }   
        }
        // RIGHT ARROW - CORRECT
        else if (e.keyCode === 39){
            if(gameScreen !== "endscreen"){
                correctWord();
            }   
        }
    }

    const flipViewModeClick = () => {
        if (playMode === "target_first"){
            setPlayMode("source_first");
        } else {
            setPlayMode("target_first");
        }
    }

    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }
/* 
<div className="text-3xl" onClick={flipViewModeClick}>
                                    <div data-tip="Change game mode" data-for="flashcard-game-tooltip">
                                        <Icon color="blue" icon={<FontAwesomeIcon icon={faGamepad} />} />
                                    </div>
                                </div>
*/
    //p-8 sm:p-0 flex flex-col items-center justify-center h-full gap-4
    //<FlashcardsMenu playMode={playMode} score={score} nbOfAnswers={nbOfAnswers} cardsLeft={cardsLeft} />

    return (
        <>
          {(loadingItems === false && showLoadingScreen === false && cards !== null && cards.length > 0n) &&
                        <>
            <div className="h-full flex justify-between place-items-center flex-col w-full">
              
                <motion.div className="w-full m-auto">
                    
                            {(cards !== null && cards.length > 0) ?
                                <>
                                {(gameScreen === "game" || gameScreen === "review-mistakes") &&
                                    <>
                                        
                                        { gameScreen === "review-mistakes" && 
                                            <div className=" flex flex-row justify-center gap-4 m-auto mb-3 font-bold text-center bg-burnt-sienna rounded-xl shadow-xl text-base w-48 sm:text-lg md:text-xl md:w-64 p-2 text-white">
                                                <div>
                                                    <FontAwesomeIcon icon={faSchool} />
                                                </div>
                                                <div>
                                                    Review mode
                                                </div>
                                            </div>
                                        }
                                        <div className="flex h-full flex-col gap-6 justify-center place-items-center overflow-visible w-full">
                                            {!isInMix &&
                                                <>
                                                    
                                                </>
                                            }
                                            <FlashcardsCard isPremium={isPremium} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} failedWord={failedWord} correctWord={correctWord} gameScreen={gameScreen} nextCard={cardsLeft > 1 ? cards[currentCardIndex+1] : null} cardsLeft={cardsLeft} playMode={playMode !== "mix" ? playMode : playModeArray[currentCardIndex]} data={cards[currentCardIndex]} flipCard={flipCard} targetSide={targetSide} setTargetSide={setTargetSide} />
                                            
                                            <FlashcardsButtons failedButtonRef={failedButtonRef} failedWord={failedWord} correctWord={correctWord} nextWord={nextWord} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} flipCard={flipCard} targetSide={targetSide} setTargetSide={setTargetSide} />
                                        </div>
                                    </>
                                }
                                </>
                            :
                                <>
                                    {(gameScreen !== "endscreen" && !isInMix && type !== "review-mistakes") &&
                                        <div className="text-black flex flex-col items-center justify-content gap-2">
                                            <h1>Whoops!</h1>
                                            <p className="text-base">There are no items in this {type}. Please add at least one item in order to play.</p> 
                                            <div classCenter="text-center">
                                                {type === "deck" &&
                                                    <Link to={'/my-decks/'+id}><Button color="green" text="Add items"></Button></Link>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        
                    {(gameScreen === "endscreen" && loadingItems === false && showLoadingScreen === false && !isInMix) && 
                        <>
                            <ScoreScreen score={score} nbOfAnswers={nbOfAnswers} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgain} reviewMistakesClick={reviewMistakes} mistakes={mistakes} reviewFinished={reviewFinished} />
                        </>
                    }
                </motion.div>
                        
                        
            </div>
                </>
            }
        </>
    )
}

export default FlashcardsGame;
