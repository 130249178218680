import React,{useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import Button from '../../../general/Button';
import {timeStamp} from '../../../../firebase/config.js';
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '../../../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import {faVolumeUp} from '@fortawesome/pro-solid-svg-icons';
import {useUserStatisticsContext} from '../../../../contexts/UserStatisticsContext';
import RenderWord from '../../../vocabulary/games/common-assets/RenderWord';

const QuizQuestions = ({isPremium, playModeArray, mistakes, setMistakes, audioLoading, isInMix, globalChosenCard, globalHandleSkipAnswer, globalHandleCorrectAnswer, globalHandleWrongAnswer, googleTextToSpeechClick, playMode, cardsLeft, setCardsLeft, reviewMode, addQuizStatForCurrentUser, dataType, currentCardIndex, setCurrentCardIndex, setGameScreen, cards, score, setScore, nbOfAnswers, setNbOfAnswers, nbOfSkips, setNbOfSkips}) => {
    let {currentUser} = useAuth();
    let navigate = useNavigate();
    const {activateDailyStatsNeedRefresh, activateDailyStreakNeedsRefresh} = useUserStatisticsContext();
    const location = useLocation();
    const [collectData, setCollectData] = useState(false);
    let [currentPath, setCurrentPath] = useState(location.pathname.toString());

    useEffect(()=>{
        if (isInMix && globalChosenCard !== null){
            setShowCorrectAnswer(false); //reset between two quizzes after each other in mix mode
            setWrongOption("");
        }   
    },[globalChosenCard, isInMix]);

    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [wrongOption, setWrongOption] = useState("");

    useEffect(()=>{
        function randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min)
          }

        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }

        const createQuestions = (items, playMode) => {
            let questions_ = [];
            let currentMode = null;
            console.log("Play mode: ", playMode);
            if (playMode === "source_first"){
               currentMode = playMode;
            } else if (playMode === "target_first"){
                currentMode = "target_first";
            }
            let nbOfQuestions = items.length;
            let item_number = 0;
            for (const item of items){
                if (playMode === "mix"){
                    currentMode = playModeArray[item_number];
                    console.log("Current: ", playModeArray[item_number]);
                }
                let question = {}; 
                if (currentMode === "source_first"){
                    question['target'] = item.source; 
                    question['target_item'] = item;
                } 
                else {
                    question['target'] = item.target; 
                    question['target_item'] = item;
                }
                let options = [];
                let used_numbers = [];
                options.push({'correct': true, 'item':item});
                used_numbers.push(item_number);
                for (let i=0; i<3; i++){
                    let random_number = randomIntFromInterval(0, nbOfQuestions-1);
                    let accepted_number = false;

                    while(accepted_number === false){
                        if (used_numbers.includes(random_number)===false){
                            // we don't two of the same options
                            accepted_number = true;
                        }  
                        else {
                            random_number = randomIntFromInterval(0, nbOfQuestions-1);
                        }
                    }
                    let alsoCorrect = false;
                    if (currentMode === "source_first"){
                        if (item.target === items[random_number].target){
                            alsoCorrect = true;
                        }
                    }
                    if (currentMode === "target_first"){
                        if (item.source === items[random_number].source){
                            alsoCorrect = true;
                        }
                    }
                    options.push({'correct': alsoCorrect ? true : false, 'item':items[random_number]});
                    used_numbers.push(random_number);
                }
                question['options'] = shuffleArray(options);

                questions_.push(question);
                item_number = item_number + 1; 
            }
            setQuestions(questions_);
            setLoading(false);
        }   

        const createMixQuestion = () => {
            let questions_ = [];
            let question = {}; 
            let currentMode = null;
            if (playMode === "source_first"){
               currentMode = playMode;
            } else if (playMode === "mix"){
                currentMode = playModeArray[currentCardIndex];
            }
            else {
               currentMode = "target_first";
            }
            if (currentMode === "source_first"){
                question['target'] = globalChosenCard.source; 
                question['target_item'] = globalChosenCard;
            } else {
                question['target'] = globalChosenCard.target; 
                question['target_item'] = globalChosenCard;
            }
            let options = [];
            let used_numbers = [];
            options.push({'correct': true, 'item':globalChosenCard});

            for (let i=0; i<3; i++){
                let random_number = randomIntFromInterval(0, cards.length-1);
                let accepted_number = false;

                while(accepted_number === false){
                    if (used_numbers.includes(random_number)===false && globalChosenCard.doc_id !== cards[random_number].doc_id){
                        // we don't two of the same options and we don't take the chosen question card
                        accepted_number = true;
                    }  
                    else {
                        random_number = randomIntFromInterval(0, cards.length-1);
                    }
                }
                options.push({'correct': false, 'item':cards[random_number]});
                used_numbers.push(random_number);
            }
            question['options'] = shuffleArray(options);
            questions_.push(question);
            setQuestions(questions_);
            setLoading(false);
        }

        if (cards !== null && playMode !== null && !isInMix){
            createQuestions(cards, playMode);
        } else if (cards !== null && playMode !== null && isInMix === true && globalChosenCard !== null){
            createMixQuestion();
        }
        if (currentUser !== null) {
            setCollectData(true);
        }
    },[playMode, playModeArray, cards, isInMix, globalChosenCard, currentUser]);

    const makeStatObject = ({correct, chosenItem}) => {
        let cur_question = questions[isInMix ? 0 : currentCardIndex]['options'];
        let correct_option;
        let alternative_options = [];
        cur_question.forEach(question=>{
            if (question['correct']===true){
                correct_option = question['item'];
            } else {
                alternative_options.push(question['item']);
            };
        });
        if (!('created_timestamp' in correct_option)){
            correct_option['created_timestamp'] = null;
        }
        if (!('last_updated_timestamp' in correct_option)){
            correct_option['last_updated_timestamp'] = null;
        }
        let deck_id = null;
        if (dataType === "vocabulary" && correct_option.hasOwnProperty('deck_ids') && correct_option['deck_ids'] !== null && correct_option['deck_ids'].length > 0){
            deck_id = correct_option['deck_ids'][0];
        }
        if (dataType !== "vocabulary"){
            deck_id = correct_option.deck_id;
        }
        let obj = {
            'data_type': dataType, 
            'correct': correct, 
            'doc_id': correct_option.doc_id,
            'deck_uid': 'deck_uid' in correct_option ? correct_option.deck_uid : null,
            'deck_id': deck_id,
            'doc_created_timestamp': dataType === "vocabulary" ? correct_option.created  : correct_option.created_timestamp,
            'doc_last_updated_timestamp': dataType === "vocabulary" ? correct_option.last_updated  : correct_option.last_updated_timestamp,
            'source_word': correct_option.source,
            'target_word': correct_option.target,
            'target_ISO_639-1': correct_option['target_ISO_639-1'],
            'source_ISO_639-1': correct_option['source_ISO_639-1'], 
            'alternative_options': alternative_options, 
            'timestamp': timeStamp, 
            'chosen_target_word': chosenItem['target'], 
            'chosen_source_word': chosenItem['source'], 
            'google_tts': correct_option.hasOwnProperty('google_tts') ? correct_option['google_tts'] : null,
            'google_tts_target': correct_option.hasOwnProperty('google_tts_target') ? correct_option['google_tts_target'] : null,
            'view_mode': playMode !== "mix" ? playMode : playModeArray[currentCardIndex], 
            'game': isInMix ? 'mix' : 'quiz'
        }
        activateDailyStatsNeedRefresh();
        activateDailyStreakNeedsRefresh();
        return obj;
    }

    const handleWrongAnswer = (chosenItem) => {
        if (currentUser !== null && collectData){
            let correct_ = null;
            questions[isInMix ? 0 : currentCardIndex]['options'].forEach(question=>{
                if (question['correct']===true){
                    correct_ = question['item'];
                }
            });
            if (correct_ !== null){
                setMistakes([...mistakes, correct_]);
            }
            let statObject = makeStatObject({correct:false, chosenItem: chosenItem});
            addQuizStatForCurrentUser(statObject);
        }  
    }

    const handleCorrectAnswer = (chosenItem) => {
        if (currentUser !== null && collectData){
            let statObject = makeStatObject({correct:true, chosenItem: chosenItem});
            addQuizStatForCurrentUser(statObject);
        }
    }

    const optionClick = (option) => {
        let chosenItem = option['item'];
        if (!showCorrectAnswer){
            let correct = option.correct;
            if (correct  && !showCorrectAnswer){
                if (!isInMix){
                    setScore(score+1);
                }
                handleCorrectAnswer(chosenItem);
                // add to score and color answers
                setShowCorrectAnswer(true);
                setTimeout(() => {nextQuestionClick(true)}, 800);
            }
            else {
                // add red color
                setWrongOption(option.item.id);
                handleWrongAnswer(chosenItem);
                // add to score and color answers
                setShowCorrectAnswer(true);
                setTimeout(() => {nextQuestionClick(false)}, 1500);
            }
            if (!isInMix){
                setCardsLeft(cardsLeft-1);
            }
        }
    }
    
    const nextQuestionClick = (correct) => {
        if (isInMix){
            if (correct === true){
                globalHandleCorrectAnswer();
            } else if (correct === false) {
                globalHandleWrongAnswer();
            } else if (correct === "skip"){
                globalHandleSkipAnswer();
            } else {
                globalHandleSkipAnswer();
            }
        } else {
            if (!loading){
                if (questions.length > 0){
                    if (currentCardIndex === questions.length-1){
                        setGameScreen("endscreen");
                    }
                    else {
                        setCurrentCardIndex(currentCardIndex+1);
                        setNbOfAnswers(nbOfAnswers+1);
                    }
                }
            }
            setCardsLeft(cardsLeft-1);
            setWrongOption("");
            setShowCorrectAnswer(false);
        }
    }

    document.body.onkeyup = function(e){
        if(e.key === "1" || e.key === "2" || e.key === "3" || e.key === "4"){
            optionClick(questions[isInMix ? 0 : currentCardIndex]['options'][e.key-1]); 
        }
    }

    const audioClick = (e, option) => {
        e.stopPropagation();
        googleTextToSpeechClick(option.item);
    }

    //h-full w-full flex flex-col place-items-center gap-3 p-0 sm:p-3 justify-center
    console.log(questions);
    return (
        <>
            {(!loading && questions.length > 0) &&
                <>
                    <div className="w-screen px-8 flex flex-col gap-4 sm:gap-8">
                        <motion.div  className="text-2xl  sm:text-3xl w-5/6 mx-auto  p-4 overflow-x-auto overflow-y-hidden  text-center">
                            <div className="flex flex-row justify-center place-items-center gap-3">
                                {playMode === "target_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "target_first") &&
                                    <>
  
                                        <div className="text-xl sm:text-2xl">
                                            {!audioLoading ?
                                                <>
                                                    {(questions[isInMix ? 0 : currentCardIndex]['target_item'].hasOwnProperty("google_tts") && questions[isInMix ? 0 : currentCardIndex]['target_item']['google_tts'] !== null) &&
                                                    <div onClick={(e)=>googleTextToSpeechClick(questions[isInMix ? 0 : currentCardIndex]['target_item'])} className="">
                                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                    </div>
                                                    }
                                                </>
                                            :
                                                <div className="text-xl sm:text-2xl">
                                                    <Icon color="charcoal" className="fa-beat" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                </div>
                                            }
                                        </div>
                                        
                                    </>
                                }
                                <div>
                                    {questions[isInMix ? 0 : currentCardIndex]['target'] === questions[isInMix ? 0 : currentCardIndex]["target_item"]["target"] ?
                                    <RenderWord  targetSize={"5xl"} pinyinSize={"xl"} text={questions[isInMix ? 0 : currentCardIndex]['target']} language={questions[isInMix ? 0 : currentCardIndex]["target_item"]["target_ISO_639-1"]} data={questions[isInMix ? 0 : currentCardIndex]["target_item"]} targetOrSource={"target"} />
                                    :
                                    <>
                                        {questions[isInMix ? 0 : currentCardIndex]['target']}
                                    </>
                                    }   
                                    </div>
                            </div>
                        </motion.div>
                        <div className="grid w-full m-auto justify-center gap-2 grid-cols-1 sm:grid-cols-2 text-black">
                            { questions[isInMix ? 0 : currentCardIndex]['options'].map((option,index)=>(
                                <motion.div key={"option_"+index} onClick={()=>optionClick(option)} whileHover={{y:-2}}  whileTap={{scale:1}} className={(showCorrectAnswer ? (option.correct ? "bg-persian-green text-white" : (option.item.id === wrongOption ? "bg-burnt-sienna text-white " : "bg-charcoal text-white ")) : "bg-charcoal text-white ") + " rounded-md shadow-md py-4 w-full px-1 sm:p-4 cursor-pointer"}>
                                    <div className="flex flex-row m-auto whitespace-nowrap w-full flex-grow justify-start gap-6 text-lg sm:text-xl text-center">
                                        <div className="font-bold hidden sm:block bg-white text-black rounded-sm p-5">
                                            {index+1}
                                        </div>
                                        <div className=" w-5/6 m-auto">
                                            {playMode === "source_first" || (playMode === "mix" && playModeArray[currentCardIndex] === "source_first") ?
                                                <>
                                                <div className="flex flex-row w-full justify-center place-items-center gap-6">

                                                            {(option.item.hasOwnProperty("google_tts") && option.item['google_tts'] !== null) &&
                                                            <div className="text-white">
                                                                    <div onClick={(e)=>audioClick(e, option)} className="">
                                                                        <Icon color="white" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                    </div>
                                                            </div>
                                                            }
                                                        
                                                    <div>
                                                        <RenderWord text={option.item.target} language={option.item["target_ISO_639-1"]} data={option.item} targetOrSource={"target"} />
                                                    </div>
                                                </div>
                                                   
                                                </>
                                            : 
                                                <>
                                                    {option.item.source}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </motion.div>
                            )) }
                        </div>
                        <div className={(showCorrectAnswer === true ? "invisible " : " ") + " text-center flex flex-row justify-center"}>
                            <Button text='Skip question' color="charcoal" onClick={()=>nextQuestionClick("skip")} />
                        </div>
                        
                        
                    </div>
                </>
            }
        </>
    )
}

export default QuizQuestions;
