import React, {useState} from 'react';
import YalangoHead from '../../../assets/svg/yalango/YalangoHead.svg';
import {motion} from 'framer-motion';
import OpenMenu from './OpenMenu';
import {faX} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GeneralFeedback from '../../../components/feedback/modals/GeneralFeedback';
import TranslatorModal from '../../../components/widgets/translator/TranslatorModal';
import SimpleNavigationComponent from '../../../components/navigation/SimpleNavigationComponent';
import QuickNotesModal from '../../../components/widgets/quick-notes/QuickNotesModal';

const WidgetMenu = ({menuDesign}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [minimizeMenu, setMinimizeMenu] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showTranslator, setShowTranslator] = useState(false);
    const [showQuickNotes, setShowQuickNotes] = useState(false);
    const [showNavigationArrows, setShowNavigationArrows] = useState(true);
    const closeFeedback = () => {setShowFeedback(false);}
    const closeTranslator = () => {setShowTranslator(false);}
    const closeQuickNotes = () => {setShowQuickNotes(false);}
    const closeFunction = () => {setShowMenu(false);}
    const closeNavigationArrows = () => {setShowNavigationArrows(false);}

    const translatorClick = () => {
        setShowTranslator(true);
        closeFunction();
    }

    const navigationArrowsClick = () => {
        setShowNavigationArrows(!showNavigationArrows);
    }

    const feedbackClick = () => {
        setShowFeedback(true);
        closeFunction();
    }
    const quickNotesClick = () => {
        setShowQuickNotes(true);
        closeFunction();
    };

    const minimizeTim = () => {
        setMinimizeMenu(true);
        setShowMenu(false);
    }

    const unminimizeTim = () => {
        setMinimizeMenu(false);
        setShowMenu(true);
    }

    return (
        <>
            {menuDesign === "mobile-menu" ?
                <>
                    <div className="relative">
                    <div onClick={()=>setShowMenu(!showMenu)} className="cursor-pointer ">
                        <motion.img whileHover={{scale:1.03}} className="w-10" src={YalangoHead} aria-label="Yalango logo" />
                    </div>
                    
                    {showMenu && 
                        <OpenMenu quickNotesClick={quickNotesClick} menuDesign={menuDesign} showHideTimOption={false} minimizeTim={minimizeTim} showNavigationArrows={showNavigationArrows} navigationArrowsClick={navigationArrowsClick} feedbackClick={feedbackClick} translatorClick={translatorClick} closeFunction={closeFunction} /> 
                    }
                    {showFeedback &&
                        <GeneralFeedback closeFunction={closeFeedback} />
                    }
                    {showTranslator &&
                        <TranslatorModal closeFunction={closeTranslator} />
                    }
                    {showQuickNotes &&
                        <QuickNotesModal closeFunction={closeQuickNotes} />
                    }
                    </div>
                </>
            :
                <>
                    <div className="invisible sm:visible fixed z-10 right-0 bottom-0 p-4 mr-4 mb-24 sm:mr-6 sm:mb-6">
                        {minimizeMenu ? 
                            <>
                                <div className="font-bold underline text-gray-600 cursor-pointer" onClick={unminimizeTim}>
                                    Tim
                                </div>
                            </>
                        :
                        <>
                        <div className="flex flex-col place-items-center">
                            {!showMenu &&
                            <div className="font-bold hidden text-base muted-text text-center">
                                Useful tools
                            </div>
                            }
                            <div onClick={()=>setShowMenu(!showMenu)} className="z-10 cursor-pointer p-4 w-18 h-18 bg-white border border-gray-600 border-dotted shadow-xl rounded-full ">
                                <motion.img whileHover={{scale:1.03}} className="w-16" src={YalangoHead} aria-label="Yalango logo" />
                            </div>
                        </div>
                        </>
                        
                        }
                        {showMenu && 
                            <OpenMenu quickNotesClick={quickNotesClick}  minimizeTim={minimizeTim} showNavigationArrows={showNavigationArrows} navigationArrowsClick={navigationArrowsClick} feedbackClick={feedbackClick} translatorClick={translatorClick} closeFunction={closeFunction} /> 
                        }
                        {showFeedback &&
                            <GeneralFeedback closeFunction={closeFeedback} />
                        }
                        {showTranslator &&
                            <TranslatorModal closeFunction={closeTranslator} />
                        }
                         {showQuickNotes &&
                            <QuickNotesModal closeFunction={closeQuickNotes} />
                        }
                    </div>   

            </>
            }
            
        </>
    )
}

export default WidgetMenu