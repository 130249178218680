import EmailVerification from "../../components/settings/EmailVerification";

const AccountSettings = () => {


    return (
      <>
          <div className="  flex flex-col gap-3">
              <h1>Account settings</h1>
              <div>
                <h3>Email verification</h3>
                <div className="p-3">
                    <EmailVerification />
                </div>
              </div>

          </div>
      </>
    )
  }
  
  export default AccountSettings;