import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {CSVReader} from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/pro-solid-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faRepeatAlt} from '@fortawesome/pro-duotone-svg-icons';
import {faTrashAlt} from '@fortawesome/pro-duotone-svg-icons';
import {faCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faPlus} from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';
import {useParams} from "react-router-dom";
import useDecks from '../../../hooks/useDecks';
import Icon from '../../../components/general/Icon';
import Button from '../../../components/general/Button';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import YalangoComputer from '../../../assets/svg/yalango/yalango_computer.svg';
import Skeleton from 'react-loading-skeleton';
import DeckSelectorModal from '../../../components/general-modals/decks/DeckSelectorModal';

const ImportCSVToDeck = ({type, design, mode, ytId, hideTitles}) => {
    //design: page or embed
    //mode: create-deck or edit-deck
    const [deck, setDeck] = useState(null);
    const [fetchingData, setFetchingData] = useState(false);
    const [dataFetched, setDataFetched] = useState(mode==="edit-deck" ? false : true);

    const {fetchDeckFromCurrentUser, 
        uploadMultipleCardsToDeckApi, 
        fetchDeckFromCurrentUserRealtime} = useDecks();
    const {allLanguages, globalSelectedTargetLanguage, globalSelectedSourceLanguage} = useLanguagesContext();

    let { id } = useParams();
    if (id === undefined || id === null) {
        id = null; //we need to create a new deck
    } else {
        id = parseInt(id); // deck already exists
    }

    let [languageOptions, setLanguageOptions] = useState(null);
    const [firstColOption, setFirstColOption] = useState(null);
    const [secondColOption, setSecondColOption] = useState(null);

    let [instructions, setInstructions] = useState({
        'csv': {
            'start_page': 'upload',
            'title': 'Import CSV file',
            'uploaded_title': 'Edit data',
            'uploadDescription': 'Please upload a CSV file encoded in UTF-8 with two columns. You can choose which column is the source and target afterwards.',
            'csv': 'Click here or drop a file directly into this rectangle.', 
            'inputFormat': 'csv',
            'showBackToFirstPageButton': true, 
            'uploadedPageText': "The cards haven't been added to your deck yet. Edit them below and then click on the save button when you're finished."
        }, 
        'quizlet': {
            'start_page': 'upload',
            'title': 'Import Quizlet deck',
            'uploaded_title': 'Edit data',
            'uploadDescription': 'Go to your deck on Quizlet, click on the three dots, click on "Export", then click on "Copy text". Paste the text below.',
            'inputFormat': 'textfield', 
            'inputPlaceholder': 'Paste text from Quizlet here...',
            'showBackToFirstPageButton': true, 
            'uploadedPageText': "The cards haven't been added to your deck yet. Edit them below and then click on the save button when you're finished."
        }, 
        'table': {
            'start_page': 'uploaded',
            'title': 'Create cards with a table',
            'uploaded_title': mode === "edit-deck" ? 'Save words' : 'Create a deck',
            'uploadDescription': 'Easily create multiple cards by filling out the rows on the next page.',
            'inputFormat': 'table',
            'showBackToFirstPageButton': false, 
            'uploadedPageText': "Easily create multiple cards by adding rows to the table below. Remember to save your changes."
        }, 
        'inputText': {
            'start_page': 'upload',
            'title': 'Create cards from raw text',
            'uploaded_title': 'Edit data',
            'uploadDescription': 'Write text below according to the chosen settings.',
            'inputFormat': 'textfield', 
            'inputPlaceholder': 'Example:\nhello, bonjour\nhouse, maison',
            'showBackToFirstPageButton': true, 
            'uploadedPageText': "The cards haven't been added to your deck yet. Edit them below and then click on the save button when you're finished."
        }

    });

    const [typeData, setTypeData] = useState(instructions[type]);
    const [page, setPage] = useState(typeData.start_page); //

    const [showCSVImport, setShowCSVImport] = useState(false);
    const [fileImportContent, setFileImportContent] = useState(null);
    const [newItemsRows, setNewItemsRows] = useState(type === "table" ? [['', '']] : []);
    const [csvError, setCsvError] = useState(false);
    const [csvErrorMsg, setCsvErrorMsg] = useState("");
    const [csvUploaded, setCsvUploaded] = useState(false);
    const [showImportCsvButton, setShowImportCsvButton] = useState(true);
    const [showCancelCsvImportButton, setShowCancelCsvImportButton] = useState(false);
    const [progressCounter, setProgressCounter] = useState(0);
    const [maxNumberOfColumns, setMaxNumberOfColumns] = useState(null);
    const [dbUploadFinished, setDbUploadFinished] = useState(false);

    const [selectedTargetColumnIndex, setSelectedTargetColumnIndex] = useState(null);
    const [selectedSourceColumnIndex, setSelectedSourceColumnIndex] = useState(null);

    const [nbNewItems, setNbNewItems] = useState(newItemsRows.length);

    //textinput
    const [textInputValue, setTextInputValue] = useState("");
    const [textInputSourceTargetSeparator, setTextInputSourceTargetSeperator] = useState(type==="quizlet" ? "\t" : ",");
    const [textInputCardSeparator, setTextInputCardSeperator] = useState("\n");

    const [termSeparatorOptions, setTermSeparatorOptions] = useState([{label: "Tab", value: "\t"}, {label: "Comma", value: ","}]);
    const [cardSeparatorOptions, setCardSeparatorOptions] = useState([{label: "New line", value: "\n"}, {label: "Semicolon", value: ";"}]);
    const [termSeparatorOptionsDict, setTermSeparatorOptionsDict] = useState({
        "\t": {label: "Tab", value: "\t"}, 
        ",": {label: "Comma", value: ","}
    });
    const [cardSeparatorOptionsDict, setCardSeparatorOptionsDict] = useState({
        "\n": {label: "New line", value: "\n"}, 
        ";": {label: "Semicolon", value: ";"}
    });

    const changeTermSeparator = (option) => {
        setTextInputSourceTargetSeperator(option.value);
    }

    const changeCardSeparator = (option) => {
        setTextInputCardSeperator(option.value);
    }

    useEffect(()=>{
        const fetchDeck = async () => {
            setFetchingData(true);
            let r = await fetchDeckFromCurrentUser(id);
            let o = [];
            if (r.hasOwnProperty('target_ISO_639-1')){
                o.push({'label': allLanguages[0][r['target_ISO_639-1']]['name']['en'], 'value': 'target'});
            }
            if (r.hasOwnProperty('source_ISO_639-1')){
                o.push({'label': allLanguages[0][r['source_ISO_639-1']]['name']['en'], 'value': 'source'});
            }
            setLanguageOptions(o);
            setSecondColOption(o[0]);
            setFirstColOption(o[1]);
            setDeck(r);
            setDataFetched(true);
            setFetchingData(false);
        }

        const createDeckMode = () => {
            let o = [];
            o.push({'label': allLanguages[0][globalSelectedTargetLanguage]['name']['en'], 'value': 'target'});
            o.push({'label': allLanguages[0][globalSelectedSourceLanguage]['name']['en'], 'value': 'source'});
            setLanguageOptions(o);
            setSecondColOption(o[0]);
            setFirstColOption(o[1]);
            setDataFetched(true);
        }

        if (allLanguages === null){return null}
        
        if (mode === "edit-deck" && id !== null){
            fetchDeck();
        } else if (mode === "create-deck"){
            if (globalSelectedTargetLanguage === null || globalSelectedSourceLanguage === null){return null}
            createDeckMode();
        }

    },[id, mode, allLanguages, globalSelectedTargetLanguage, globalSelectedSourceLanguage]);

    const handleOnCSVFileLoad = (result) => {
        //console.log("HIEOIJFE");
        //console.log("DATA: CSV: ", result);
        let filtered_result = [];
        let filtered_rows = [];
        let max_number_of_columns = 0;
        result.forEach((elem)=>{
            //console.log(elem, elem.data.length);
            if (elem.data.length >= 2){
                filtered_result.push(elem);
                filtered_rows.push(elem.data);
            }
            if (elem.data.length > max_number_of_columns){
                max_number_of_columns = elem.data.length;
            }
        });
        if (filtered_result.length === 0){
            setCsvError(true);
            setCsvErrorMsg("Found 0 rows.");
        }
        if (max_number_of_columns === 2){
            setPage("uploaded");

            setNewItemsRows([...filtered_rows, ...newItemsRows]);
            
        } 
        else if (max_number_of_columns > 2){
            setSelectedSourceColumnIndex(0);
            setSelectedTargetColumnIndex(1);
            setPage("choose-columns");
        }
        setMaxNumberOfColumns(max_number_of_columns);
        setFileImportContent(filtered_result);
        setShowCSVImport(false);
            setShowCancelCsvImportButton(false);
            setCsvUploaded(true);
            setShowImportCsvButton(true);

    }

    const finishSelectingColumnsClick = () => {
        let rows = [];
        fileImportContent.forEach((row, index)=>{
            if (row.data.length > selectedSourceColumnIndex && row.data.length > selectedTargetColumnIndex){
                let new_row = [row.data[selectedTargetColumnIndex], row.data[selectedSourceColumnIndex]];
                rows.push(new_row);
            }
        });
        //setFileImportContent(filtered_result);
        setNewItemsRows([...rows, ...newItemsRows]);
        setShowCSVImport(false);
        setShowCancelCsvImportButton(false);
        setCsvUploaded(true);
        setShowImportCsvButton(true);
        setPage("uploaded");
    }

    const [deckError, setDeckError] = useState(null);
    const [deckRealtimeUnsubscribe, setDeckRealtimeUnsubscribe] = useState(null);

    useEffect(()=>{
        return () => {
            deckRealtimeUnsubscribe !== null && deckRealtimeUnsubscribe !== undefined && deckRealtimeUnsubscribe();
        }
    },[deckRealtimeUnsubscribe])

    const subscribeToRealTimeDeck = async () => {
        let unsub = await fetchDeckFromCurrentUserRealtime(id, setDeck, setDeckError);
        setDeckRealtimeUnsubscribe(unsub);
    }
    
    const handleRemoveCSVFile = () => {
        setCsvError(false);
        setCsvErrorMsg("");
        setFileImportContent(null);
    }

    const flipTargetSourceLangFunc = () => {
        let temp_val = firstColOption;
        setFirstColOption(secondColOption);
        //console.log("Changing", temp_val, secondColOption);
        setSecondColOption(temp_val);
    }

    const changeFirstColOption = (e) => {
        setFirstColOption(e);
        if (e === languageOptions[0]){
            setSecondColOption(languageOptions[1]);
        } else {
            setSecondColOption(languageOptions[0]);
        }
    }

    const changeSecondColOption = (e) => {
        setSecondColOption(e);
        if (e === languageOptions[0]){
            setFirstColOption(languageOptions[1]);
        } else {
            setFirstColOption(languageOptions[0]);
        }
    }

    const rowInputChange = (e) => {
        let word_index = e.target.getAttribute("data-word-index");
        let row_index = e.target.getAttribute("data-row-index");
        let new_value = e.target.value;

        let new_state = [...newItemsRows];
        new_state[row_index][word_index] = new_value;
        setNewItemsRows(new_state);
    }

    const deleteNewItemRow = (e) => {
        let row_index = e.currentTarget.getAttribute("data-row-index");
        let new_state = [];
        newItemsRows.forEach((row, index)=>{
            if (row_index !== index.toString()){
                //console.log(row, index, row_index);
                new_state.push(row);
            }
        });
        setNewItemsRows(new_state);
    }

    const flipNewItemRow = (e) => {
        let row_index = e.currentTarget.getAttribute("data-row-index");
        let new_state = [];
        newItemsRows.forEach((row, index)=>{
            //console.log(row_index, index);
            if (row_index === index.toString()){
                let new_row = [row[1], row[0]];
                new_state.push(new_row);
            } else {
                new_state.push(row);
            }
        });
        setNewItemsRows(new_state);
    }

    const addEmptyRow = () => {
        setNewItemsRows([...newItemsRows, ['', '']]);
    }

    const handleTabClick = (e) => {
        if (e.key === 'Tab'){
            addEmptyRow();
        }
    }

    const sendRowsToCloudFunctionClick = async () => {
        setPage("uploading-to-database");
        let d = await uploadMultipleCardsToDeckApi(id, newItemsRows, firstColOption.value, secondColOption.value, deck);
        if (d.hasOwnProperty('success') && d['success'] === true){
            setDbUploadFinished(true);
            setPage("upload-success");
            subscribeToRealTimeDeck();
        }
        
    }

    const goBackClick = () => {
        setPage("upload");
        setNewItemsRows([]);
        setCsvError(false);
        setCsvErrorMsg("");
        setFileImportContent(null);
    }

    const [showDeckSelector, setShowDeckSelector] = useState(false);
    const [selectedDeck, setSelectedDeck] = useState(null);

    const addRowsToDeckFromDeckSelector = async (selDeck) => {
        setSelectedDeck(selDeck);
        console.log("Selected deck: ", selDeck, firstColOption, secondColOption)
        setPage("uploading-to-database");
        let d = await uploadMultipleCardsToDeckApi(selDeck.id, newItemsRows, firstColOption.value, secondColOption.value, selDeck, ytId);
        if (d.hasOwnProperty('success') && d['success'] === true){
            setDbUploadFinished(true);
            setPage("upload-success");
            subscribeToRealTimeDeck();
        }
    }
    
    const closeDeckSelector = () => {
        setShowDeckSelector(false);
    }
    const goToCreateDeckPage = () => {
        setShowDeckSelector(true);
    }

    const importQuizletClick = () => {
        let cards_ = textInputValue.split(textInputCardSeparator);
        let list_ = [];
        for (const card_ of cards_){
            let data_ = card_.split(textInputSourceTargetSeparator);
            if (data_.length === 2){
                list_.push([data_[0].trim(), data_[1].trim()]);
            };
        }
        setNewItemsRows(list_);
        setPage("uploaded");
    }

    const selectedSourceColumnOnChange = (index) => {
        setSelectedSourceColumnIndex(index);
    }

    const selectedTargetColumnOnChange = (index) => {
        setSelectedTargetColumnIndex(index);
    }

    /*
     <div className="flex flex-row place-items-center justify-center gap-4">
                            <div className="font-bold text-sm text-gray-600">
                                Total cards in deck: 
                            </div>
                            <div>
                                {deck === null ? 
                                    <Skeleton count={1} height={30} />
                                :
                                    <>
                                        {(deck.hasOwnProperty('temp_adding_items') && deck['temp_adding_items'] === true) ?
                                            <>
                                                {deck.hasOwnProperty('temp_number_of_items_to_add') &&
                                                    <div className="flex ml-0 opacity-80 text-gray-600 flex-row gap-1 text-xs justify-start place-items-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                                        </div>
                                                        <div className="">
                                                            {deck['number_of_items']} (adding {deck['temp_number_of_items_to_add']} items)
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="flex ml-0 text-gray-600 flex-row gap-1 text-sm justify-start place-items-center">
                                                    <div className="text-persian-green">
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </div>
                                                    <div className="">
                                                        {deck['number_of_items']}
                                                    </div>
                                                </div>
                                            
                                            </>
                                        }
                                    
                                    </>
                                }
                            </div>
                        </div>
    */

    //ISO-8859-1
    return (
        <>
            <div className={(design === "page" ? "  " : "") +  " relative"}>
                {design === "page" &&
                <div className="text-xs">
                    <Link to={id !== null ? "/my-decks/"+id+"/import" : "/import"}>Go back</Link>
                </div>
                }
                {page === "upload" &&
                    <>
                        <div className="flex flex-col place-items-center gap-4 h-full">
                            <h1>{typeData.title}</h1>
                            <div className="text-sm">
                                {typeData.uploadDescription}
                            </div>
                            {typeData.inputFormat === "csv" &&
                                <div className="bg-white rounded-xl shadow-xl">
                                    <CSVReader
                                        //onDrop={handleOnCSVDrop} doesn't work
                                        onFileLoad={handleOnCSVFileLoad}
                                        onRemoveFile={handleRemoveCSVFile}
                                        config={{
                                            encoding: "UTF-8"
                                        }}
                                        addRemoveButton
                                    >
                                        <div className="flex flex-col justify-center h-28 italic">
                                            <div className="flex flex-row gap-4 p-2 text-sm">
                                                <div>
                                                    <FontAwesomeIcon icon={faUpload} />
                                                </div> 
                                                <div>
                                                    {typeData.csv}
                                                </div>
                                            </div>
                                        </div>
                                    </CSVReader>
                                </div>
                            }
                            {typeData.inputFormat === "textfield" &&
                                <>
                                    <div className="flex flex-row gap-4 sm:gap-16 justify-center">
                                        <div>
                                            <div className="font-bold text-gray-600 text-base">
                                                Term separator
                                            </div>
                                            <div>
                                                <Select onChange={changeTermSeparator} isSearchable={false} options={termSeparatorOptions} value={termSeparatorOptionsDict[textInputSourceTargetSeparator]}  />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold text-gray-600 text-base">
                                                Card separator
                                            </div>
                                            <div>
                                                <Select onChange={changeCardSeparator} isSearchable={false} options={cardSeparatorOptions} value={cardSeparatorOptionsDict[textInputCardSeparator]}  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-1/2  m-auto">
                                        <textarea placeholder={typeData['inputPlaceholder']} value={textInputValue} onChange={(e)=>setTextInputValue(e.target.value)} className="bg-white rounded-lg shadow-xl w-full h-96 border-none outline-none p-4" />
                                    </div>
                                    <div>
                                        <Button onClick={importQuizletClick} text="Continue" color="green" />
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                {page === "choose-columns" &&
                    <>
                        <div className="flex flex-col gap-8 py-6">
                            <h1>Which columns do you want to import?</h1>
                            <div className="text-center">
                                We found {maxNumberOfColumns} columns in your file. Please choose which columns you want to import, as we currently only support importing two columns (source language and target language).
                            </div>
                            <div className="flex flex-col place-items-center gap-8 lg:flex-row justify-evenly">
                                <div className="flex flex-col gap-3">
                                    <div className="font-bold muted-text">
                                        {languageOptions[0].label} (target)
                                    </div>
                                    <div className="">
                                        {fileImportContent !== null && fileImportContent.map((row, index)=>(
                                            <>
                                                {index < 3 &&
                                                    <div key={"column"+index} className="">
                                                        <label className="flex flex-row place-items-center gap-4">
                                                            <input type="radio" value={selectedSourceColumnIndex} checked={selectedSourceColumnIndex === index} onChange={()=>selectedSourceColumnOnChange(index)} name={"source"} />
                                                            Column {index+1}
                                                            {row.data.map((cell, cell_index)=>(
                                                                <>
                                                                    {index === cell_index &&
                                                                        <div key={"cell_"+index+"_"+cell_index} className="border p-2">
                                                                            ({cell})
                                                                        </div>  
                                                                    }
                                                                </>
                                                            ))}
                                                        </label>
                                                    </div>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <div className="font-bold muted-text">
                                        {languageOptions[1].label} (source)
                                    </div>
                                    <div className="">
                                        {fileImportContent !== null && fileImportContent.map((row, index)=>(
                                            <>
                                                {index < 3 &&
                                                    <div key={"column_target"+index} className="">
                                                        <label className="flex flex-row place-items-center gap-4">
                                                            <input type="radio" value={selectedTargetColumnIndex} checked={index===selectedTargetColumnIndex} onChange={()=>selectedTargetColumnOnChange(index)} name={"target"} />
                                                            Column {index+1}
                                                            {row.data.map((cell, cell_index)=>(
                                                                <>
                                                                    {index === cell_index &&
                                                                        <div key={"cell_"+index+"_"+cell_index} className="border p-2">
                                                                            ({cell})
                                                                        </div>  
                                                                    }
                                                                </>
                                                            ))}
                                                        </label>
                                                    </div>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row justify-center">
                                <Button onClick={finishSelectingColumnsClick} disabledDesign={(selectedSourceColumnIndex===selectedTargetColumnIndex || selectedSourceColumnIndex === null || selectedTargetColumnIndex === null)} disabled={(selectedSourceColumnIndex===selectedTargetColumnIndex || selectedSourceColumnIndex === null || selectedTargetColumnIndex === null)} text="Continue" color="charcoal" />
                            </div>
                        </div>
                    </>
                }
                {page === "uploaded" &&
                    <>
                        <div className="flex flex-col gap-4 h-full">
                            {hideTitles !== true &&
                                <h1>{typeData['uploaded_title']}</h1>
                            }
                            <div className="text-sm text-center">{typeData['uploadedPageText']}</div>
                            {newItemsRows === null && <>Loading...</>}
                            {newItemsRows !== null && 
                                <>
                                    <div className="text-gray-600 text-xs font-bold text-left">
                                        Number of rows: {newItemsRows.length}
                                    </div>
                                </>
                            }
                            <div className="max-h-96 w-full min-h-48 overflow-auto">
                                <table className="w-full p-4 m-auto  shadow-lg bg-white my-4">
                                    <thead>
                                        {(firstColOption !== null && secondColOption !== null) &&
                                        <tr className="text-center font-bold">
                                            <th width="" className="bg-persian-green w-1/2 top-0 text-white border px-8 py-4">
                                                <Select onChange={changeFirstColOption} value={firstColOption} isSearchable={ false } autoFocus={true} options={languageOptions} placeholder="Target language" className="min-w-full text-gray-500 text-base w-full"  />
                                            </th>
                                            <th width="" className="bg-persian-green top-0 text-white border px-8 py-4">
                                                <Icon onClick={flipTargetSourceLangFunc} icon={<FontAwesomeIcon icon={faRepeatAlt} />} />
                                            </th>
                                            <th width="" className="bg-persian-green w-1/2 top-0 text-white border  px-8 py-4">
                                                <Select onChange={changeSecondColOption} value={secondColOption} isSearchable={ false } options={languageOptions} placeholder="Target language" className="min-w-full text-gray-500 text-base w-full"  />
                                            </th>
                                        </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {dataFetched && newItemsRows !== null && newItemsRows.map((row,row_index)=>(
                                            <>
                                                <tr className="text-center">
                                                    {row.map((word, word_index)=>(
                                                        <>
                                                            {word_index < 2 &&
                                                            <>
                                                            <td className="border px-8 py-4 flex flex-row gap-2" >
                                                                <div className="muted-text text-xs">
                                                                    {row_index}
                                                                </div>
                                                                {word_index === 0 &&
                                                                    <input data-row-index={row_index} data-word-index={word_index} onChange={rowInputChange} key={"csv_input_"+row_index+"_"+word_index} type="text" className="bg-gray-200 min-w-36 w-full appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={word} />
                                                                }
                                                                {word_index === 1 &&
                                                                    <input data-row-index={row_index} data-word-index={word_index} onKeyDown={handleTabClick} onChange={rowInputChange} key={"csv_input_"+row_index+"_"+word_index} type="text" className="bg-gray-200 appearance-none min-w-36 w-full border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" value={word} />
                                                                }
                                                            </td>
                                                            {word_index === 0 &&
                                                                <>
                                                                    <td>
                                                                        <div className="flex flex-row gap-3 justify-center">
                                                                            <Icon color="green" key={"row_flip_"+row_index} data-row-index={row_index} onClick={flipNewItemRow}  icon={<FontAwesomeIcon icon={faRepeatAlt} />} />
                                                                            <Icon color="red" key={"row_delete"+row_index} data-row-index={row_index} onClick={deleteNewItemRow} icon={<FontAwesomeIcon icon={faTrashAlt} />} />
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            }
                                                            </>
}
                                                        </>
                                                    ))
                                                    }  
                                                </tr>
                                            </>
                                        ))
                                        }
                                        {newItemsRows.length === 0 &&
                                            <>
                                                <td colSpan={3} className="border px-8 py-4 text-center italic">
                                                    Start by adding a row.
                                                </td>
                                            </>
                                        }
                                        <tr className="text-center">
                                            <td className="border px-8 py-4">
                                            </td>
                                            <td className="border px-8 py-4">
                                                <Icon color="green" icon={<FontAwesomeIcon icon={faPlus} />} onClick={addEmptyRow} />
                                            </td>
                                            <td className="border px-8 py-4"></td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-col gap-3 sm:flex-row justify-center">
                                {typeData['showBackToFirstPageButton'] &&
                                    <Button onClick={goBackClick} text="Go back" color="charcoal" />
                                }
                                {(mode === "edit-deck" && newItemsRows.length > 0) && 
                                    <Button onClick={sendRowsToCloudFunctionClick} text="Add all rows to deck" color="green" />
                                }
                                {(mode === "create-deck" && newItemsRows.length > 0) && 
                                    <Button onClick={goToCreateDeckPage} text="Add all rows to a deck" color="green" />
                                }
                            </div>
                        </div>
                    </>
                }
                {showDeckSelector &&
                    <>
                        <DeckSelectorModal saveChangesFunction={addRowsToDeckFromDeckSelector} targetLanguage={globalSelectedTargetLanguage} sourceLanguage={globalSelectedSourceLanguage} closeFunction={closeDeckSelector} />
                    </>
                }
                {page === "uploading-to-database" &&
                    <>
                        <div className="flex flex-col justify-center h-full gap-3">
                            <h1>Uploading...</h1>
                            <div className="text-center text-sm text-gray-600">
                                Please wait a few moments while we connect to the server.
                            </div>
                        </div>
                    </>
                }
                {page === "upload-success" &&
                    <>
                        <div className="flex flex-col gap-6">
                        <div className="w-full hidden">
                            <img src={YalangoComputer} alt="Yalango computer" className=" p-4 w-48 lg:w-64 m-auto" />
                        </div>
                        <h1>Upload successful</h1>
                        <div className="text-center text-sm text-gray-600">
                            <div>Your items are being uploaded to the server. You can leave this page and the process will continue in the background.</div>
                        </div>
                       
                   
                        <div className="text-center flex flex-row justify-center">
                            <Link className="no-underline" to={"/my-decks/"+(mode==="edit-deck" ? id : selectedDeck.id)}>
                                <Button text="Go to deck" />
                            </Link>
                        </div>
                        </div>
                    </>
                }
            </div>
        </>
  )
}

export default ImportCSVToDeck;