import React, {useEffect, useState} from 'react';
import useTranslate from '../../../hooks/useTranslate';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import Button from '../../../components/general/Button';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import CountryFlag from '../../../components/languages/CountryFlag';


const TranslatorWidget = ({showHeadline}) => {
    let {allLanguages, targetLanguageOptions, sourceLanguageOptions, activeSourceLanguageOptions, activeTargetLanguageOptions, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage} = useLanguagesContext();
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [translationWorking, setTranslationWorking] = useState(false);
    const [translatedText, setTranslatedText] = useState(null);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [translatedTextHistory, setTranslatedTextHistory] = useState([]);
    const {translateText} = useTranslate();
    const [inputText, setInputText] = useState("");

    useEffect(()=> {
        setTargetLanguage(globalSelectedTargetLanguage);
    },[globalSelectedTargetLanguage]);

    useEffect(()=> {
        setSourceLanguage(globalSelectedSourceLanguage);
    },[globalSelectedSourceLanguage]);

    const handleSourceLanguageChange = (option) => {
        setSourceLanguage(option.value);
        setError(false);
    }

    const handleTargetLanguageChange = (option) => {
        setTargetLanguage(option.value);
        setError(false);
    }

    const inputOnChange = (e) => {
        setInputText(e.target.value);
        setError(false);
    }

    const translateButtonClick = async () => {
        if (inputText === ""){return null;}
        setTranslationWorking(true);
        let r = await translateText(inputText, targetLanguage);
        if (r.success){
            setTranslatedText(r.data);
            setTranslatedTextHistory([...translatedTextHistory, {'translations': r.data, 'input': inputText, 'targetLanguage': targetLanguage, 'sourceLanguage': sourceLanguage}]);
        } else {
            setError(true);
            setErrorMsg(r.error.message);
        }
        setTranslationWorking(false);
    }

  return (
        <>
            
            <div className="flex flex-col gap-5">
                {showHeadline !== false &&
                <h1>Pocket translator</h1>
                }
                <div className="text-sm">
                    Quickly translate words or phrases when needed using Yalango's Pocket Translator.
                </div>
                <div className="flex flex-col sm:flex-row w-full gap-4 justify-center ">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="">
                            {(sourceLanguageOptions !== null) &&
                                <Select options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][sourceLanguage]} autoFocus={false} placeholder="Source language" className=" text-gray-500 text-base" onChange={handleSourceLanguageChange} />
                            }
                        </div>
                        <div>
                            <input placeholder="Text to translate"  value={inputText} onChange={inputOnChange} type="text" className="bg-gray-200 appearance-none border-2 w-full  rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <div className="">
                            {(sourceLanguageOptions !== null) &&
                                <Select options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][targetLanguage]} autoFocus={false} placeholder="Target language" className=" text-gray-500 text-base" onChange={handleTargetLanguageChange} />
                            }
                        </div>
                        <div className="">
                            {(!translationWorking && translatedText === null && !error) &&
                                <>
                                    <input disabled={true} className="bg-gray-200 cursor-not-allowed appearance-none border-2  w-full rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                </>
                            }
                            {translationWorking &&
                                <>
                                    <div className="text-center text-xl text-black">
                                        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                    </div>
                                </>
                            }
                            {!translationWorking && translatedText !== null &&
                                <>
                                    {translatedText.map((t, index)=>(
                                        <>
                                            <div key={"translated_"+index}>
                                                <input disabled={true} value={t} className="bg-gray-200 cursor-not-allowed appearance-none border-2  w-full rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                                            </div>
                                        </>
                                    ))}
                                </>
                            }
                        
                        </div> 
                        
                    </div>
                    
                </div>
                {error &&
                                <>
                                    <div className="text-red-500 my-3">{errorMsg}</div>
                                </>
                            }
                <div className="text-center flex flex-row justify-center ">
                    <Button color="green" onClick={translateButtonClick} text="Translate" />
                </div>
            </div>
        </>
  )
}

export default TranslatorWidget