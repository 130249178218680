import React, {useEffect, useState} from 'react';
import useFeedback from '../../hooks/useFeedback';
import {motion} from 'framer-motion';
import FeedbackItemModal from '../../components/feedback/modals/FeedbackItemModal';
import ReactTooltip from 'react-tooltip';
import {Helmet} from 'react-helmet';
import GeneralFeedback from '../../components/feedback/modals/GeneralFeedback';
import {useAuth} from '../../contexts/AuthContext';

const MyFeedback = () => {
    const { getCurrentUserFeedback, 
        getFeedbackMessagesInRealtime, 
        sendMessage } = useFeedback();
    const {currentUser} = useAuth();
    const [feedback, setFeedback] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showFeedbackItemModal, setShowFeedbackItemModal] = useState(false);
    const [showNewFeedbackModal, setShowNewFeedbackModal] = useState(false);
    const [messages, setMessages] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [sendingNewMessageLoading, setSendingNewMessageLoading] = useState(false);
    
    useEffect(()=>{
        const fetchData = async () => {
            let docs = await getCurrentUserFeedback();
            setFeedback(docs);
            setLoading(false);
        }
    
        if (loading === true){
            fetchData();
        }
    },[loading, getCurrentUserFeedback]);

    const feedbackItemClick = (item) => {
        setSelectedItem(item);
        setShowFeedbackItemModal(true);
    }
    

    const closeFeedbackItemModal = () => {
        setSelectedItem(null);
        setShowFeedbackItemModal(false);
        setMessages(null);
        setNewMessage("");
    }

    const closeNewFeedbackModal = () => {
        setShowNewFeedbackModal(false);
    }

    useEffect(()=>{
        let unsubscribe = null;
        setNewMessage("");
        if (selectedItem !== null){
            const getMessagesInRealtime = async () => {
                unsubscribe = await getFeedbackMessagesInRealtime(selectedItem.doc_id, setMessages);
            }
            getMessagesInRealtime();
        }

        return () => {
            unsubscribe !== null && unsubscribe();
        }
    },[selectedItem]);

    const sendNewMessageClick = async () => {
        if (newMessage !== ""){
            setSendingNewMessageLoading(true);
            await sendMessage(selectedItem.doc_id, newMessage);
            setNewMessage("");
            setSendingNewMessageLoading(false);
        }
    }

  return (
      <>
            <ReactTooltip id="feedback-item-tooltip" effect="solid" />
            <Helmet>
                <title>My feedback - Yalango</title>
                <meta name="description" content={"Review the feedback you've submitted to Yalango."} />
            </Helmet>
            <div className=" ">
                <h1>My feedback</h1>
                <p className="text-center text-gray-700">Submit feedback and follow its progress by clicking on your submissions below.</p>
                <motion.div whileHover={{scale:1.02}} className="m-auto w-1/6 text-center my-2">
                    <button onClick={()=>setShowNewFeedbackModal(true)} className="bg-persian-green p-4 text-white rounded-lg shadow-2xl text-center">Submit feedback</button>
                </motion.div>
                {showNewFeedbackModal && 
                    <>
                        <GeneralFeedback closeFunction={closeNewFeedbackModal} />
                    </>
                }
                <div className="grid grid-cols-1">
                   
                   {!loading && 
                        <>
                            {feedback.map((item, index)=>(
                                <>
                                <motion.div key={"feedback_item_"+index} onClick={()=>feedbackItemClick(item)} whileHover={{scale:1.01}} className={(item.status === "solved" ? " bg-persian-green text-white" : ' ')+(item.status === "read" ? " bg-charcoal text-white" : ' ')+(item.status === "work in progress" ? " bg-sandy-brown text-white" : ' ')+" flex bg-white sm:h-24 overflow-y-hidden text-black cursor-pointer p-3 shadow-lg  rounded-lg flex-row justify-start place-items-center gap-4"}>
                                    
                                    <div className="w-full p-2 flex flex-col sm:flex-row gap-2 justify-between sm:place-items-center">
                                        <div className="flex flex-col place-items-center text-center sm:text-left sm:place-items-start">
                                            <div className="flex flex-row gap-6">
                                                {'number_of_messages' in item &&
                                                    <div className="font-bold">
                                                        {item.number_of_messages+1} message{item.number_of_messages+1 === 1 ? '' : 's'}
                                                    </div>
                                                }
                                                <div className="italic">
                                                    {item.text.length > 40 ? <>{item.text.substring(0,40).trim()}...</> : item.text}
                                                </div>
                                                
                                            </div>
                                            <div className="italic text-left  text-xs py-2">
                                                {item.created_timestamp.toDate().toDateString()}
                                                
                                            </div>
                                        </div>
                                        <div className="w-48 m-auto md:m-0 text-center">
                                            {item.status === "solved" && 
                                                <div data-for="feedback-item-tooltip" data-tip="This functionality has been implemented" className="rounded-lg shadow-2xl text-black font-bold p-3 bg-gray-200">
                                                    Implemented
                                                </div>
                                            }
                                            {item.status === "work in progress" && 
                                                <div data-for="feedback-item-tooltip" data-tip="Yalango is working on this functionality" className="rounded-lg shadow-2xl text-black font-bold p-3 bg-gray-200">
                                                    Work in progress
                                                </div>
                                            }
                                            {item.status === "read" && 
                                                <div data-for="feedback-item-tooltip" data-tip="Yalango has read this feedback" className="rounded-lg shadow-2xl text-black font-bold p-3 bg-gray-200">
                                                    Feedback read
                                                </div>
                                            }
                                            {item.status === undefined && 
                                                <div data-for="feedback-item-tooltip" data-tip="The feedback has been sent" className="rounded-lg shadow-2xl text-black font-bold p-3 bg-gray-200">
                                                    Feedback sent
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </motion.div>
                                </>
                            ))}
                        </>
                   } 
                </div>
                {showFeedbackItemModal && 
                    <>
                        <FeedbackItemModal sendingNewMessageLoading={sendingNewMessageLoading} currentUser={currentUser} sendNewMessageClick={sendNewMessageClick} newMessage={newMessage} setNewMessage={setNewMessage} messages={messages} selectedItem={selectedItem} size="3xl" closeFunction={closeFeedbackItemModal} />
                    </>
                }
          </div>
      </>
  )
};

export default MyFeedback;
