import React, {useState, useEffect} from 'react';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlay, faGamepadModern } from '@fortawesome/pro-solid-svg-icons';
import YalangoImage from '../../assets/svg/yalango/YalangoMovie.svg';

const MiniGamesCard = () => {

    const [dbLoading, setDbLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showList, setShowList] = useState(false);
    const {refreshSpacedRepetitionQueue, 
        mostUrgentNextReviewItems
    } = useUserStatisticsContext();
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();

    return (
        <>
            <div className="p-8 h-full bg-gradient-to-r from-charcoal to-blue-900 w-full shadow-xl flex flex-col gap-6 rounded-xl transition-all ease-in-out duration-300">
                        <div className="font-semibold text-lg text-white flex flex-col gap-4">
                            {globalSelectedTargetLanguage === null ? <Skeleton count={1} /> : 
                                <>
                                    <Link className="no-underline" to={`/my-vocabulary/play?back=dashboard`}>
                                        <div className="flex flex-row justify-center gap-3">
                                            <div>
                                                <FontAwesomeIcon icon={faGamepadModern} className="" />
                                            </div>
                                            <div>
                                                Minigames
                                            </div>
                                        </div>
                                    </Link>
                                </>
                            }
                        
                        </div>
                    
                <div>

                    <img src={YalangoImage} alt="Tim from Yalango" className="w-36 mx-auto" />
                    
                </div>
                <div className="flex flex-row gap-8 items-start">
                       
                <div className="flex flex-col gap-4">
                    

                </div>
                </div>
            </div>
        </>
    )
}

export default MiniGamesCard;