import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import { useCallback } from 'react';

export default function useCourses() {
    
    let {currentUser} = useAuth();

    const fetchYalangoCoursesForLanguage = useCallback(async ({targetLanguage}) => {
        const courses = [];
        const courseRef = projectFirestore.collection("courses");
        const snapshot = await courseRef.where("target_ISO_639-1", "==", targetLanguage).where("uid", "==", "d1AsNHNfQUWYYoplP3UNdKcATss1").get();
        snapshot.forEach(doc => {
            courses.push({...doc.data(), doc_id: doc.id});
        });
        return courses;
    },[]);

    const fetchCourseFromId = useCallback(async ({courseId}) => {
        // with document with course_id = courseId as variable
        courseId = parseInt(courseId);
        const courseRef = projectFirestore.collection("courses");
        const snapshot = await courseRef.where("course_id", "==", courseId).get();
        let course = null;
        snapshot.forEach(doc => {
            course = {...doc.data(), doc_id: doc.id};
        });
        return course;
    },[]);

    const fetchCourseLessons = useCallback(async ({lessonIds: lessonIds, course:course}) => {
        //check course_lessons for docu ments with lesson_id equal to item in courseIds. Do this for all ids in variable courseIds. The in operator has a limit of 30 items.
        // course is added to order the lessons correctly
        let lessons = [];
        if (lessonIds === null || lessonIds === undefined || lessonIds.length === 0){return lessons};
        const lessonRef = projectFirestore.collection("course_lessons");
        if (lessonIds.length <= 30){
            console.log("Fetching lessons for course ids: ", lessonIds)
            const snapshot = await lessonRef.where("lesson_id", "in", lessonIds).get();
            snapshot.forEach(doc => {
                lessons.push({...doc.data(), doc_id: doc.id});
            });
        } else {
            // do it in batches of 30
            const batchedCourseIds = [];
            let i, j, temparray, chunk = 30;
            for (i=0, j=lessonIds.length; i<j; i+=chunk){
                temparray = lessonIds.slice(i, i+chunk);
                batchedCourseIds.push(temparray);
            }
            for (let i=0; i<batchedCourseIds.length; i++){
                const snapshot = await lessonRef.where("lesson_id", "in", batchedCourseIds[i]).get();
                snapshot.forEach(doc => {
                    lessons.push({...doc.data(), doc_id: doc.id});
                });
            }
        }
        lessons = lessons.sort((a, b) => course.lesson_ids.indexOf(a.lesson_id) - course.lesson_ids.indexOf(b.lesson_id));
        //getFullThumbnailPath for easier use in frontend
        lessons = lessons.map(lesson => {
            if (lesson.hasOwnProperty("thumbnail_200x200") && lesson.thumbnail_200x200 !== null && lesson.thumbnail_200x200 !== undefined){
                lesson['full_thumbnail_path_200x200'] = getFullThumbnailPath({dbPath: lesson.thumbnail_200x200});
            } else {
                lesson['thumbnail_200x200'] = null;
                lesson['full_thumbnail_path_200x200'] = null;
            }
            return lesson;
        });
        return lessons;
    },[]);

    const fetchCourseLesson = useCallback(async ({lessonId}) => {
        try {
            lessonId = parseInt(lessonId);
        } catch {
            return null;
        }
        const lessonRef = projectFirestore.collection("course_lessons");
        const snapshot = await lessonRef.where("lesson_id", "==", lessonId).get();
        let lesson = null;
        snapshot.forEach(doc => {
            lesson = {...doc.data(), doc_id: doc.id};
        });
        return lesson;
    },[]);

    const createNewCourse = useCallback(async ({targetLanguage, sourceLanguage, name, parentFolderDocId}) => {
        console.log("Creating new course: ", targetLanguage, sourceLanguage, name, parentFolderDocId, currentUser)
        if (currentUser === null){return false};
        if (name === "" || name === null || name === undefined){return false};
        
        const createNewCourseFunction =  projectFunctions.httpsCallable('createNewCourse');
        let data = await createNewCourseFunction({
            'targetLanguage': targetLanguage,
            'sourceLanguage': sourceLanguage,
            'name': name, 
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        }).catch(err=>{return false});
        if (data === false){return false};

        return data;
    },[]);

    const saveChangesToCourse = useCallback(async ({courseDocId=null, name=null}) =>  {
        if (courseDocId === null || courseDocId === undefined){return false}
        let obj = {
            'last_updated_timestamp': timeStamp
        };
        if (name !== null){
            obj['name'] = name;
        };
        let success = true; 

        await projectFirestore.collection("courses").doc(courseDocId).set(obj, {merge: true}).catch(err=>{
            console.log("Error: ", err);
            success = false;
        });

        return success;

    },[]);

    const saveChangesToLesson = useCallback(async ({lessonDocId=null, contentId=undefined, contentType=undefined, lessonName=undefined, contentGame=undefined}) =>  {
        if (lessonDocId === null || lessonDocId === undefined){return false}
        let obj = {
            'last_updated_timestamp': timeStamp
        };
        if (contentId !== undefined){
            if (contentId !== null){
                obj['content_id'] = parseInt(contentId);
            } else {
                obj['content_id'] = null; //null is used to remove the content_id
            }
        }
        if (lessonName !== undefined){
            obj['lesson_name'] = lessonName;
        };
        if (contentType !== undefined){
            obj['content_type'] = contentType;
        };
        if (contentGame !== undefined){
            obj['content_game'] = contentGame;
        };
        let success = true; 

        await projectFirestore.collection("course_lessons").doc(lessonDocId).set(obj, {merge: true}).catch(err=>{
            console.log("Error: ", err);
            success = false;
        });

        return success;
    },[]);

    const fetchAllCoursesFromCurrentUserInRealtime = useCallback(async (setStateFunc) => {
        let query =  projectFirestore.collection('courses').where('uid', '==', currentUser.uid);
        return query.onSnapshot(querySnapshot => {
            let courses = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                courses.push(d);
            }
            setStateFunc(courses);
        });
    },[]);

    const renameCourse = useCallback(async (courseDocId, newName) => {
        // dont refactor to {} in variables due to the setup of modals in my library
        console.log("Renaming");
        return projectFirestore.collection("courses").doc(courseDocId).set({
            'name': newName, 
            'last_updated_timestamp': timeStamp
        }, {merge: true});
    },[]);

    const deleteCourseFromDocId = async (docId) => {
        let path = projectFirestore.collection("courses").doc(docId);
        let r = await path.delete();
        return r;
    }

    const getFullThumbnailPath = useCallback(({dbPath}) => {
        let path = "https://firebasestorage.googleapis.com/v0/b/soothing-languages.appspot.com/o/"+dbPath.replaceAll("/", "%2F") + "?alt=media";
        console.log("Path: ", path);
        return path;
    },[]);

    const getDefaultThumbnailImage = useCallback(() => {
        //shutterstock_2282856415_200x200.webp
        let dbPath = "images/courses/thumbnails/thumbnails_200x200/shutterstock_2282856415_200x200.webp";
        return getFullThumbnailPath({dbPath});
    },[]);

    const uploadLessonThumnbnail = useCallback(async ({base64Image, file, contentType, lesson}) => {
        //console.log("Uploading image: ", base64Image, file, contentType, lesson);
        const uploadImage = projectFunctions.httpsCallable('uploadCourseThumbnail');
        
        try {
            const result = await uploadImage({
            base64Image,
            fileName: file.name,
            contentType: contentType,
            });
            console.log('Upload successful');
            //update database with new thumbnail for lesson
            let lessonDocId = lesson.doc_id;
            let path = projectFirestore.collection("course_lessons").doc(lessonDocId);
            let dbPath = "images/courses/thumbnails/thumbnails_200x200/"+file.name.split(".")[0] + "_200x200" + ".webp";//all are converted to webp
            let r = await path.set({
                'thumbnail_200x200': dbPath,
                'last_updated_timestamp': timeStamp
            }, {merge: true});
            return dbPath;

        } catch (error) {
            console.error('Error uploading file: ', error);
        }
    },[]);

    const uploadCourseThumnbnail = useCallback(async ({base64Image, file, contentType, course}) => {
        //console.log("Uploading image: ", base64Image, file, contentType, lesson);
        const uploadImage = projectFunctions.httpsCallable('uploadCourseThumbnail');
        
        try {
            const result = await uploadImage({
            base64Image,
            fileName: file.name,
            contentType: contentType,
            });
            console.log('Upload successful');
            //update database with new thumbnail for lesson
            let courseDocId = course.doc_id;
            let path = projectFirestore.collection("courses").doc(courseDocId);
            let dbPath = "images/courses/thumbnails/thumbnails_200x200/"+file.name.split(".")[0] + "_200x200" + ".webp";//all are converted to webp
            let r = await path.set({
                'thumbnail_200x200': dbPath,
                'last_updated_timestamp': timeStamp
            }, {merge: true});
            return dbPath;

        } catch (error) {
            console.error('Error uploading file: ', error);
        }
    },[]);

    const addNewLessonToCourse = useCallback(async ({course, lessonName, contentType, contentId}) => {
        //add new backend side lesson to course_lessons with course_id = course.course_id, content_type = contentType, content_id = contentId, lesson_name = lessonName
        const addLesson = projectFunctions.httpsCallable('createNewCourseLesson');
        let data = await addLesson({
            'course': course,
            'lesson_name': lessonName,
            'content_type': contentType,
            'content_id': contentId
        }).catch(err=>{return false});
    },[]);

    return {
        fetchYalangoCoursesForLanguage, 
        fetchCourseFromId, 
        fetchCourseLessons,
        fetchCourseLesson, 
        createNewCourse, 
        saveChangesToCourse, 
        fetchAllCoursesFromCurrentUserInRealtime, 
        renameCourse, 
        saveChangesToLesson,
        deleteCourseFromDocId, 
        getFullThumbnailPath, 
        uploadLessonThumnbnail, 
        uploadCourseThumnbnail, 
        addNewLessonToCourse, 
        getDefaultThumbnailImage
    }

}