import React from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '../../../general/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/pro-duotone-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faVolume } from '@fortawesome/pro-solid-svg-icons';
import { faVolumeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';

const HelpMenu = ({isPremium, backToPlayPageLink, autoPlayPronunciations,  autoPlayPronunciationsClick, helpClick, playMode}) => {
   
    /*
 <div className="text-lg" onClick={helpClick}>
                                    <div data-tip="How to play" data-for="flashcard-game-tooltip">
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faQuestion} />} />
                                    </div>
                                </div>
    */
    return (
        <>
            {backToPlayPageLink !== null &&
                <>
                    <div className="w-full">
                        <ReactTooltip id="flashcard-game-tooltip" effect="solid" />      
                            <div className=" flex flex-row justify-end gap-6">
                                {isPremium && playMode === "target_first" &&
                                    <div className="text-base" onClick={autoPlayPronunciationsClick}>
                                        <div data-tip="Autoplay pronunciations" data-for="flashcard-game-tooltip">
                                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={autoPlayPronunciations ? faVolume : faVolumeSlash} />} />
                                        </div>
                                    </div>
                                }
                               
                                {backToPlayPageLink !== null &&
                                    <div className="text-base">
                                        <Link to={backToPlayPageLink}>
                                            <div data-tip="Go back to play page" data-for="flashcard-game-tooltip">
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faX} />} />
                                            </div>
                                        </Link>
                                    </div>
                                }
                            </div>
                    </div>  
                </>
            }  
        </>
    )
}

export default HelpMenu 