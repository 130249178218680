import React from 'react';
import {Link} from 'react-router-dom';

const Changelog = () => {
    return (
        <>
            <div className="  flex flex-col gap-6 text-center">
                <h1>Changelog</h1>
                <div>
                Follow along the development of Yalango. Thank you for giving us feedback if you have any ideas. 
                </div>
                    <div className="text-left">
                        <div className="w-full">
                            <table className="mx-auto w-full lg:w-5/6 table-fixed  my-4">   
                                <thead>  
                                    <tr className="text-left font-bold">
                                        <th className="bg-persian-green w-1/3 md:w-1/5 text-white border px-8 py-4">
                                            Date
                                        </th>
                                        <th className="bg-persian-green w-full   text-white border  px-8 py-4">
                                            News
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            10.07.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Improvements to dashboard page
                                                </li> 
                                                <li>
                                                    Improvements on course technology
                                                </li>
                                            </ul>
                                        </td>
                                </tr>  
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            09.07.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Add functionality for courses
                                                </li> 
                                                <li>
                                                    Started working on Norwegian course
                                                </li>
                                            </ul>
                                        </td>
                                </tr>  
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            05.07.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    New navbar
                                                </li> 
                                                <li>
                                                    Various improvements
                                                </li>
                                            </ul>
                                        </td>
                                </tr>  
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            17.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Improvements to My Library page
                                                </li> 
                                                
                                            </ul>
                                        </td>
                                </tr>  
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            15.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Almost finished basic grid layout for My Library
                                                </li> 
                                                
                                            </ul>
                                        </td>
                                </tr>  
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            12.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Better design on deck pages
                                                </li> 
                                                <li>
                                                    Better design on memory grid game
                                                </li> 
                                                <li>
                                                    Added Mandarin Chinese to pinyin converter
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            10.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Updated landing page
                                                </li> 
                                                <li>
                                                    Added hanzi animations and stroke order game for Chinese
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            09.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Added option to automatically show pinyin on Chinese cards (option in My Languages settings)
                                                </li> 
                                                <li>
                                                    Pinyin can now be shown in the games
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            08.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Creating items are now done in a modal instead
                                                </li> 
                                                <li>
                                                    You can now choose source and target scripts when creating Chinese decks
                                                </li> 
                                                <li>
                                                    Improved CSV import functionality for files with more than 2 columns
                                                </li> 
                                                <li>
                                                    Student or teacher option on My Languages
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            07.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Improved design of My Languages page
                                                </li> 
                                                <li>
                                                    Choose default script for Chinese as target or source
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            05.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Cleaner dashboard
                                                </li> 
                                                <li>
                                                    Quick notes moved to widget menu instead
                                                </li> 
                                                <li>
                                                    Made prototype for write-hanzi game
                                                </li> 
                                                <li>
                                                    Various bugs and design improvements
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            04.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Started work on new dashboard
                                                </li> 
                                                <li>
                                                    Created component for spaced repetition list
                                                </li> 
                                                <li>
                                                    Made prototype for new quick note feature
                                                </li> 
                                                <li>
                                                    Decks and cards now differentiate between Hanzi and pinyin/zhuyin
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            03.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Design updates
                                                </li> 
                                            </ul>
                                        </td>
                                </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            02.04.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Started refactoring old code
                                                </li> 
                                            </ul>
                                        </td>
                                    </tr>
                                <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            20.03.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    The add card button is now visible on mobile 
                                                </li>  
                                                <li>
                                                    Removed premium plan (we will make it better and re-add it in the future)
                                                </li>  
                                                <li>
                                                    Adjusted free Google translate quota to 50 translations per month
                                                </li>  
                                                <li>
                                                    Added separate navbar on homepage and updated content
                                                </li>  
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            19.03.24
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    We've decided to start updating the changelog page again
                                                </li>  
                                                <li>
                                                    Started rethinking design improvements on the website
                                                </li>
                                                <li>
                                                    Cleaner design of the vocabulary item pages
                                                </li>
                                                <li>
                                                    Updated frontpage
                                                </li>
                                                <li>
                                                    Re-added practical widget menu
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            04.02.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Made browse mode as a study tool on game page
                                                </li>  
                                                <li>
                                                    Added more languages. 100+ languages are now available
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            03.02.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    You can now flip view mode on flashcard game
                                                </li>  
                                                <li>
                                                    Made instruction modal for flashcard game
                                                </li>   
                                                <li>
                                                    Updated the deck play page + updated the icon library
                                                </li>     
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            02.02.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Added sorting functionality on My Decks page
                                                </li> 
                                                <li>
                                                    You can now duplicate decks (made it work using cloud functions)
                                                </li>
                                                <li>
                                                    Number of items in decks are counted and displayed
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            01.02.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Made admin page to update languages
                                                </li> 
                                                <li>
                                                    Classified all the languages into language families
                                                </li>
                                                <li>
                                                    You can now start learning languages directly from the language pages + add to wishlist
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            31.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Made admin dashboard
                                                </li> 
                                                <li>
                                                    Admin page for feedback + visual feedback on My Feedback page
                                                </li> 
                                                <li>
                                                    Hided language selector if one active language
                                                </li>    
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            28.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    It's now possible to delete decks in organized view
                                                </li> 
                                                <li>
                                                    Made simple feedback page
                                                </li>     
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            27.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Fixed many bugs
                                                </li>    
                                                <li>
                                                    Made My Decks page more visually appealing
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            25.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    The big screen navbar now shows which page you're on in the dashboard
                                                </li>    
                                                <li>
                                                    Started implementing Michael's suggestions
                                                </li>   
                                                <li>
                                                    Moved all pages into dashboard menu
                                                </li>  
                                                <li>
                                                    Started implementing database data as contexts instead
                                                </li>    
                                                <li>
                                                    Made the select components more user friendly on the deck directory page (groups and auto select) 
                                                </li>
                                                <li>
                                                    The add deck modal now works for adding decks in "All my decks" view on My Decks page
                                                </li>     
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            24.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Made alternative grid view on My Decks page. Did other UI improvements in the organized view
                                                </li>    
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            05.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Finished quiz statistics implementation and showing basic quiz stats on My Statistics page
                                                </li>    
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            02.01.22
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Started implementation of tracking quiz statistics
                                                </li>  
                                                
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            20.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Added simple statistics for flashcards on My Statistics page
                                                </li>  
                                                
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            19.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Flashcard results are now tracked in the database, globally and per user
                                                </li>  
                                                
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            18.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Designed My Profile page
                                                </li>  
                                                <li>
                                                    Started implementation to track statistics for flashcards
                                                </li> 
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            15.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Finished welcome flow for new users
                                                </li>
                                                <li>
                                                    Made cleaner dashboard design
                                                </li>
                                                <li>
                                                    Added functionality to add new languages on My Languages
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            14.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Created roadmap page
                                                </li>
                                                <li>
                                                    Started and almost finished onboarding guide
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            13.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Continued work on making cloud functions work
                                                </li>
                                                <li>
                                                    The <Link to="/settings/profile">profile settings</Link> are now much better and the username functionality works properly using cloud functions
                                                </li>
                                                <li>
                                                    Buttons on profile settings to randomly generate username and to check an username
                                                </li>
                                                <li>
                                                    Database entries for new users are now created with triggers in cloud functions. These records are deleted when an user is deleted in the auth system
                                                </li>
                                                <li>
                                                    Added global stats tracking for number of users and decks
                                                </li>
                                                <li>
                                                    Experimented with AppCheck but didn't make it work for cloud functions
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            08.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul className="list-disc">
                                                <li>
                                                    Made separate components for games when playing public decks and your own
                                                </li>
                                                <li>
                                                    Learned more about Cloud Functions and started setting it up (for username verification, global stats and more)
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            07.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Short bug fixing session
                                                </li>
                                                <li>
                                                    - Added possibility of changing section name and category name on the dedicated section and category pages
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            03.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Fixed bug related to deck name updates, although optimal solution with onSnapshot did not work properly
                                                </li>
                                                <li>
                                                    - Implemented basic version of Feedback system. Added feedback option on dashboard and on MyDecks page.
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            02.12.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Changed design of rows on MyDecks page, both when selected and deselected
                                                </li>
                                                <li>
                                                    - Added possibility of easily renaming sections, categories and decks on MyDecks page. Remaining bug: deck names don't show immediately!
                                                </li>
                                                <li>
                                                    - Started implementing Feedback feature
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            30.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Fixed many bugs at My Decks page
                                                </li>
                                                <li>
                                                    - Improved design and useful descriptive messages
                                                </li>
                                                <li>
                                                    - Query strings work properly now
                                                </li>
                                                <li>
                                                    - Changed design of scroll bar on MyDecks page
                                                </li>
                                                <li>
                                                    - Introduced concept of "Actions" in database in order to track specific actions taken by users
                                                </li>
                                                <li>
                                                    - Used Actions to create a Get started page for My Decks
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            29.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - More work on new My Decks page
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            26.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Added row of public decks on language pages
                                                </li>
                                                <li>
                                                    - Made home page menu sticky (fixed)
                                                </li>
                                                <li>
                                                    - Fixed bugs in category and section pages
                                                </li>
                                                <li>
                                                    - Started to work on improved My Decks page
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            25.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Added row of public decks on profile pages, along with link to see all decks 
                                                </li>
                                                
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            24.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Added hamburger menu and optimized mobile experience for homepage functionality
                                                </li>
                                                <li>
                                                    - Changed design of main menu on homepage
                                                </li>
                                                <li>
                                                    - Fixed bugs related to profile pages and sign-up flow
                                                </li>
                                                <li>
                                                    - Changed design of profile pages
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            23.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Added around 40 languages with flags
                                                </li>
                                                <li>
                                                    - Deck repository now lets you filter for "all languages" and "community decks"
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            22.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - You can now filter public decks by source language, target language, and whether it's an official Yalango deck or not
                                                </li>
                                                <li>
                                                    - Made the public deck pages more visually pleasing and added simple controls, including choosing between a card grid or table layout
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            21.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            Recent changes include:
                                            <ul>
                                                <li>
                                                    - Made a homepage and put other functionality inside dashboard
                                                </li>
                                                <li>
                                                    - Started working on public deck repository
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-8 py-4 font-bold">
                                            12.11.21
                                        </td>
                                        <td className="border px-8 py-4 text-left">
                                            <ul>
                                                <li>
                                                    - Added box displaying metadata on the page of the decks
                                                </li>
                                                <li>
                                                    - Added changelog page
                                                </li>
                                                <li>
                                                    - Improvements to bulk upload modal for decks. You can now add a new row by using the 'tab' key
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            
        </>
    )
}

export default Changelog;
