import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {useAuth} from '../../../contexts/AuthContext';
import { useLanguagesContext } from '../../../contexts/LanguagesContext';

const OpenMenu = ({minimizeTim, menuDesign, quickNotesClick, showHideTimOption, showNavigationArrows, closeFunction, translatorClick, feedbackClick, navigationArrowsClick}) => {

    let {currentUser} = useAuth();
    const {globalSelectedTargetLanguage} = useLanguagesContext();
    
    return (
        <>
            <div className={(menuDesign === "mobile-menu" ? 'bottom-16 -left-12 ' : '-left-16 -bottom-1 mb-28 ') + " absolute z-20  bg-white shadow-xl text-black font-bold rounded-lg flex flex-col gap-2 "}>
                {currentUser !== null &&
                    <motion.div onClick={translatorClick} whileHover={{scale:1.03}} className="cursor-pointer rounded-lg p-3 px-4 hover:bg-persian-green hover:text-white">
                        Pocket translator
                    </motion.div>
                } 
                {currentUser !== null && globalSelectedTargetLanguage !== null &&
                    <motion.div onClick={quickNotesClick} whileHover={{scale:1.03}} className="cursor-pointer rounded-lg p-3 px-4 hover:bg-persian-green hover:text-white">
                        Quick notes
                    </motion.div>
                } 
                <motion.div onClick={feedbackClick} whileHover={{scale:1.03}} className="cursor-pointer whitespace-nowrap rounded-lg p-3 px-4 hover:bg-persian-green hover:text-white">
                    Send feedback
                </motion.div> 
                {(showHideTimOption === true || showHideTimOption === undefined) &&
                    <motion.div onClick={minimizeTim} whileHover={{scale:1.03}} className="cursor-pointer text-xs text-center rounded-lg p-3 px-4 hover:bg-persian-green hover:text-white">
                        Hide Tim
                    </motion.div>
                }
            </div>
        </>
  )
}

export default OpenMenu;