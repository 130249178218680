import React, { useEffect, useState } from 'react';
import {DebounceInput} from 'react-debounce-input';
import useQuickNotes from '../../hooks/useQuickNotes';
import Skeleton from 'react-loading-skeleton';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const QuickNote = () => {
    const [note, setNote] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();

    const {fetchQuickNote, saveQuickNote} = useQuickNotes();

    useEffect(() => {
        const fetchNote = async () => {
            const note = await fetchQuickNote(globalSelectedTargetLanguage);
            console.log(note)
            if (note !== false && note.hasOwnProperty('text')){
                setNote(note.text);
            } else {
                setNote('');
            }
        }
        if (globalSelectedTargetLanguage !== null){
            fetchNote();
        }
    }, [globalSelectedTargetLanguage]);

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSaveNote = async (e) => {
        setSaving(true);
        const text = e.target.value;
        setNote(text)
        let success = await saveQuickNote(text, globalSelectedTargetLanguage);
        console.log('Note saved:', text);
        setSaving(false);
        if (!success){
            setError(true);
        }   
    };

    return (
        <div className="flex flex-col gap-4">
             <div className="font-semibold text-lg lg:text-xl ">
                Quick {(allLanguages !== null && globalSelectedTargetLanguage !== null )&& allLanguages[0][globalSelectedTargetLanguage]['name']['en']} notes
             </div>
             <div className="text-sm">
                Save words you want to add later, ideas, tasks or anything else you want to remember here. The note is saved automatically, and you have a unique note for each language you are learning.
             </div>
             {note === null ? 
                <>
                    <Skeleton count={1} height={200} />
                </> 
                :
                <>
                    <DebounceInput
                        className={"w-full h-44 p-4 border outline-none border-gray-300 rounded-md resize-none"}
                        minLength={1}
                        element="textarea"
                        value={note}
                        placeholder="Save quick notes, tasks, or words you want to add later here..."
                        debounceTimeout={700}
                        onChange={event => handleSaveNote(event)} 
                    />
                </>
            }
            <div className="text-left">
                {saving ? <div className="text-sm text-gray-400">Saving...</div> : <div className="text-sm text-gray-400">Saved</div>}
                {error && <div className="text-sm text-red-400">Error saving note.</div>}
            </div>
        </div>
    );
};

export default QuickNote;