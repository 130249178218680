import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import useCourses from '../../../hooks/useCourses';
import { Link, useParams } from 'react-router-dom';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';
import DeckGame from '../../vocabulary/games/DeckGame';
import TableGame from '../../tables/games/TableGame';


const CourseLesson = () => {
    let { courseId, lessonId } = useParams();

    const {fetchCourseLesson, fetchCourseFromId} = useCourses();

    const [lesson, setLesson] = useState(null);
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [contentGame, setContentGame] = useState(null);
    const [defaultDeckGame, setDefaultDeckGame] = useState("mix");
    const [defaultTableGame, setDefaultTableGame] = useState("fill-in");
    const [nextLessonId, setNextLessonId] = useState(null);
    const [currentPage, setCurrentPage] = useState("content");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            console.log("Fetching course and lesson from id: ", lessonId)
            const resCourse = await fetchCourseFromId({courseId});
            setCourse(resCourse);
            console.log("Course: ", resCourse);
            if (resCourse !== null && resCourse.hasOwnProperty("lesson_ids")){
                let l = resCourse.lesson_ids;
                if (l !== null){
                    // find next lesson id if it exists
                    let lessonIndex = l.indexOf(parseInt(lessonId));
                    if (lessonIndex !== -1 && lessonIndex < l.length-1){
                        setNextLessonId(l[lessonIndex+1]);
                    } else {
                        setNextLessonId(null); 
                    }
                }
            }
            const results = await fetchCourseLesson({lessonId});
            setLesson(results);
            if (results !== null && results.hasOwnProperty("content_game")){
                setContentGame(results.content_game);
            }
            setLoading(false);
        }

        if (lessonId === null || lessonId === undefined || fetchCourseLesson === undefined || courseId === null || courseId === undefined){return undefined}
        console.log("LessonID or courseID changed: ", lessonId, courseId);
        fetchData();
    }, [lessonId, courseId, fetchCourseLesson]);



    return (
        <>
            <div>
                {loading &&
                    <>
                        <div className="h-screen w-screen fixed flex flex-col place-items-center justify-center text-4xl">
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </div>
                    </>
                }
                {!loading &&
                    <>
                        {lesson === null &&
                            <div className="h-screen">
                                <GeneralErrorPage errorMessage={"Please verify that the lesson ID is correct and try again."} errorHeadline={"Lesson not found"} goBackLink={`/course/${courseId}`} goBackText={"Go back to course"} />
                            </div>
                        }
                        {lesson !== null &&
                            <>
                                {currentPage === "intro" &&
                                    <>
                                        Welcome!
                                    </>
                                }
                                {currentPage === "content" &&
                                    <div>
                                        {lesson.content_type === "deck" &&
                                            <>
                                                <div className="">
                                                    <DeckGame nextLessonId={nextLessonId} manualParamNumberOfItems={500} avoidParamsContentId={lesson.content_id} avoidParamsTargetLanguage={lesson["target_ISO_639-1"]} type={"course"} game={contentGame !== null ? contentGame : defaultDeckGame} />
                                                </div>
                                            </>
                                        }
                                        {lesson.content_type === "table" &&
                                            <>
                                                <div className="">
                                                    <TableGame type={"course"} nextLessonId={nextLessonId} backLink={`/course/${courseId}`} chosenHowManyItems={500} avoidParamsContentId={lesson.content_id} avoidParamsTargetLanguage={lesson["target_ISO_639-1"]} game={contentGame !== null ? contentGame : defaultTableGame} />
                                                </div>
                                            </>
                                        }
                                    </div>      
                                }
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default CourseLesson;