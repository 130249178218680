import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import HelmetData from '../../components/helmet/HelmetData';
import SRCard from '../../components/dashboard/SRCard';
import GreetingMessage from '../../components/dashboard/GreetingMessage';
import MyStarsCounter from '../../components/stars/MyStarsCounter';
import QuickNote from '../../components/dashboard/QuickNote';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import NoActiveTargetLanguage from '../../components/errors/NoActiveTargetLanguage';
import MistakesCard from '../../components/dashboard/MistakesCard';
import MiniGamesCard from '../../components/dashboard/MiniGamesCard';
import PremiumCard from '../../components/dashboard/PremiumCard';
import SpecificLanguageTools from '../../components/language-tools/SpecificLanguageTools';
import YalangoContentForLanguage from '../../components/dashboard/YalangoContentForLanguage';
import { useUserStatisticsContext } from '../../contexts/UserStatisticsContext';
import ReactTooltip from 'react-tooltip';

const CleanDashboard = () => {
  let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
  const {vocabularyMetadata} = useUserStatisticsContext();
  // <QuickNote />
  /*
            <div className="text-sm text-center">
              Please note that Yalango is being actively developed. If you encounter any issues or have any feature requests, <Link to={"/my-feedback"}>please let us know</Link>. Thank you for your support!
            </div>
  */
  useEffect(()=>{
    ReactTooltip.rebuild();
  },[globalSelectedTargetLanguage, vocabularyMetadata]);

  return (
    <>
        <ReactTooltip place="bottom" id="tooltip-dashboard" effect="solid" />
        <HelmetData title={"My Dashboard"} description={"View your personal dashboard on Yalango."} />
        {activeUserLanguages !== null && activeUserLanguages.length === 0 ?
          <>
              <NoActiveTargetLanguage />
          </>
          :
          <>
          <div className="flex flex-col gap-16">
            <div className="flex flex-row place-items-center  w-full justify-between">
                <div className="">
                    <PremiumCard />
                </div>
                <div className="flex justify-center flex-grow">
                    <GreetingMessage />
                </div>
                <div className="">
                    <MyStarsCounter />
                </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold text-center">Study your content</div>
            <div className="flex flex-col place-items-center lg:place-items-start lg:flex-row justify-center gap-3">
              <div className="w-64 h-64">
                {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                  <Link data-for="tooltip-dashboard" data-tip="Review the next items in your spaced repetition queue" className={"no-underline"} to={`/my-vocabulary/${globalSelectedTargetLanguage}/mix?level=spaced-repetition&items=20&mode=mix&back=dashboard`}>
                    <SRCard />
                  </Link>
                  :
                  <>
                    <div data-for="tooltip-dashboard" data-tip="Spaced repetition will be activated after you start a course or add your own decks to your library" className="grayscale h-full w-full">
                      <SRCard />
                    </div>
                  </>
                }
              </div>
              <div className="w-64 h-64">
              {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                  <Link data-for="tooltip-dashboard" data-tip="Review your most recent mistakes" className="no-underline" to={`/my-vocabulary/${globalSelectedTargetLanguage}/review-mistakes?back=dashboard`}>
                    <MistakesCard />
                  </Link>
                  :
                  <>
                    <div data-for="tooltip-dashboard" data-tip="You can review your mistakes after you have started reviewing on Yalango" className="grayscale h-full w-full">
                      <MistakesCard />
                    </div>
                  </>
                }
                
              </div>
              <div className="w-64 h-64">
              {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                  <Link data-for="tooltip-dashboard" data-tip="Review your content with minigames here"  className="no-underline" to={`/my-vocabulary/play?back=dashboard`}>
                    <MiniGamesCard />
                  </Link>
                  :
                  <>
                    <div data-for="tooltip-dashboard" data-tip="After you have started studying on Yalango, you can play minigames with your content here" className="grayscale h-full w-full">
                    <MiniGamesCard />
                    </div>
                  </>
                }
                
                
              </div>
            </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold text-center">Learn {allLanguages !== null && globalSelectedTargetLanguage !== null && allLanguages[0][globalSelectedTargetLanguage]["name"]["en"]} with Yalango</div>
              <div>
                <YalangoContentForLanguage language={globalSelectedTargetLanguage} />
              </div>
            </div>

            <div>
            <div>
              <SpecificLanguageTools language={globalSelectedTargetLanguage} />
            </div>
            
            </div>
            <div className="sm:">
                <h2>Navigation</h2>
                <div className="flex flex-col sm:flex-row place-items-center justify-center gap-4">
                  <Link to="/decks">
                    Explore
                  </Link>
                  <Link to="/settings">
                    Settings
                  </Link>
                  <Link to="/my-feedback">
                    Give feedback
                  </Link>
                  <Link to="/help">
                    Help center
                  </Link>
                </div>
            </div>
          </div>
          </>
        }
    </>
  )
}

export default CleanDashboard;