import React from 'react';
import '../../css/Button.css';
import { motion } from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const Button = ({innerRef, text="", width="", hideIconOnSmallScreen=false, hideTextOnSmallScreen=false, size="normal", iconPlacement="first", color="charcoal", extraClass="", type="", icon, disabledDesign=false, ...buttonProps}) => {
    
    let disabledDesignClass = "";

    if (disabledDesign){
        disabledDesignClass = "cursor-not-allowed text-gray-300";
    }

    const gradients = {
        'green': " persian-green-gradient ", 
        'charcoal': " charcoal-gradient ", 
        'red': " burnt-sienna-gradient "
    }

    const sizes = {
        'big': 'py-4 px-5 sm:px-8 text-base ', 
        'normal': 'py-3 px-5 sm:px-12 text-sm ', 
        'normal-ultrawide': 'py-3 px-24 text-sm ', 
        'small': 'py-2 px-3 sm:px-6 text-xs ',
    };

    
    
    return (
        <motion.button ref={innerRef} className={sizes[size] + " " + width + " text-white  font-bold tracking-wider uppercase flex flex-row gap-2 justify-center text-center  my-2 rounded-lg transition  ease-out duration-300 shadow-lg " + (gradients.hasOwnProperty(color) ? gradients[color] : color) + " " + type + " " + extraClass + " " + disabledDesignClass} {...buttonProps} whileHover = {!disabledDesign && {scale:1.01}} whileTap={{scale:0.9}}>
            {iconPlacement === "first" && icon !== undefined &&
                <div>
                    <FontAwesomeIcon icon={icon} />
                </div>
            }
            <div className={hideTextOnSmallScreen ? "hidden sm:block no-underline" : "no-underline"}>
                {text}
            </div>
            {iconPlacement === "last" && icon !== undefined &&
                <div>
                    <FontAwesomeIcon icon={icon} />
                </div>
            }
        </motion.button>
    )
}

export default Button;
