import './css/App.css';
import './css/Colors.css';

import {BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate, Outlet} from 'react-router-dom';
import Index from './pages/Index';
import AuthHandler from './pages/authentication/AuthHandler';
import Vocabulary from './pages/mydecks/Vocabulary';
import PostPage from './pages/comments/PostPage';
import AboutYalango from './pages/about/AboutYalango';
import MyContentSidebarMenu from './components/menus/sidebar-menu/menus/MyContentSidebarMenu';
import BetaMenuTest from './components/menus/sidebar-menu/menus/BetaMenuTest';
import UserProfile from './pages/UserProfile';
import DeckSection from './pages/DeckSection';
import DeckCategory from './pages/DeckCategory';
import TestPage from './pages/TestPage';
import EmailSettings from './pages/settings/EmailSettings';
import AccountSettings from './pages/settings/AccountSettings';
import ImportContentPage from './pages/import/ImportContentPage'
import Guides from './pages/guides/Guides';
import Analytics from './pages/analytics/Analytics';
import Dashboard from './pages/Dashboard';
import EmailVerificationPage from './pages/email/EmailVerificationPage';
import Explore from './pages/explore/Explore';
import NewDashboard from './pages/dashboard/NewDashboard';
import CleanDashboard from './pages/dashboard/CleanDashboard';
import ResourcesDirectory from './pages/resources/ResourcesDirectory';
import MainSettings from './pages/settings/MainSettings';
import ProfileSettings from './pages/settings/ProfileSettings';
import DeckSettings from './pages/settings/DeckSettings';
import IndividualDeckSettings from './pages/settings/IndividualDeckSettings';
import NewNavbar from './components/general/NewNavbar';
import HomepageNavbar from './components/general/HomepageNavbar';
import TermsOfService from './pages/policies/TermsOfService';
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import MyDecks from './pages/decks/mydecks/MyDecks';
import MyDeckCards from './pages/decks/mydecks/MyDeckCards';
import ExportMyDeck from './pages/decks/mydecks/ExportMyDeck';
import ExportCSV from './pages/decks/mydecks/ExportCSV';
import ExportPDF from './pages/decks/mydecks/ExportPDF';
import ImportDeckPage from './pages/decks/mydecks/ImportDeckPage';
import ImportCSVToDeck from './pages/decks/mydecks/ImportCSVToDeck';
import MyTables from './pages/tables/mytables/MyTables';
import MyNotes from './pages/notes/mynotes/MyNotes';
import EditMyNote from './pages/notes/mynotes/EditMyNote';
import EditMyText from './pages/texts/EditMyText';
import EditMyCourse from './pages/courses/course/EditMyCourse';
import Play from './pages/play/Play';
import MyTable from './pages/tables/mytables/MyTable';
import MyTablePlay from './pages/tables/mytables/MyTablePlay';
import TableGame from './pages/tables/games/TableGame';
import MyProfile from './pages/MyProfile';
import MyVocabulary from './pages/MyVocabulary';
import MyVocabularySpacedRepetition from './pages/myvocabulary/MyVocabularySpacedRepetition';
import MyVocabularyExport from './pages/MyVocabularyExport';
import PlusDecks from './pages/PlusDecks';
import WatchYouTube from './pages/videos/WatchYouTube';
import MyDailyGoals from './pages/goals/MyDailyGoals';
import CommentFeed from './pages/comments/CommentFeed';
import MyGoals from './pages/goals/MyGoals';
import MyFavorites from './pages/favorites/MyFavorites';
import Flashcards from './pages/vocabulary/games/Flashcards';
import Input from './pages/vocabulary/games/Input';
import Quiz from './pages/vocabulary/games/Quiz';
import Browse from './pages/vocabulary/games/Browse';
import Memory from './pages/vocabulary/games/Memory';
import Login from './pages/authentication/Login';
import ForgottenPassword from './pages/authentication/ForgottenPassword';
import LogOutComponent from './components/authentication/LogOutComponent';
import Register from './pages/authentication/Register';
import MyLanguages from './pages/mylanguages/MyLanguages';
import Changelog from './pages/changelog/Changelog';
import HanziToPinyinConverterPage from './pages/learn/mandarin-chinese/HanziToPinyinConverterPage';
import Translator from './pages/translation/Translator';
import DecksDirectory from './pages/decks/DecksDirectory';
import Premium from './pages/premium/Premium';
import PremiumSuccess from './pages/premium/PremiumSuccess';
import Tools from './pages/tools/Tools';
import FeaturesOverview from './pages/features/FeaturesOverview';
import PublicDeck from './pages/decks/PublicDeck';
import MyFeedback from './pages/feedback/MyFeedback';
import MyFriends from './pages/social/MyFriends';
import GeneralFeedbackCircle from './components/feedback/components/GeneralFeedbackCircle';
import Onboarding from './pages/onboarding/Onboarding';
import DailyStats from './pages/userStatistics/DailyStats';
import UserProfileDecks from './pages/profile/UserProfileDecks';
import MyStatistics from './pages/userStatistics/MyStatistics';
import MyFlaschardsStatistics from './pages/userStatistics/MyFlaschardsStatistics';
import DeckPlay from './pages/vocabulary/games/DeckPlay';
//import outfront from 'outfront' // import outfront JS
import StudyNote from './pages/notes/general/StudyNote';
import { AuthProvider } from "./contexts/AuthContext";
import { SocialProvider } from "./contexts/SocialContext";
import { useAuth } from "./contexts/AuthContext";
import { LanguagesProvider } from "./contexts/LanguagesContext";
import { MyContentProvider } from "./contexts/MyContentContext";
import { UserInfoProvider } from "./contexts/UserInfoContext";
import { UserStatisticsProvider } from "./contexts/UserStatisticsContext";
import TextsDirectory from  './pages/texts/TextsDirectory';
import StudyText from './pages/texts/StudyText';
import Forums from './pages/forums/Forums';

import LanguagePage from './pages/learn/LanguagePage';
import AllLanguages from './pages/languages/AllLanguages';
import Roadmap from './pages/Roadmap';
import DeckGame from './pages/vocabulary/games/DeckGame';

import Pricing from './pages/pricing/Pricing';
import NotFoundPage from './pages/404/NotFoundPage';
import WidgetMenu from './components/menus/widget-menu/WidgetMenu';
import ScrollToTop from "./components/scrollToTop";
import VocabularyItemPage from './pages/myvocabulary/VocabularyItemPage'
import MyLibrary from './pages/mylibrary/MyLibrary';
import MyLibraryFolder from './pages/mylibrary/MyLibraryFolder';

import DecksGuide from './pages/guides/decks/DecksGuide';
import HelpArticle from './pages/help/articles/HelpArticle';
import HelpCenter from './pages/help/HelpCenter';

import MyCalendar from './pages/calendar/MyCalendar';
import FooterMenu from './components/menus/FooterMenu';
import CourseHome from './pages/courses/course/CourseHome';
import CourseLesson from './pages/courses/course/CourseLesson';
import ExportPDFQuiz from './pages/decks/mydecks/ExportPDFQuiz';

function OldPrivateRoute ({component: Component, ...rest}) {

  let authed = undefined;
  let { currentUser } = useAuth();
  if (currentUser === null){
    authed = false;
  }
  else {
    authed = true;
  }

  return (
    <>
      <Routes>
        <Route
          {...rest}
          render={(props) => authed === true
            ? <Component {...props} />
            : <Navigate to={{pathname: '/login', state: {from: props.location}}} />}
        />
      </Routes>
    </>
  )
}

function PrivateRouteWrapper() {
  let authed = undefined;
  const location = useLocation();
  let { currentUser } = useAuth();
  if (currentUser === null){
    authed = false;
  }
  else {
    authed = true;
  }
  return authed ? <Outlet /> : <Navigate to="/login" state={{from: location}} />;
}

function EmailVerificationWrapper() {
  let redirect = false;
  const location = useLocation();
  let { currentUser } = useAuth();
  if (currentUser !== null){
    console.log("Email verification: ", currentUser, location.pathname);
    if (location.pathname !== "/email-verification" && location.pathname !== "/settings" && location.pathname !== "/onboarding"  && currentUser.emailVerified === false){
      redirect = true;
    } else {
      redirect = false;
    }
    console.log("Verification redirect: ", redirect);
    return redirect ? <Navigate to="/email-verification" state={{from: location}} /> : <Outlet />;
  }
  else {
    return <Outlet />
  }
}

const DashboardLayout = () => {
  return (
    <>
      <NewNavbar />
      <WidgetMenu />
      <div className="dashboard">
        <Outlet />
      </div>
      <div className=" ">
        </div>
    </>
  )
}

const AuthDecidesLayout = () => {
  let { currentUser } = useAuth();
  let authed = false;
  if (currentUser === null){
    authed = false;
  }
  else {
    authed = true;
  }

  return (
    <>
      {authed ?
        <>
         <DashboardLayout />
        </>
      :
        <>
          <HomepageLayout />
        </> 
      }
    </>
  )
}

const HomepageLayout = () => {
  return (
    <>
      <div className="relative homepage flex flex-col ">
        <HomepageNavbar />
        <div className=" flex-grow px-8 py-8 pt-12 lg:pt-0 lg:mt-7">
          <Outlet />
        </div>
        <FooterMenu />
        <div className="dotted-background ">
        </div>
      </div>
    </>
  )
}

function App() {
  //outfront(); // Call it on top of project.
  //<WidgetMenu />
  //<PagesWithDashboardMenu />
  //<Route element={<EmailVerificationWrapper />}>
  //                <Route path="/my-notes/:id/study" element={<StudyNote />} />
  return (
    <>
      <Router>
        <ScrollToTop />
        <AuthProvider>
        <LanguagesProvider>
           <UserInfoProvider>
            <UserStatisticsProvider>
            
            <MyContentProvider>
              <SocialProvider>
          <Routes>

            
            
            <Route path="/my-vocabulary/:lang/flashcards" element={<DeckGame game="flashcards" isPublic={false} type="vocabulary" />} />
            <Route path="/my-vocabulary/:lang/quiz" element={<DeckGame game="quiz" isPublic={false} type="vocabulary" />} />
            <Route path="/my-vocabulary/:lang/input" element={<DeckGame game="input" isPublic={false} type="vocabulary" />} />
            <Route path="/my-vocabulary/:lang/mix" element={<DeckGame game="mix" isPublic={false} type="vocabulary" />} />
            <Route path="/my-vocabulary/:lang/memory" element={<DeckGame game="memory" isPublic={false} type="vocabulary" />} />
            <Route path="/my-vocabulary/:lang/browse" element={<DeckGame game="browse" isPublic={false} type="vocabulary" />} />

            <Route path="/my-decks/lang/:lang/flashcards" element={<DeckGame game="flashcards" isPublic={false} type="all-decks" />} />
            <Route path="/my-decks/lang/:lang/quiz" element={<DeckGame game="quiz" isPublic={false} type="all-decks" />} />
            <Route path="/my-decks/lang/:lang/input" element={<DeckGame game="input" isPublic={false} type="all-decks" />} />
            <Route path="/my-decks/lang/:lang/mix" element={<DeckGame game="mix" isPublic={false} type="all-decks" />} />
            <Route path="/my-decks/lang/:lang/memory" element={<DeckGame game="memory" isPublic={false} type="all-decks" />} />
            <Route path="/my-decks/lang/:lang/browse" element={<DeckGame game="browse" isPublic={false} type="all-decks" />} />

            <Route path="/my-decks/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="deck" />} />
            <Route path="/my-decks/:id/browse" element={<DeckGame game="browse" type="deck" isPublic={false}  />} />
            <Route path="/my-decks/:id/memory" element={<DeckGame game="memory" type="deck" isPublic={false} />} />
            <Route path="/my-decks/:id/input" element={<DeckGame game="input" type="deck" isPublic={false} />} />
            <Route path="/my-decks/:id/mix" element={<DeckGame game="mix" type="deck" isPublic={false} />} />
            <Route path="/my-decks/:id/write-hanzi" element={<DeckGame game="write-hanzi" type="deck" isPublic={false} />} />
            <Route path="/my-decks/:id/animate-hanzi" element={<DeckGame game="animate-hanzi" type="deck" isPublic={false} />} />
            <Route path="/my-decks/:id/stroke-order-hanzi" element={<DeckGame game="stroke-order-hanzi" type="deck" isPublic={false} />} />

            <Route path="/my-vocabulary/:lang/review-mistakes" element={<DeckGame game="flashcards" isPublic={false} type="review-mistakes" />} />
           
            <Route path="/my-vocabulary/review" element={<DeckGame game="quiz" type="vocabulary-review" />} />
            <Route path="/my-decks/category/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="category" />} />
            <Route path="/my-decks/section/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="section" />} />
            
            <Route path="/my-folders/:id/decks/flashcards" element={<DeckGame game="flashcards" type="folder-decks" isPublic={true} />} />
            <Route path="/my-folders/:id/decks/quiz" element={<DeckGame game="quiz" type="folder-decks" isPublic={true} />} />
            <Route path="/my-folders/:id/decks/input" element={<DeckGame game="input" type="folder-decks" isPublic={true} />} />
            <Route path="/my-folders/:id/decks/mix" element={<DeckGame game="mix" type="folder-decks" isPublic={true} />} />
            <Route path="/my-folders/:id/decks/memory" element={<DeckGame game="memory" type="folder-decks" isPublic={true} />} />
            <Route path="/my-folders/:id/decks/browse" element={<DeckGame game="browse" type="folder-decks" isPublic={true} />} />

            <Route path="/my-folders/:id/all/flashcards" element={<DeckGame game="flashcards" type="folder-all" isPublic={true} />} />
            <Route path="/my-folders/:id/all/quiz" element={<DeckGame game="quiz" type="folder-all" isPublic={true} />} />
            <Route path="/my-folders/:id/all/input" element={<DeckGame game="input" type="folder-all" isPublic={true} />} />
            <Route path="/my-folders/:id/all/mix" element={<DeckGame game="mix" type="folder-all" isPublic={true} />} />
            <Route path="/my-folders/:id/all/memory" element={<DeckGame game="memory" type="folder-all" isPublic={true} />} />
            <Route path="/my-folders/:id/all/browse" element={<DeckGame game="browse" type="folder-all" isPublic={true} />} />
            
            <Route path="/my-tables/:id/fill-in" element={<TableGame game="fill-in" />} />   
            <Route path="/my-tables/:id/flashcards" element={<TableGame game="flashcards" />} />   

            <Route path="/my-decks/category/:id/quiz" element={<DeckGame game="quiz" type="category" isPublic={false}  />} />
            <Route path="/my-decks/section/:id/quiz" element={<DeckGame game="quiz" type="section" isPublic={false}  />} />
            <Route path="/my-decks/:id/quiz" element={<DeckGame game="quiz" type="deck" isPublic={false}  />} />
            <Route path="/my-decks/:id/context" element={<DeckGame game="context" type="deck" isPublic={false}  />} />
            <Route path="/my-decks/category/:id/browse" element={<DeckGame game="browse" type="category" isPublic={false}  />} />
            <Route path="/my-decks/section/:id/browse" element={<DeckGame game="browse" type="section" isPublic={false}  />} />
            

            <Route path="/my-decks/category/:id/memory" element={<DeckGame game="memory" type="category" isPublic={false}  />} />
            <Route path="/my-decks/section/:id/memory" element={<DeckGame game="memory" type="section" isPublic={false}  />} />
            
            
            <Route path="/decks/:id/flashcards" element={<DeckGame game="flashcards" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/quiz" element={<DeckGame game="quiz" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/memory" element={<DeckGame game="memory" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/browse" element={<DeckGame game="browse" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/input" element={<DeckGame game="input" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/mix" element={<DeckGame game="mix" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/write-hanzi" element={<DeckGame game="write-hanzi" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/animate-hanzi" element={<DeckGame game="animate-hanzi" type="deck" isPublic={true} />} />
            <Route path="/decks/:id/stroke-order-hanzi" element={<DeckGame game="stroke-order-hanzi" type="deck" isPublic={true} />} />

            <Route path="/course/:courseId/lesson/:lessonId" element={<CourseLesson />} /> 

            <Route path="/my-decks/category/:id/input" element={<DeckGame game="input" type="category" isPublic={false}  />} />
            <Route path="/my-decks/category/:id/mix" element={<DeckGame game="mix" type="category" isPublic={false}  />} />
            <Route path="/my-decks/section/:id/input" element={<DeckGame game="input" type="section" isPublic={false}  />} />
            <Route path="/my-decks/section/:id/mix" element={<DeckGame game="mix" type="section" isPublic={false}  />} />
            <Route path="/texts/:targetLang/:text_id/:slug" element={<StudyText location={"public"} />} />
            <Route path="/my-texts/:text_id/study" element={<StudyText location={"library"} />} />

            <Route element={<DashboardLayout />}>        

              <Route path="/auth/action" element={<AuthHandler />} />
              
              <Route path="/test" element={<TestPage />} />
           
              
              <Route path="/my-texts/:id" element={<EditMyText />} />
              <Route path="/my-courses/:courseId" element={<EditMyCourse />} />
              
              
              <Route path="/my-vocabulary/:targetLang/item/:id" element={<VocabularyItemPage />} />
              
              <Route path="/analytics" element={<Analytics />} />

              
              <Route element={<PrivateRouteWrapper />}>
                
                  <Route path="/premium" element={<Premium />} />

                  <Route path="/import" element={<ImportContentPage />} />
                <Route path="/import/decks/csv" element={<ImportCSVToDeck design="page" mode="create-deck" type={"csv"} />} />
                <Route path="/import/decks/quizlet" element={<ImportCSVToDeck design="page" mode="create-deck"  type={"quizlet"} />} />
                <Route path="/import/decks/table" element={<ImportCSVToDeck design="page" mode="create-deck" type={"table"} />} />
                <Route path="/import/decks/input-text" element={<ImportCSVToDeck design="page" mode="create-deck"  type={"inputText"} />} />

                <Route path="/my-calendar" element={<MyCalendar />} />
                <Route path="/email-verification" element={<EmailVerificationPage />} />
                <Route path ="/watch" element={<WatchYouTube />} />
                <Route path="/premium/success" element={<PremiumSuccess />} />
                <Route path="/my-vocabulary/play" element={<DeckPlay type="vocabulary" isPublic={false} />} />
                <Route path="/my-decks/:id/play" element={<DeckPlay type="deck" isPublic={false} />} />
                <Route path="/my-decks/cards" element={<MyDeckCards />} />
                <Route path="/my-decks/play" element={<DeckPlay type="all-decks" isPublic={false} />} />
                <Route path="/my-decks" element={<MyDecks />} />
                <Route path="/my-decks/:id" element={<Vocabulary />} />
                <Route path="/decks/section/:id" element={<DeckSection />} />
                <Route path="/decks/category/:id" element={<DeckCategory />} />

                <Route path="/my-folders/:id/decks/play" element={<DeckPlay type="folder" folderMode="decks" isPublic={true} />} />
                <Route path="/my-folders/:id/all/play" element={<DeckPlay type="folder" folderMode="all" isPublic={true} />} />
                <Route path="/my-decks/:id/export" element={<ExportMyDeck />} />
                <Route path="/my-decks/:id/export/csv" element={<ExportCSV />} />
                <Route path="/my-decks/:id/export/pdf" element={<ExportPDF />} />
                <Route path="/my-decks/:id/export/quiz" element={<ExportPDFQuiz />} />
                
                <Route path="/my-decks/:id/import" element={<ImportDeckPage />} />
                <Route path="/my-decks/:id/settings" element={<IndividualDeckSettings />} />
                <Route path="/my-decks/:id/import/csv" element={<ImportCSVToDeck design="page" mode="edit-deck" type={"csv"} />} />
                <Route path="/my-decks/:id/import/quizlet" element={<ImportCSVToDeck design="page" mode="edit-deck"  type={"quizlet"} />} />
                <Route path="/my-decks/:id/import/table" element={<ImportCSVToDeck design="page" mode="edit-deck" type={"table"} />} />
                <Route path="/my-decks/:id/import/input-text" element={<ImportCSVToDeck design="page" mode="edit-deck"  type={"inputText"} />} />
                
                <Route path="/tools/translator" element={<Translator />} />
                <Route path="/my-library" element={<MyLibrary libraryPage={"library"}  />} />
                <Route path="/my-library/folder/:folderDocId" element={<MyLibrary libraryPage={"folder"} />} />
                <Route path ="/my-favorites" element={<MyFavorites />} />
                
                <Route path="/my-tables" element={<MyTables />} />
                <Route path="/my-goals/daily" element={<MyDailyGoals />} />
                <Route path ="/my-goals" element={<MyGoals />} />
                <Route path="/my-tables/:id" element={<MyTable />} />   
                <Route path="/my-tables/:id/play" element={<MyTablePlay />} />      
                <Route path="/my-notes" element={<MyNotes />} />
                <Route path="/my-notes/:id" element={<EditMyNote />} /> 
                <Route path="/stats/daily" element={<DailyStats />} />

                

                <Route path="/my-decks/section/:id" element={<DeckSection />} />
                <Route path="/my-decks/category/:id" element={<DeckCategory />} />
                <Route path="/my-decks/section/:id/play" element={<DeckPlay type="section" isPublic={false} />} />
                <Route path="/my-decks/category/:id/play" element={<DeckPlay type="category" isPublic={false} />} />
                <Route path="/my-feedback" element={<MyFeedback />} />
                <Route path="/plus" element={<PlusDecks />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/my-statistics" element={<MyStatistics />} />
                <Route path="/my-statistics/flashcards" element={<MyFlaschardsStatistics />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/my-vocabulary" element={<MyVocabulary />} />
                <Route path="/my-vocabulary/spaced-repetition" element={<MyVocabularySpacedRepetition />} />
                <Route path="/my-vocabulary/export" element={<MyVocabularyExport />} />
                
                <Route path="/dashboard" element={<CleanDashboard />} />
                <Route path="/play" element={<Play />} />
                <Route path="/old-dashboard" element={<Dashboard />} />
                <Route path="/friends" element={<MyFriends />} />
                <Route path="/settings" element={<MainSettings />} />
                <Route path="/settings/profile" element={<ProfileSettings />} />
                <Route path="/settings/email" element={<EmailSettings />} />
                <Route path="/settings/decks" element={<DeckSettings />} />
                <Route path="/settings/account" element={<AccountSettings />} />
                <Route path="/my-languages" element={<MyLanguages />} />  
              </Route>
           
            </Route>
            <Route element={<AuthDecidesLayout />}>
              <Route path="/languages" element={<AllLanguages />} />
              <Route path="/learn/:language" element={<LanguagePage />} />
              <Route path="/course/:courseId" element={<CourseHome />} /> 

              <Route path="/learn/Mandarin-Chinese/hanzi-to-pinyin" element={<HanziToPinyinConverterPage />} />
              <Route path="/decks/:id/play" element={<DeckPlay type="deck" isPublic={true} />} />
              <Route path={"/post/:commentDocId"} element={<PostPage />} />
              <Route path="/decks/section/:id/play" element={<DeckPlay type="section" isPublic={true} />} />
              <Route path="/decks/category/:id/play" element={<DeckPlay type="category" isPublic={true} />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/decks" element={<DecksDirectory />} />
              <Route path="/decks/:id" element={<PublicDeck />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/forgotten-password" element={<ForgottenPassword />} />
              <Route path="/logout" element={<LogOutComponent />} />
              <Route path="/register" element={<Register />} />
              <Route path="/resources" element={<ResourcesDirectory />} />
              <Route path="/u/:username" element={<UserProfile />} />
              <Route path="/u/:username/decks" element={<UserProfileDecks />} />
              <Route path="/forums" element={<Forums />} />
              <Route path="/texts" element={<TextsDirectory />} />
              <Route path="/feed" element={<CommentFeed />} />
              <Route path="/forums/:language" element={<CommentFeed />} />
            </Route>

            <Route element={<HomepageLayout />}>
              <Route path="/help/:slug" element={<HelpArticle />} />
              <Route path="/help" element={<HelpCenter />} />
              
              <Route path="/about" element={<AboutYalango />}  />
              <Route path="/guides" element={<Guides />} />
              <Route path="/guides/decks" element={<DecksGuide />} />
              <Route path="/pricing" element={<Pricing />} />
 
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/features" element={<FeaturesOverview />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/changelog" element={<Changelog />} />
              <Route path="/" element={<Index />} />   
            </Route>
            
          </Routes>
          
          </SocialProvider>
          </MyContentProvider>
          </UserStatisticsProvider>
          </UserInfoProvider>
              </LanguagesProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
