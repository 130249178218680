import React, {useEffect, useState} from 'react';
import FrontPageHero from '../components/frontpage/FrontPageHero';
import {Helmet} from 'react-helmet';
import FooterMenu from '../components/menus/FooterMenu';

const Index = () => {

    return (
        <>
            <Helmet>
                <title>Yalango - Organize your Language Studies</title>
                <meta name="description" content={"Made for polyglots by polyglots. Make decks, tables, notes, collect sentences, and more in your target languages."} />
            </Helmet>
            
            <div className="overflow-y-auto pb-32 sm:pb-0 ">
                <div className="">
                    <FrontPageHero /> 
                </div>
            </div> 
        </>
    )
}

export default Index;
