import React from "react";
import { Link } from "react-router-dom";


const LanguageTools_zh = () => {

    return (
        <div>
            <h2>Useful Chinese tools</h2>
            <div className="flex flex-col gap-3 place-items-center">
                <Link to="/learn/Mandarin-Chinese/hanzi-to-pinyin">Hanzi to pinyin converter</Link>
            </div>
        </div>
    );
};

export default LanguageTools_zh;