import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import { useLocation } from "react-router-dom";

export default function useLogs() {
    let {currentUser} = useAuth();
    const location = useLocation();

    const saveErrorLog = async ({errorObject, functionName}) => {
        let path = projectFirestore.collection("logs").doc("errors").collection("items");
        let obj = {
            'created_timestamp': timeStamp,
            'last_updated_timestamp': timeStamp,
        };
        if (currentUser !== null){
            obj['uid'] = currentUser.uid;
        } else {
            obj['uid'] = null;
        }
        if (errorObject !== undefined){
            if (errorObject.hasOwnProperty("name") && errorObject.name !== undefined){
                obj['error_name'] = errorObject.name;
            }
            if (errorObject.hasOwnProperty("message") && errorObject.message !== undefined){
                obj['error_message'] = errorObject.message;
            }
            if (errorObject.hasOwnProperty("stack") && errorObject.stack !== undefined){
                obj['error_stack'] = errorObject.stack;
            }
        }
        if (functionName !== undefined){
            obj['function_name'] = functionName;
        }
        obj['error_location'] = "client";
        obj['location_path_name'] = location.pathname;
        obj['location_search'] = location.search;
        await path.add(obj).catch(err=>console.log(err));
    }

    return {
        saveErrorLog
    }
}