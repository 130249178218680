import React, {useEffect, useState} from 'react';
import useDecks from '../../../../hooks/useDecks';
import {Link} from 'react-router-dom';
import {faVolumeUp} from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from "../../../../contexts/AuthContext";
import Icon from '../../../../components/general/Icon';
import CountryFlag from '../../../../components/languages/CountryFlag';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import {useLanguagesContext} from '../../../../contexts/LanguagesContext';
import LoadingGame from '../common-assets/LoadingGame';
import BrowseHelpModal from './modals/BrowseHelpModal';

const BrowseGame = ({reviewFinished, setReviewFinished, isPremium, score, setScore, audioLoading, isInMix, autoPlayPronunciations, setAudioLoading, playMode, getStandardForvoPronunciation, googleTextToSpeechClick, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, id, type}) => {
    let {allLanguages} = useLanguagesContext();
    const { currentUser } = useAuth();
    const [nbOfItems, setNbOfItems] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    useEffect(()=>{
        if (cards !== null && !loadingItems){
            setNbOfItems(cards.length);
        }
    },[cards, loadingItems]);

    useEffect(()=>{
        if (autoPlayPronunciations === true && currentIndex !== null && cards !== null && !audioLoading && !isInMix && playMode === "target_first"){
            googleTextToSpeechClick(cards[currentIndex]);
        }
    },[autoPlayPronunciations, currentIndex, cards, isInMix, playMode]);

    const nextItemClick = () => {
        if (currentIndex < nbOfItems - 1){
            setCurrentIndex(currentIndex+1);
        } else {
            setCurrentIndex(0);
        }
        setAudioLoading(false);
    }

    const previousItemClick = () => {
        if (currentIndex === 0){
            setCurrentIndex(nbOfItems-1);
        } else {
            setCurrentIndex(currentIndex-1);
        }
        setAudioLoading(false);
    }

    // KEY CODES
    document.body.onkeyup = function(e){

        // RIGHT ARROW - CORRECT
        if (e.key === "ArrowRight"){
            nextItemClick(); 
        }

        // RIGHT ARROW - CORRECT
        if (e.key === "ArrowLeft"){
            previousItemClick(); 
        }
    }
    //<Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
//textToSpeechWithForvo
    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

    const [forvoData, setForvoData] = useState(null);
    
    const [standardForvoPronunciationMp3Path, setStandardForvoPronunciationMp3Path] = useState(null);
    const [standardForvoPronunciationOggPath, setStandardForvoPronunciationOggPath] = useState(null);

    const [standardForvoAudioFiles, setStandardForvoAudioFiles] = useState({});

    const playActiveStandardForvoPronunciation = () => {
        let audio = new Audio(standardForvoPronunciationMp3Path);
        audio.play();
    }

    const forvoTextToSpeechClick = async (card) => {
        console.log("Card: ", card);
        console.log(standardForvoAudioFiles);
        if (standardForvoAudioFiles.hasOwnProperty(card['doc_id'])){
            let af = standardForvoAudioFiles[card['doc_id']];
            if (af.hasOwnProperty('pathmp3')){
                setAudioLoading(true);
                try {
                    let audio = new Audio(af['pathogg']);
                    let playPromise = audio.play();
                    audio.onended = () => {
                        setAudioLoading(false);
                    };
                    if (playPromise !== undefined) {
                        playPromise.then(function() {
                          // Automatic playback started!
                        }).catch(function(error) {
                            console.log(error);
                            setAudioLoading(false);
                        });
                      }
                } catch {
                    setAudioLoading(false); 
                }
            }
        }
        else {
            setAudioLoading(true);
            let r =  await getStandardForvoPronunciation(card['target'], card['target_ISO_639-1']);
            console.log("response: ", r);
            let mp3Path = null;
            let oggPath = null;
            if (r !== false && r !== null){
                // we have audio
                if (r.hasOwnProperty('items') && r['items'].length > 0){
                    setForvoData(r);
                    let firstAudioFile = r['items'][0];
                    if (firstAudioFile.hasOwnProperty('pathmp3') && firstAudioFile.hasOwnProperty('pathogg')){
                        mp3Path = firstAudioFile['pathmp3'];
                        oggPath = firstAudioFile['pathogg'];
                        setStandardForvoPronunciationMp3Path(mp3Path);
                        setStandardForvoPronunciationOggPath(oggPath);
                        let copyOfStandardForvoAudioFiles = JSON.parse(JSON.stringify(standardForvoAudioFiles));
                        copyOfStandardForvoAudioFiles[card['doc_id']] = {
                            'pathmp3': mp3Path,
                            'pathogg': oggPath
                        };
                        setStandardForvoAudioFiles(copyOfStandardForvoAudioFiles);
                    }
                }
                else {
                    setAudioLoading(false);
                }
            } 
            if (mp3Path !== null){
                try {
                    let audio = new Audio(oggPath);
                    let playPromise = audio.play();
                    audio.onended = () => {
                        setAudioLoading(false);
                    };
                    if (playPromise !== undefined) {
                        playPromise.then(function() {
                          // Automatic playback started!
                        }).catch(function(error) {
                            console.log(error);
                            setAudioLoading(false);
                        });
                      }
                } catch {
                    setAudioLoading(false); 
                }
            }   
        }
    }



    return (
        <>
            {(loadingItems === false && showLoadingScreen === false) &&
                    <>
                        <div className="sm:p-0 flex flex-col items-center justify-center h-full gap-4 w-full">
                        {(nbOfItems !== null && nbOfItems > 0) ?
                        <>
   
                        <div className="flex flex-col justify-center place-items-center h-full w-full">
                            <div className="relative w-full sm:w-5/6 m-auto text-center ">
                                <div className="absolute right-0 p-4 text-gray-700 font-bold">
                                    {currentIndex+1}/{nbOfItems}
                                </div>
                                <div className="flex flex-grow flex-row  text-gray-700 text-3xl p-12 place-items-center justify-start">
                                    <div className="w-16">
                                    {('source_ISO_639-1' in cards[currentIndex] && 'target_ISO_639-1' in cards[currentIndex] && cards[currentIndex]['source_ISO_639-1'] !== "" && cards[currentIndex]['target_ISO_639-1'] !== "") &&
                                                        <>
                                        <CountryFlag size="2em" countryCode={allLanguages[0][cards[currentIndex]['target_ISO_639-1']]['country_flag']}  /> 
                                        </>
                                    }
                                    </div>
                                    <div className="w-full flex flex-row gap-6 place-items-center justify-center ">
                                        <div>
                                            
                                        </div>
                                        <div className="text-4xl sm:text-7xl">
                                            {playMode === "source_first" ?
                                                <>
                                                    {cards[currentIndex].source}
                                                </>
                                            : 
                                                <>
                                                    <div className="flex flex-row place-items-center gap-6">
                                                        {(isPremium && currentUser !== null) && 
                                                            <>
                                                                {!audioLoading ?  
                                                                    <>
                                                                        {(cards[currentIndex].hasOwnProperty("google_tts") && cards[currentIndex]['google_tts'] !== null) &&
                                                                        <div onClick={()=>googleTextToSpeechClick(cards[currentIndex])} className="text-3xl">
                                                                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                        </div>
                                                                        }
                                                                    </>
                                                                :
                                                                    <div className="text-3xl">
                                                                        <Icon color="charcoal" className="fa-beat" icon={<FontAwesomeIcon icon={faVolumeUp} />} />
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                        <div>
                                                            {cards[currentIndex].target}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center">
                                    <div className="w-5/6 m-auto border-t border-persian-green border-dashed">
                                    </div>
                                    <div className="w-1/6 underline text-base text-center text-gray-700 font-bold cursor-pointer" onClick={nextItemClick}>
                                        {currentIndex+1 === nbOfItems ? <>Start over</> : <>Next</>}
                                    </div>
                                </div>
                                <div className="flex flex-grow flex-row  text-black text-3xl p-12 place-items-center justify-start">
                                    <div className="w-16">
                                        {('source_ISO_639-1' in cards[currentIndex] && 'target_ISO_639-1' in cards[currentIndex] && cards[currentIndex]['source_ISO_639-1'] !== "" && cards[currentIndex]['target_ISO_639-1'] !== "") &&
                                                        <>  
                                        <CountryFlag size="2em" countryCode={allLanguages[0][cards[currentIndex]['source_ISO_639-1']]['country_flag']}  /> 
                                            </>
                                        }
                                    </div>
                                    <div className="w-full flex flex-row gap-6 place-items-center justify-center ">
                                        
                                        <div className="text-xl sm:text-3xl">
                                            {playMode === "source_first" ?
                                                <>
                                                    {cards[currentIndex].target}
                                                </>
                                            : 
                                                <>
                                                    {cards[currentIndex].source}
                                                </>
                                            }
                                        </div>

                                    </div>
                                    
                                </div>
                                {('custom_fields' in cards[currentIndex] && Object.keys(cards[currentIndex]['custom_fields']).length > 0 && 'custom_fields_metadata' in cards[currentIndex] && cards[currentIndex]['custom_fields_metadata'].length > 0) && 
                                    <>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 text-center gap-4 text-lg">
                                        {cards[currentIndex]['custom_fields_metadata'].map((field, index)=>(
                                            <>
                                                {(field.data.id in cards[currentIndex].custom_fields && cards[currentIndex].custom_fields[field.data.id] !== "") &&
                                                    <>  
                                                        <div key={"c_field"+index} className="flex flex-row justify-center gap-2">
                                                            <div  className="font-bold text-gray-600">
                                                                {field.data.name}:
                                                            </div>
                                                            <div className="text-black overflow-x-auto">
                                                                {cards[currentIndex].custom_fields[field.data.id]}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        ))}
                                        </div>
                                    </>
                                }
                                
                            </div>    
                        </div>
                        </>
                        :
                        <>
                        {(nbOfItems !== null && nbOfItems === 0 && !loadingItems) &&
                            <>
                                <div className="flex flex-col justify-center place-items-center h-screen">
                                    <div className="relative flex text-xl flex-col h-64 justify-center text-black p-4 w-5/6 m-auto text-center shadow-2xl rounded-xl  bg-white">
                                        <div>
                                            There are no items in this {type}. Please come back to this page after you've added at least one item. 
                                        </div>
                                        <div className="p-6">
                                            <Link to={backToPlayPageLink}>Go back</Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        </>
                        
                        }
                        </div> 
                    </>
                }
        </>
    )
};

export default BrowseGame;
