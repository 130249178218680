import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import useUserSettings from './useUserSettings';
import useQueryStrings from './useQueryStrings';
import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons';
import { useCallback } from 'react';

export default function useDecks() {

    if (process.env.NODE_ENV === 'development') {
        projectFunctions.useEmulator("localhost", 5001); // local testing
    } 

    const {currentUser} = useAuth();
    const {fetchCurrentUserInfo} = useUserSettings();
    const {getQueryStrings} = useQueryStrings();

    const newCardStructureBool = true;


    const uploadMultipleCardsToDeckApi = async (deckId, listOfCards, firstColOption, secondColOption, deck, ytId) => {
        let f = projectFunctions.httpsCallable('uploadMultipleCardsToDeckApi');
        let args = {'deckId': deckId, 'listOfCards': listOfCards, 'firstColOption':firstColOption, 'secondColOption':secondColOption, 'deck':deck};
        if (ytId !== undefined && ytId !== null){
            args['ytId'] = ytId;
        }
        let r = await f(args);
        return r['data'];
    }

    const fetchAllItemsInSingleDeck = async (deck_id, public_deck) => {
        let {items, deck} = await fetchAllItemsInDeck(deck_id, public_deck);
        return {items: items, deck: deck};
    }

    const getDeckAndXRandomCardsFromDeckApi = async (deckId, howManyItems, game) => {
        let f = projectFunctions.httpsCallable('getDeckAndXRandomCardsFromDeck');
        let r = await f({'deck_id': deckId, 'howManyItems': howManyItems, 'game': game});
        return r['data'];
    }

    const getDeckCardsForCourseLessonApi = async ({deckId, howManyItems, game}) => {
        let f = projectFunctions.httpsCallable('getDeckAndXRandomCardsFromDeck');
        let r = await f({'deck_id': deckId, 'howManyItems': howManyItems, 'game': game, 'type': 'course'});
        return r['data'];
    }

    const getDecksAndXRandomCardsFromCategoryApi = async (categoryId, howManyItems) => {
        let f = projectFunctions.httpsCallable('getDecksAndXRandomCardsFromCategory');
        let r = await f({'category_id': categoryId, 'howManyItems': howManyItems});
        return r['data'];
    }

    const getDecksAndXRandomCardsFromSectionApi = async (sectionId, howManyItems) => {
        let f = projectFunctions.httpsCallable('getDecksAndXRandomCardsFromSection');
        let r = await f({'section_id': sectionId, 'howManyItems': howManyItems});
        return r['data'];
    }

    const getXRandomCardsFromVocabularyApi = async (howManyItems, level, lang) => {
        console.log("level");
        let f = projectFunctions.httpsCallable('getXRandomCardsFromVocabulary');
        let r = await f({'level': level, 'howManyItems': howManyItems, 'lang': lang});
        return r['data'];
    }

    const getXCardsFromVocabularyForSpacedRepetitionApi = async (howManyItems, level, lang) => {
        let f = projectFunctions.httpsCallable('getXCardsFromVocabularyForSpacedRepetition');
        let r = await f({'level': level, 'howManyItems': howManyItems, 'lang': lang});
        return r['data'];
    }

    const getXMistakesToReviewFromLastYDaysApi = async (howManyItems, days, lang) => {
        let f = projectFunctions.httpsCallable('getXMistakesToReviewFromLastYDays');
        let r = await f({'days': days, 'howManyItems': howManyItems, 'lang': lang});
        return r['data'];
    }

    const getXRandomCardsFromFolderApi = async (howManyItems, folderDocId) => {
        let f = projectFunctions.httpsCallable('getDecksAndXCardsFromFolder');
        let r = await f({'howManyItems': howManyItems, 'folderDocId': folderDocId});
        return r['data'];
    }

    const getXRandomItemsFromFolderApi = async (howManyItems, folderDocId) => {
        let f = projectFunctions.httpsCallable('getXItemsFromFolder');
        let r = await f({'howManyItems': howManyItems, 'folderDocId': folderDocId});
        return r['data'];
    }

    const getXRandomCardsFromAllUserDecksApi = async (howManyItems, lang) => {
        let f = projectFunctions.httpsCallable('getXRandomCardsFromCurrentUserDecks');
        let r = await f({'howManyItems': howManyItems, 'lang': lang});
        return r['data'];
    }

    const getObjectAndXRandomCardsFromTypeApi = async (id, howManyItems, type, level, lang, game) => {
        if (type === "deck"){
            let r = await getDeckAndXRandomCardsFromDeckApi(id, howManyItems, game); 
            return r;
        } 
        else if (type === "course"){
            let r = await getDeckCardsForCourseLessonApi({deckId: id, howManyItems: howManyItems, game: game});
            return r;
        }
/*         else if (type === "category"){
            let r = await getDecksAndXRandomCardsFromCategoryApi(id, howManyItems);
            return r;
        } 
        else if (type === "section") {
            let r = await getDecksAndXRandomCardsFromSectionApi(id, howManyItems);
            return r;
        }  */
        else if (type === "vocabulary"){
            let r = null;
            if (level === "easy" || level === "hard" || level === "normal"){
                r = await getXRandomCardsFromVocabularyApi(howManyItems, level, lang, game);
            } else {
                r = await getXCardsFromVocabularyForSpacedRepetitionApi(howManyItems, level, lang, game);
            }
            return r;
        } 
        else if (type === "folder-decks"){
            let r = await getXRandomCardsFromFolderApi(howManyItems, id, game);
            return r;
        }
        else if (type === "folder-all"){
            let r = await getXRandomItemsFromFolderApi(howManyItems, id, game);
            return r;
        }
        else if (type === "all-decks"){
            let r = await getXRandomCardsFromAllUserDecksApi(howManyItems, lang, game);
            return r;
        }
        else if (type === "review-mistakes"){
            let days = 7;
            let r = await getXMistakesToReviewFromLastYDaysApi(howManyItems, days, lang);
            return r;
        }
        //
    }

    const refreshObjectAndXRandomCardsFromTypeApi = async (id, howManyItems, type, setLoadingItems, setDeck, setDecks, setCards) => {
        setLoadingItems(true);
        let r = await getObjectAndXRandomCardsFromTypeApi(id, howManyItems, type);
        'deck' in r && setDeck(r['deck']);
        'decks' in r && setDecks(r['decks']);
        setCards(r['cards']);
    }

    const queryStringsCheckLevel = (setLevel, r) => {
        //helper function
        let level = null;
        let defaultValue = "normal";
        let possible = ['easy', 'normal', 'hard', "spaced-repetition"];
        if ('level' in r && possible.includes(r['level'])){
            try {
                level = r['level'].toString();
                setLevel(r['level'].toString());
            } catch {
                level = defaultValue;
                setLevel(defaultValue);
            }
        }
        else {
            level = defaultValue;
            setLevel(defaultValue);
        }
        return level;
    }

    const queryStringsCheckNumberOfItems = (setHowManyItems, r) => {
        //helper function
        let nbOfItems = null;
        let defaultValue = 25;
        if ('items' in r){
            try {
                nbOfItems = parseInt(r['items']);
                setHowManyItems(parseInt(r['items']));
            } catch {
                nbOfItems = defaultValue;
                setHowManyItems(defaultValue);
            }
        }
        else {
            nbOfItems = defaultValue;
            setHowManyItems(defaultValue);
        }
        return nbOfItems;
    }

    const chooseRandomPlayModes = (nbOfItems) => {
        let options = ["target_first", "source_first"];
        let chosenList = [];
        let counter = 0;
        while (counter < nbOfItems){
            let random = Math.round(Math.random());
            chosenList.push(options[random]);
            counter++;
        }
        return chosenList;
    }

    const queryStringsCheckMode = async (setPlayMode, r, setPlayModeArray, nbOfItems) => {
        let mode = null;
        let defaultValue = "target_first";
        if ('mode' in r){
            if (r['mode'] === "target"){
                setPlayMode("target_first");
                setPlayModeArray(Array(nbOfItems).fill("target_first").flat());
                mode = "target_first";
            } else if (r['mode'] === "source"){
                setPlayMode("source_first");
                setPlayModeArray(Array(nbOfItems).fill("source_first").flat());
                mode = "source_first";
            } else if (r['mode'] === "mix"){
                //choose randomly
                let chosenList = chooseRandomPlayModes(nbOfItems);
                setPlayModeArray(chosenList);
                setPlayMode("mix");
                mode = "mix";
            }
            else {
                setPlayMode(defaultValue);
                mode = defaultValue;
            }
        }
        else {
            mode = defaultValue;
            setPlayMode(defaultValue);
            setPlayModeArray(Array(nbOfItems).fill(defaultValue).flat());
        }
        console.log("Mode: ", mode);
        return mode;
    }

    const backToPlayPageLinkFunction = (type, setBackToPlayPageLink, howManyItems, id, isPublic, lang, pMode,r, courseId, nextLessonId) => {
        if (howManyItems === null){return null}
        let deckBaseUrl = null;
        let modeUrl = "target";
        if (isPublic === true && type === "deck"){
            deckBaseUrl = "/decks/";
        }
        else {
            deckBaseUrl = "/my-decks/";
        }
        if (pMode === "source_first"){
            modeUrl = "source";
        } else if (pMode === "target_first"){
            modeUrl = "target";
        } else if (pMode === "mix"){
            modeUrl = "mix";
        } 
        else {
            modeUrl = "target";
        }
        if (type === "deck"){
            setBackToPlayPageLink(deckBaseUrl+id+'/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "category"){
            setBackToPlayPageLink('/my-decks/category/'+id+'/play?items='+howManyItems);
        }
        else if (type === "section"){
            setBackToPlayPageLink('/my-decks/section/'+id+'/play?items='+howManyItems);
        } 
        else if (type === "vocabulary"){
            let url = '/my-vocabulary/play?items='+howManyItems+"&mode="+modeUrl;
            if (r !== undefined && r !== null && r['back'] !== undefined){
                if (r['back'] === "play"){
                    url = "/play";
                }
                if (r['back'] === "dashboard"){
                    url = "/dashboard";
                }
            }   
            setBackToPlayPageLink(url);
        }
        else if (type === "folder-decks"){
            setBackToPlayPageLink('/my-folders/'+id+'/decks/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "folder-all"){
            setBackToPlayPageLink('/my-folders/'+id+'/all/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "all-decks"){
            setBackToPlayPageLink('/my-decks/play?items='+howManyItems+"&mode="+modeUrl);
        }
        else if (type === "review-mistakes"){
            let url = '/dashboard';
            if (r !== undefined && r !== null && r['back'] !== undefined){
                if (r['back'] === "play"){
                    url = "/play";
                }
            }   
            setBackToPlayPageLink(url);
        }
        else if (type === "course"){
            if (courseId !== null){
                setBackToPlayPageLink('/course/'+courseId);
            }
            else {
                setBackToPlayPageLink('/dashboard');
            }
        }
    }

    const fetchGameDataApiHelper = async (game, setApiFetchStarted,id, nbOfItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang) => {
        setApiFetchStarted(true);
        let r = await getObjectAndXRandomCardsFromTypeApi(id, nbOfItems, type, level, lang, game);
        'deck' in r && setDeck(r['deck']);
        'decks' in r && setDecks(r['decks']);
        if (type === "vocabulary"){
            if (game === "mix"){
                setCards(r['cards']);
            }
            else {
                let tmpDeckCardsList = [];
                if (r['cards'] !== null){
                    r['cards'].forEach((tmp_card, index)=>{
                        if (tmp_card.hasOwnProperty("type") && tmp_card['type'] === "deck"){
                            tmpDeckCardsList.push(tmp_card);
                        }
                        else if (tmp_card.hasOwnProperty("deck_name") && tmp_card.hasOwnProperty('deck_id')){
                            //deck card in game outside of vocabulary games
                            tmpDeckCardsList.push(tmp_card);
                        }
                    })
                }
                setCards(tmpDeckCardsList);
            }
        } else {
            setCards(r['cards']);
        }
        setLoadingItems(false);
    }

    const loadAllGameData = useCallback(async (courseId, nextLessonId, setHowManyItems, game, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode, setPlayModeArray) => {
        let level = null;
        let r = await getQueryStrings();
        let nbOfItems = queryStringsCheckNumberOfItems(setHowManyItems, r);
        let pMode = "target_first";
        if (setPlayMode !== undefined){
            pMode = await queryStringsCheckMode(setPlayMode, r, setPlayModeArray, nbOfItems);
        }
        if (type === "vocabulary"){
            level = queryStringsCheckLevel(setLevel, r);
        }
        backToPlayPageLinkFunction(type, setBackToPlayPageLink, nbOfItems, id, isPublic, lang, pMode, r, courseId, nextLessonId);
        setParamsLoading(false);
        await fetchGameDataApiHelper(game, setApiFetchStarted,id, nbOfItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang);  
    },[]);

    const refreshAllGameData = async (courseId, nextLessonId, setCards, game, setShowLoadingScreen, id, howManyItems, type, setLoadingItems, setApiFetchStarted, setDeck, setDecks, lang, level, playMode, setPlayMode, setPlayModeArray) => {
        setShowLoadingScreen(true);
        setLoadingItems(true);
        if (playMode === "mix"){
            //update random array
            let chosenList = chooseRandomPlayModes(howManyItems);
            setPlayModeArray(chosenList);
        }
        await fetchGameDataApiHelper(game, setApiFetchStarted,id, howManyItems, type, setDeck, setDecks, setCards, setLoadingItems, level, lang);  
        setShowLoadingScreen(false);
    }

    const fetchAllItemsInDeck = async (deck_id, public_deck) => {
        //console.log(deck_id, public_deck);
        if (public_deck === undefined){
            public_deck = false;
        }
        let docRef; 
        let deck = null;
        if (currentUser !== null && !public_deck){
            docRef = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
        }
        else if (public_deck) {
            docRef = projectFirestore.collection('decks').where('privacy', '==', "public").where('id', '==', deck_id);
        }
        const items = await docRef.get().then((querySnapshot) => {
            return querySnapshot;
        }).then((querySnapshot)=>{
            if (!querySnapshot.empty){
                let o = {};
                o['doc_id'] = querySnapshot.docs[0].ref.id;
                deck = {...o, ...querySnapshot.docs[0].data()}
                if (newCardStructureBool){
                    return projectFirestore.collection('deck-cards').where("uid", "==", currentUser.uid).where('deck_id', '==', deck_id).get();
                } else {
                    return querySnapshot.docs[0].ref.collection('items').get();
                }
            } else {
                return null;
            }
            
        }).then(querySnapshot => { 
            let documents = [];
            if (querySnapshot !== null && !querySnapshot.empty){
                for (const doc of querySnapshot['docs']){
                    documents.push({...doc.data(), id:doc.id, deck_id: deck_id, 'target_ISO_639-1': deck['target_ISO_639-1'], 'source_ISO_639-1': deck['source_ISO_639-1']});
                };
            }
            return documents;
        });
        return {items, deck}; 
    }

    const fetchAllItemsInCategory = async (category_id) => {
        
        const deck_ids = await findDeckIdsInCategory(category_id);
        let items=[], decks;
        if (deck_ids.length > 0){
            ({items, decks} = await fetchAllItemsInMultipleDecks(deck_ids));
        } else {
            let items=[];
            let decks;
        }
        
        return {items: items, decks: decks}; 
    }

    const findDeckIdsInCategory = async (category_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        const ids = await docRef.get().then(snapshots => {
            //console.log(snapshots);
            if (snapshots.size === 1){
                if (snapshots['docs'][0].data()['decks'] !== undefined){
                    //console.log(snapshots['docs'][0].data()['decks']['id']);
                    return snapshots['docs'][0].data()['decks']['id'];
                }
                else {
                    return [];
                };
            };
        });
        //console.log("ids: ", ids);
        return ids;
    };

    const getAllItemsFromCategoryDatabase = (deck_ids) => {
        const items_list = [];
        for (let deck_id of deck_ids) {
            //console.log("STARTING LOOP FOR: ", deck_id);
            const docRef = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
            findItemsInCategoryDeck(docRef, deck_id);
        };
    };

    const findItemsInCategoryDeck = (docRef, deck_id) => {
        docRef.get().then((querySnapshot) => {
            return querySnapshot.docs[0].ref.collection('items').get();
        }).then(querySnapshot => { 
            const temp_items_list = [];
            querySnapshot.forEach((doc) => {
                temp_items_list.push({...doc.data(), id:doc.id, deck_id: deck_id});
            });
        }); 
    };

    const findCategoryIds = async (section_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('section_id', '==', section_id);

        const category_ids = await docRef.get().then(snapshots => {
            let temp_cat = [];
            let counter = 1;
            snapshots.forEach(snapshot => {
                let cat_id = snapshot.data()['category_id'];
                counter++;
                temp_cat.push(cat_id);
            });
            return temp_cat;
        });

        return category_ids; 
        
    }

    const fetchAllItemsInSection = async (section_id) => {
        const category_ids = await findCategoryIds(section_id);
        let deck_ids = await fetchAllDeckIdsInMultipleCategories(category_ids);
        let {items, decks} = await fetchAllItemsInMultipleDecks(deck_ids);

        return {items, decks};
    };

    const fetchAllItemsInMultipleDecks = async (deck_ids) => {
        let all_items = [];
        let all_decks = [];
        for await (const deck_id of deck_ids){
            let {items, deck} = await fetchAllItemsInDeck(deck_id);
            all_items = [...all_items, ...items];
            if (deck !== null){
                all_decks = [...all_decks, deck];
            }    
        }
        return {items: all_items, decks: all_decks};
    }

    const fetchAllDeckIdsInMultipleCategories = async (category_ids) => {
        let all_ids = [];
        for await (const c_id of category_ids){
            //console.log("Starting category loop for: ", c_id);
            let d_ids = await findDeckIdsInCategory(c_id);
            //console.log("Found these ones: ", d_ids);
            all_ids = [...all_ids, ...d_ids];
        };  
        return all_ids;
    }

    const addItemToDeck = async ({deck_id, sourceInput, targetInput, customFieldsInput, outputFormat}) => {
        const colRef = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
        if (sourceInput === undefined || targetInput === undefined || sourceInput.trim() === "" || targetInput.trim() === ""){
            return null;
        }
        else {
            let doc_id;
            let newCard = await colRef.get().then(async querySnapshot => {
                if (!querySnapshot.empty) {
                    let o = {}; 
                    if (customFieldsInput !== null && customFieldsInput !== undefined){
                        for (const [key, value] of Object.entries(customFieldsInput)){
                            o[parseInt(key)] = value.trim();
                        }
                    }
                    doc_id = querySnapshot.docs[0].ref.id;
                    let deckData = querySnapshot.docs[0].data();
                    let query = null;
                    let obj = {
                        'source': sourceInput.trim(),
                        'target': targetInput.trim(), 
                        'created_timestamp': timeStamp,
                        'last_updated_timestamp': timeStamp, 
                        'custom_fields': o
                    };
                    if (newCardStructureBool){
                        query = projectFirestore.collection("deck-cards");
                        // more variables on new format
                        obj['deck_doc_id'] = doc_id;
                        obj['deck_id'] = deck_id;
                        obj['uid'] = currentUser.uid;
                        obj['source_ISO_639-1'] = deckData['source_ISO_639-1'];
                        obj['target_ISO_639-1'] = deckData['target_ISO_639-1'];
                        obj['privacy'] = deckData['privacy'];
                        obj['author'] = deckData['author'];
                    }
                    else {
                        query = querySnapshot.docs[0].ref.collection('items');
                    }
                    let cardSnapshot = await query.add(obj).then((snap)=>{
                        return snap;
                    }).catch(error => {
                        return null;
                    });
                    let returnObject = {};
                    if (outputFormat === "typesense"){
                        returnObject = {
                            'source': sourceInput.trim(),
                            'target': targetInput.trim(), 
                            'created_timestamp': timeStamp,
                            'last_updated_timestamp': timeStamp, 
                            'deck_id': deck_id, 
                            'id': cardSnapshot !== null ? cardSnapshot.id : null
                        };
                        Object.entries(o).forEach((entry)=>{
                            returnObject['custom_fields.'+entry[0]] = entry[1];
                        });
                    } else {
                        returnObject = {
                            'source': sourceInput.trim(),
                            'target': targetInput.trim(), 
                            'created_timestamp': timeStamp,
                            'last_updated_timestamp': timeStamp, 
                            'custom_fields': o, 
                            'deck_id': deck_id, 
                            'id': cardSnapshot !== null ? cardSnapshot.id : null
                        };
                    }
                    return returnObject;

                } else {
                    return null;
                };
            });
            return newCard;
/*             const func = projectFunctions.httpsCallable('saveCustomFieldForCard');
            for (const [key, value] of Object.entries(customFieldsInput)){
                if (value !== ""){
                    await func({deckDocId: doc_id, fieldId: key, fieldValue:value, cardId: newCard.id});
                }
            } */
            
        };
    }

    const editItemInDeck = async (deck_id, editCardId, sourceInput, targetInput, customFieldsInput) => {
        console.log("Edit: ", deck_id, editCardId, sourceInput, targetInput, customFieldsInput);
        const colRef = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
        if (sourceInput.trim() === "" || targetInput.trim() === ""){
            return null;
        }
        else {
            let r = await colRef.get().then(async (querySnapshot) => {
                if (!querySnapshot.empty) {
                    let o = {};
                    for (const [key, value] of Object.entries(customFieldsInput)){
                        o[parseInt(key)] = value.trim();
                    }
                    let query = null;
                    if (newCardStructureBool){
                        query = projectFirestore.collection("deck-cards").doc(editCardId);
                    } else {
                        query = querySnapshot.docs[0].ref.collection('items').doc(editCardId) 
                    }
                    let r2 = await query.update({
                        'source': sourceInput.trim(),
                        'target': targetInput.trim(),
                        'last_updated_timestamp': timeStamp, 
                        'custom_fields': o
                    }).catch(error => {
                        console.log(error);
                        return error;
                    });
                    return true;
                } else {
                    return null;
                };
            });
            console.log(r);
            return r;
        };
    }

    const fetchAllPublicDecks = async () => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        //console.log(decks);
        return decks;
    }

    const fetchAllPublicDecksData = async () => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const renameDeck = async (docId, newName) => {
        let obj = {
            'last_updated_timestamp': timeStamp,
            'name': newName
        };
        let query = projectFirestore.collection("decks").doc(docId);
        let r = await query.set(obj, {merge:true}).catch(err=>{console.log(err)});
        return r;
    }

    const addMissingDeckFields = (data) => {
        data.forEach((value, index) => {
            if (!('title' in value)){
                data[index]['title'] = 'wow';
            }
            if (!('description' in value)){
                data[index]['description'] = '';
            }
            if (!('author' in value)){
                data[index]['author'] = {
                    'username': '',
                    'displayname': '' 
                };
            }
        }); 
        return data;
    }

    const fetchAllPublicDecksDataFromOneUser = async (uid, limit) => {
        let decksRef;
        if (limit !== undefined){
            decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('uid', '==', uid).limit(limit);
        } else {
            decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('uid', '==', uid);
        }
        
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);

        //console.log("Daty: ", data);
        return data;
    }

    const fetchAllPublicCommunityDecksData = async () => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchAllPublicYalangoDecksData = async () => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchAllPublicCommunityDecksDataFromOneSourceLanguage = async (source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('source_ISO_639-1', '==', source).where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        return data;
    }

    const fetchAllPublicYalangoDecksDataFromOneSourceLanguage = async (source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('source_ISO_639-1', '==', source).where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        return data;
    }

    const fetchAllPublicCommunityDecksDataFromOneTargetLanguage = async (target) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        return data;
    }

    const fetchAllPublicYalangoDecksDataFromOneTargetLanguage = async (target) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        return data;
    }

    const fetchAllPublicDecksDataFromOneSourceLanguage = async (source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('source_ISO_639-1', '==', source);
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchAllPublicDecksDataFromOneTargetLanguage = async (target, limit) => {
        let decksRef;
        if (limit !== undefined){
            decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).limit(limit);
        } else {
            decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target);
        }
        
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchCommunityDecksDataFromOneLanguage = async (target, source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).where('source_ISO_639-1', '==', source).where('uid', '!=', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchPublicDecksDataFromOneLanguage = async (target, source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).where('source_ISO_639-1', '==', source);
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        //console.log("Daty: ", data);
        return data;
    }

    const fetchYalangoDecksDataFromOneLanguage = async (target, source) => {
        const decksRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('target_ISO_639-1', '==', target).where('source_ISO_639-1', '==', source).where('uid', '==', 'd1AsNHNfQUWYYoplP3UNdKcATss1');
        let decks = await decksRef.get().then(querySnapshot => {
            return querySnapshot.docs;
        });
        let data = [];
        decks.forEach((deck, index)=>{
            data.push(deck.data());
        })
        data = addMissingDeckFields(data);
        return data;
    }

    const fetchDeckCustomFieldsFromCurrentUserRealtime = async (docId, setCustomFields) => {
        console.log("fetchDeckCustomFieldsFromCurrentUserRealtime() for doc: ", docId);
        const docRef = projectFirestore.collection('decks').doc(docId).collection("custom-fields");
        let unsub = await docRef.onSnapshot((snap)=>{
            console.log("Snapshot");
            if (snap.docs.length > 0){
                let l = [];
                snap.docs.forEach((doc)=>{
                    let o = {};
                    o['docId'] = doc.ref.id;
                    o['data'] = doc.data();
                    l.push(o);
                });
                console.log("Found custom fields: ", l);
                setCustomFields(l);
            } else {
                console.log("Found no custom fields: ", []);
                setCustomFields([]);
            }
        });
        return unsub; 
    }

    const fetchDeckCustomFieldsFromCurrentUser = async (docId) => {
        const docRef = projectFirestore.collection('decks').doc(docId).collection("custom-fields");
        return docRef.get().then((snap)=>{
            if (snap.docs.length > 0){
                let l = [];
                snap.docs.forEach((doc)=>{
                    let o = {};
                    o['docId'] = doc.ref.id;
                    o['data'] = doc.data();
                    l.push(o);
                });
                return l;
            } else {
                return [];
            }
        })
    }

    const saveNewCustomFieldApi = async (deck, name, type) => {
        if (type !== "text" && type !== "list"){return null;}
        
        const func = projectFunctions.httpsCallable('saveNewDeckCustomField');
        let results = await func({
            'deck': deck,
            'name': name,
            'type': type
        }); 
        return results.data;
    }

    const deleteCustomFieldApi = async ({deckDocId, fieldDocId}) => {
        console.log(deckDocId, fieldDocId);
        const func = projectFunctions.httpsCallable('deleteCustomField');
        let results = await func({
            'fieldDocId': fieldDocId,
            'deckDocId': deckDocId
        });
        return results; 
    }

    const editExistingCustomFieldApi = async (deck, oldField, newField) => {
        if (newField.data.type !== "text" && newField.data.type !== "list"){return null;}
        if (newField.data.name === ""){return null;}

        const func = projectFunctions.httpsCallable('editDeckCustomField');
        let results = await func({
            'deck': deck,
            'oldField': oldField,
            'newField': newField
        }); 
        return results.data;
    }
    

    const fetchAllItemsInUserDeckRealtime = async (doc_id, deck_id, setDocuments, pageSize, setFirstVisibleItem, setLastVisibleItem, docStartPoint, type) => {
        let query = null;
        const docRef = projectFirestore.collection('decks').doc(doc_id);
        if (docStartPoint !== undefined){
            if (type === "next"){
                query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").startAfter(docStartPoint).limit(pageSize);
            } else {
                query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").endBefore(docStartPoint).limitToLast(pageSize);
            }
        } else {
            query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").limit(pageSize);
        }
        query.onSnapshot(querySnapshot => { 
            let documents = [];
            let firstVisible = null;
            let lastVisible = null;
            if (querySnapshot.docs.length > 0 ){
                firstVisible = querySnapshot.docs[0];
                lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                for (const doc of querySnapshot['docs']){
                    documents.push({...doc.data(), id:doc.id, deck_id:deck_id});
                };
            }
            setDocuments(documents);
            setFirstVisibleItem(firstVisible);
            setLastVisibleItem(lastVisible);
        });

    }

    const fetchPaginatedItemsInUserDeck = async (doc_id, deck_id, pageSize, docStartPoint, type, sortByValue, searchTerm) => {
        let query = projectFirestore.collection('decks').doc(doc_id).collection('items');
        if (searchTerm !== "" && searchTerm !== undefined && searchTerm !== null){
            query = query.where("target", "==", searchTerm);
        }
        if (sortByValue !== undefined && sortByValue !== null){
            if (sortByValue === 'target_alphabetically' && searchTerm === ""){
                query = query.orderBy("target");
            } else if (sortByValue === 'source_alphabetically'){
                query = query.orderBy("source");
            } else if (sortByValue === 'last_updated_timestamp_asc'){
                query = query.orderBy("last_updated_timestamp");
            } else if (sortByValue === 'last_updated_timestamp_desc'){
                query = query.orderBy("last_updated_timestamp", "desc");
            } else if (sortByValue === 'created_timestamp_asc'){
                query = query.orderBy("created_timestamp");
            } else if (sortByValue === 'created_timestamp_desc'){
                query = query.orderBy("created_timestamp", "desc");
            } else {
                query = query.orderBy("last_updated_timestamp", "desc");
            }
        }

        if (docStartPoint !== undefined && docStartPoint !== null) {
            if (type === "next"){
                query = query.startAfter(docStartPoint).limit(pageSize);
            } else {
                query = query.endBefore(docStartPoint).limitToLast(pageSize);
            }
        } else {
            query = query.limit(pageSize);
        }

        let r = await query.get().then(async querySnapshot => { 
            let documents = [];
            let firstVisible = null;
            let lastVisible = null;
            if (querySnapshot.docs.length > 0 ){
                firstVisible = querySnapshot.docs[0];
                lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                for (const doc of querySnapshot['docs']){
                    documents.push({...doc.data(), id:doc.id, deck_id:deck_id});
                };
            }
            if (searchTerm !== "" && searchTerm !== undefined && searchTerm !== null){
                let query2 = projectFirestore.collection('decks').doc(doc_id).collection('items').where("source", "==", searchTerm);
                let r2 = await query2.get();
                if (!r2.empty && r2.docs.length > 0){
                    r2.docs.forEach((doc2)=>{
                        documents.push({...doc2.data(), id:doc2.id, deck_id:deck_id});
                    })
                }
            }
            return {'documents': documents, 'firstVisible': firstVisible, 'lastVisible': lastVisible};
        });
        return r;
    }

    const refreshCardInDeck = async (deckDocId, cardDocId) => {
        let query = null;
        if (newCardStructureBool){
            query = projectFirestore.collection("deck-cards").doc(cardDocId);
        }
        else {
            query = projectFirestore.collection("decks").doc(deckDocId).collection("items").doc(cardDocId);
        }
        let snapshot = await query.get();
        if (!snapshot.exists){return null}
        return snapshot.data();
    }

    const fetchAllItemsInPublicDeck = async (doc_id, docStartPoint, type) => {
        let pageSize = 24;
        console.log(doc_id, docStartPoint, type);
        let query = null;
        const docRef = projectFirestore.collection('decks').doc(doc_id);
        if (docStartPoint !== undefined){
            if (type === "next"){
                //query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").startAfter(docStartPoint).limit(pageSize);
                query = projectFirestore.collection("deck-cards").where("privacy", "==", "public").where("deck_doc_id", "==", doc_id).orderBy("last_updated_timestamp", "desc").startAfter(docStartPoint).limit(pageSize);
            } else {
                //query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").endBefore(docStartPoint).limitToLast(pageSize);
                query = projectFirestore.collection("deck-cards").where("privacy", "==", "public").where("deck_doc_id", "==", doc_id).orderBy("last_updated_timestamp", "desc").endBefore(docStartPoint).limitToLast(pageSize);
            }
        } else {
            //query = docRef.collection('items').orderBy("last_updated_timestamp", "desc").limit(pageSize);
            query = projectFirestore.collection("deck-cards").where("privacy", "==", "public").where("deck_doc_id", "==", doc_id).orderBy("last_updated_timestamp", "desc").limit(pageSize);
        }
        let [documents, firstVisible, lastVisible] = await query.get().then(querySnapshot => { 
            let documents = [];
            let firstVisible = null;
            let lastVisible = null;
            if (querySnapshot.docs.length > 0 ){
                firstVisible = querySnapshot.docs[0];
                lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                for (const doc of querySnapshot['docs']){
                    documents.push({...doc.data(), id:doc.id});
                };
            }
            return [documents, firstVisible, lastVisible];

        });

        return [documents, firstVisible, lastVisible];

    }

    const fetchAllItemsAndMetadataInPublicDeck = async (deck_id, startAfterDoc) => {
        //console.log("DECK ID:", deck_id);
        let metadata;
        let lastVisible;
        let firstVisible;
        let docRef;

        docRef = projectFirestore.collection('decks').where('privacy', '==', 'public').where('id', '==', deck_id);

        const documents = await docRef.get().then((querySnapshot) => {
            if (querySnapshot.empty){return null;}
            metadata = querySnapshot.docs[0].data();
            metadata['doc_id'] = querySnapshot.docs[0].ref.id;
            
            let query; 
            if (startAfterDoc !== undefined){
                query = projectFirestore.collection('deck-cards').where('privacy', "==", "public").where("deck_id", "==", deck_id).orderBy("last_updated_timestamp", "desc").startAfter(startAfterDoc).limit(24);
            } else {
                query = projectFirestore.collection('deck-cards').where('privacy', "==", "public").where("deck_id", "==", deck_id).orderBy("last_updated_timestamp", "desc").limit(24);
            }
            return query.get();
        }).then(querySnapshot => { 
            if (querySnapshot === null){return null}
            let documents = [];
            firstVisible = querySnapshot.docs[0];
            lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
            for (const doc of querySnapshot['docs']){
                documents.push({...doc.data(), id:doc.id, deck_id: deck_id});
            };
            return documents;
        });

        return [documents, metadata, firstVisible, lastVisible];

    }

    const fetchDeckSectionsFromCurrentUserInTargetLanguage = async (target, setStateFunc) => {
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections').where('target_ISO_639-1', '==', target);
        let unsub = await query.onSnapshot(querySnapshot => {
            //console.log("live: ", querySnapshot.docs);
            let data = [];
            querySnapshot.docs.forEach((section, index)=>{
                //console.log("section:", section.data());
                data.push(section.data());
            })
            setStateFunc(data);
        });
        return unsub;
    }

    const fetchDeckCategoriesFromCurrentUserInSectionInTargetLanguage = async (target, section_id, setStateFunc) => {
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories').where('target_ISO_639-1', '==', target).where('section_id', '==', section_id);
        query.onSnapshot(querySnapshot => {
            //console.log("live: ", querySnapshot.docs);
            let data = [];
            querySnapshot.docs.forEach((section, index)=>{
                //console.log("category:", section.data());
                data.push(section.data());
            })
            setStateFunc(data);
        });
    }

    const swapFavoriteStatusForDeck = (deck_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('decks.id', 'array-contains', deck_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['decks']['favorite'][deck_id];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        [`decks.favorite.${deck_id}`]: !old_value, 
                    });
                });
            }
        }); 
    }

    const swapFavoriteStatusForCategory = (category_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['favorite'];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'favorite': !old_value, 
                    });      
                });
            }
        }); 
    }

    const swapFavoriteStatusForSection = (section_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
        const docRef = collectionRef.where('section_id', '==', section_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let old_value = snapshot.data()['favorite'];
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'favorite': !old_value, 
                    });      
                });
            }
        }); 
    }

    const fetchDeckIdsFromCurrentUserInCategory = async (category_id, setStateFunc) => {
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories').where('category_id', '==', category_id);
        query.onSnapshot(querySnapshot => {
            if (querySnapshot.docs.length === 1) {
                //console.log('Doing the work: ', querySnapshot.docs[0].data());
                //console.log(querySnapshot.docs[0].data());
                if ('decks' in querySnapshot.docs[0].data()) {
                    setStateFunc(querySnapshot.docs[0].data()['decks']['id']);
                }
            }
        })
    }

    const fetchDecksFromDeckIdsForCurrentUser = async (deck_ids, decks, setStateFunc) => {
        //not working properly
        let data = [];
        let l_index = 1;
        for await (const deck_id of deck_ids){
            //console.log("Deck id: ", deck_id);
            let d_query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
            d_query.onSnapshot(querySnapshot=>{
                //console.log("Deck data: ", querySnapshot.docs[0].data());
                if (querySnapshot.docs.length === 1){
                    //console.log("Pushing to list: ", querySnapshot.docs[0].data());
                    data.push(querySnapshot.docs[0].data());  
                };
                if (l_index === deck_ids.length){
                    //console.log("Data being pushed: ", data);
                    setStateFunc(data);
                }
                l_index = l_index + 1;
            })
        };
    }

    const fetchAllDecksFromCurrentUser = async () => {
        let query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid);
        let decks = [];
        await query.get().then(querySnapshot => {
            for (const doc of querySnapshot.docs){
                decks.push(doc.data());
            }
        })
        return decks;
    }

    const fetchAllDecksFromCurrentUserInRealtime = async (setDecks) => {
        let query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid);
        let unsub = await query.onSnapshot(querySnapshot => {
            let decks = [];
            for (const doc of querySnapshot.docs){
                let d = doc.data();
                d['doc_id'] = doc.ref.id;
                decks.push(d);
            }
            setDecks(decks);
        });
        return unsub;
    }

    const fetchAllDecksFromCurrentUserInTargetLanguage = async (target_language) => {
        let query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', target_language);
        let decks = [];
        await query.get().then(querySnapshot => {
            for (const doc of querySnapshot.docs){
                decks.push(doc.data());
            }
        });
        return decks;
    }

    const fetchAllDecksFromCurrentUserInTargetLanguageInRealtime = async (target_language, setStateFunc, sortByValue) => {
        let query = null;
        let unsubscribe;
        if (sortByValue === undefined){
            query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', target_language).orderBy('last_updated_timestamp', "desc");
        }
        else {
            let baseQuery =  projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('target_ISO_639-1', '==', target_language);
            if (sortByValue === "last_updated_timestamp_desc"){
                query = baseQuery.orderBy('last_updated_timestamp', 'desc');
            } else if (sortByValue === "last_updated_timestamp_asc"){
                query = baseQuery.orderBy('last_updated_timestamp');
            }
            else if (sortByValue === "alphabetically"){
                query = baseQuery.orderBy('name');
            }
            else if (sortByValue === "created_timestamp_asc"){
                query = baseQuery.orderBy('created_timestamp');
            }
            else if (sortByValue === "created_timestamp_desc"){
                query = baseQuery.orderBy('created_timestamp', 'desc');
            }
            else if (sortByValue === "number_of_items"){
                query = baseQuery.orderBy('number_of_items', 'desc');
            } 
        }
        if (query !== null){
            unsubscribe = await query.onSnapshot(querySnapshot => {
                let decks = [];
                for (const doc of querySnapshot.docs){
                    decks.push(doc.data());
                }
                setStateFunc(decks);
            });
        }
        return unsubscribe;
    }

    const fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime = async (target_language, setStateFunc) => {
        const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories').where('target_ISO_639-1', '==', target_language);
        let unsub = await query.onSnapshot(querySnapshot => {
            let data = [];
            querySnapshot.docs.forEach((category, index)=>{
                data.push(category.data());
            })
            setStateFunc(data);
        });
        return unsub;
    }

    const fetchDecksFromCurrentUserInCategory = async (category_id, setStateFunc) => {
        //console.log("Variables: ", category_id, setStateFunc);
        const fetchDecks = async (deck_ids) => {
            let data = [];
            //console.log("IDS: ", deck_ids);
            let loop_finished = false;
            let loop_index = 0;
            for await (const deck_id of deck_ids){
                let d_query = projectFirestore.collection('decks').where('uid', '==', currentUser.uid).where('id', '==', deck_id);
                await d_query.get().then(querySnapshot=>{
                    //console.log(querySnapshot);
                    if (querySnapshot.docs.length === 1){
                        data.push(querySnapshot.docs[0].data());  
                    };
                })
            };
            setStateFunc(data);
            
        }

        const fetchEverything = async () => {
            //console.log("Category: ", category_id);
            const query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories').where('category_id', '==', category_id);
            let deck_ids=[];
            let deck_favorites=[];
            let finished = false;
            let unsub = await query.onSnapshot(querySnapshot => {
                //console.log("Length: ", querySnapshot.docs.length);
                if (querySnapshot.docs.length === 1) {
                    //console.log('Doing the work: ', querySnapshot.docs[0].data());
                    //console.log(querySnapshot.docs[0].data());
                    if ('decks' in querySnapshot.docs[0].data()) {
                        deck_ids = querySnapshot.docs[0].data()['decks']['id'];
                        deck_favorites = querySnapshot.docs[0].data()['decks']['favorite'];
                    };
                    //console.log("Sending in this array: ", deck_ids);
                    fetchDecks(deck_ids);
                };
            });
            
            return unsub;
            
        }

        let unsub = await fetchEverything();   
        return unsub;

    }

    const addSectionForCurrentUser = async (name, language) => {
        const handleAddSection = async () => {
            const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
            let section_id = Math.floor(Math.random() * 100000000);
            const checkIfExists = async (section_id) => {
                await collectionRef.where('section_id', '==', section_id).get().then(function(querySnapshot) {
                    if (!querySnapshot.empty) {
                        section_id = Math.floor(Math.random() * 100000000);
                        checkIfExists(section_id);
                    }
                    else {
                        if (name.length>0){
                            collectionRef.add({
                                'name': name, 
                                'section_id': section_id, 
                                'favorite': false, 
                                'target_ISO_639-1': language,
                                'created_timestamp': timeStamp
                            });
                        }
                    }
                }); 
            }
            checkIfExists(section_id);
        };

        handleAddSection();
    }

    const addCategoryForCurrentUser = async (name, section_id, language) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        let category_id = Math.floor(Math.random() * 100000000);
        const checkIfExists = (category_id) => {
            collectionRef.where('category_id', '==', category_id).get().then(function(querySnapshot) {
                if (!querySnapshot.empty) {
                    category_id = Math.floor(Math.random() * 100000000);
                    checkIfExists(category_id);
                }
                else {
                    if (name.length>0){
                        collectionRef.add({
                            'category_id': category_id,
                            'decks': {
                                'favorite': [],
                                'id': []
                            },
                            'name': name, 
                            'section_id': section_id, 
                            'favorite': false,
                            'target_ISO_639-1': language,
                            'created_timestamp': timeStamp
                        });
                    }
                }
            }); 
        }
        checkIfExists(category_id); 
    }

    const handleAddDeckForCurrentUser = async (name, category_id, source_language, target_language) => {
        const collectionRef = projectFirestore.collection('decks'); 
        let userdata = await fetchCurrentUserInfo();
        if (userdata === null){
            userdata = {};
            userdata['author'] = {}; 
            userdata['author']['username'] = null;
            userdata['author']['displayname'] = "";
        }

        if (!('author' in userdata)){
            userdata['author'] = {}; 
            userdata['author']['username'] = null;
            userdata['author']['displayname'] = "";
        }
        if (!('displayname' in userdata['author'])){
            userdata['author']['displayname'] = "";
        }
        if (!('username' in userdata['author'])){
            userdata['author']['displayname'] = "";
        }
        if (name.length>0){
            const generateDeckId =  projectFunctions.httpsCallable('generateDeckId');
            const results = await generateDeckId();
            let id = results['data'];
            await collectionRef.add({
                'name': name, 
                'uid': currentUser.uid,
                'id': id, 
                'typesense_deck_id': id,
                'source_ISO_639-1': source_language,
                'target_ISO_639-1': target_language, 
                'privacy': 'private', 
                'number_of_items': 0,
                'description': '', 
                'title': '', 
                'tags': '', 
                'items_doc_ids': [],
                'created_timestamp': timeStamp, 
                'last_updated_timestamp': timeStamp,
                'author': {
                    'username': userdata.author.username, 
                    'displayname': userdata.author['displayname']
                }
            });
            addDeckIdToCategoryForCurrentUser(id, category_id);
            return id;
        }

    }

    const handleAddDeckForCurrentUserWithoutCategory = async (name, source_language, target_language, privacy, type, parentFolderDocId, targetScript, sourceScript) => {
        const collectionRef = projectFirestore.collection('decks'); 
        let userdata = await fetchCurrentUserInfo();
        let authorMetadata = {};
        if (userdata === null){
            authorMetadata['username'] = null;
            authorMetadata['displayname'] = "";
        }
        if (userdata !== null && 'displayname' in userdata){
            authorMetadata['displayname'] = userdata['displayname'];
        }
        else {
            authorMetadata['displayname'] = "";
        }
        if ((userdata !== null && 'username' in userdata)){
            authorMetadata['username'] = userdata['username'];
        } 
        else {
            authorMetadata['username'] = "";
        }

        if (name.length>0){
            const generateDeckId =  projectFunctions.httpsCallable('generateDeckId');
            const results = await generateDeckId();
            let id = results['data'];
            let obj = {
                'name': name, 
                'uid': currentUser.uid,
                'id': id, 
                'typesense_deck_id': id,
                'source_ISO_639-1': source_language,
                'target_ISO_639-1': target_language, 
                'number_of_items': 0,
                'privacy': privacy, 
                'description': '',   
                'title': '', 
                'tags': '', 
                'created_timestamp': timeStamp, 
                'last_updated_timestamp': timeStamp,
                'author': {
                    'username': authorMetadata.username, 
                    'displayname': authorMetadata.displayname
                }
            };
            if (targetScript !== undefined && targetScript !== null){
                obj['target_script'] = targetScript;
            }
            if (sourceScript !== undefined && sourceScript !== null){
                obj['source_script'] = sourceScript;
            }
            let snapshot = await collectionRef.add(obj).catch(err=>{return false});
            if (type === "subitem" && snapshot !== false){
                // add to folder 
                console.log("Subitem!", snapshot.id);
                let f = projectFunctions.httpsCallable('moveItemToFolder');
                let r = await f({'item': {
                    'doc_id': snapshot.id, 
                    'content_type': 'deck'
                }, 
                    'destinationFolder': parentFolderDocId, 
                    'currentParent': "top_level"
                });
            }
            return id;
        }
    }
    

    const addDeckIdToCategoryForCurrentUser = async (deck_id, category_id) => {
        //console.log("Deck id", deck_id);
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        return docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'decks.id': firebase.firestore.FieldValue.arrayUnion(deck_id), 
                        [`decks.favorite.${deck_id}`]: false,
                        'last_updated_timestamp': timeStamp
                    });      
                });
            }
        }); 
    }

    const deleteDeckIdFromCategoryForCurrentUser = (deck_id, category_id) => {
        const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
        const docRef = collectionRef.where('category_id', '==', category_id);
        docRef.get().then(snapshots => {
            if (snapshots.size === 1){
                snapshots.forEach(snapshot => {
                    let doc_id = snapshot.id;
                    let updateRef = collectionRef.doc(doc_id);
                    updateRef.update({
                        'decks.id': firebase.firestore.FieldValue.arrayRemove(deck_id), 
                        [`decks.favorite.${deck_id}`]: firebase.firestore.FieldValue.delete(),
                        'last_updated_timestamp': timeStamp
                    });      
                });
            }
        }); 
    }


    const editDeckNameForCurrentUser = async (deck_id, new_name) => {
        if (new_name.length>0){
            const collectionRef = projectFirestore.collection('decks'); 
            const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', deck_id);
            await docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    //console.log("Saving ", new_name);
                    snapshots.forEach(snapshot => {
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': new_name,
                            'last_updated_timestamp': timeStamp 
                        });
                    });
                }
            }); 
            return true;
        }
    }

    const editCategoryNameForCurrentUser = async (category_id, new_name) => {
        if (new_name.length>0){
            const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('categories'); 
            const docRef = collectionRef.where('category_id', '==', category_id);
            await docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    //console.log("Saving ", new_name);
                    snapshots.forEach(snapshot => {
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': new_name,
                            'last_updated_timestamp': timeStamp 
                        });
                    });
                }
            }); 
            return true;
        }
        else {
            return false;
        }
        
    }

    const editSectionNameForCurrentUser = async (section_id, new_name) => {
        if (new_name.length>0){
            const collectionRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('decks').collection('sections'); 
            const docRef = collectionRef.where('section_id', '==', section_id);
            await docRef.get().then(snapshots => {
                if (snapshots.size === 1){
                    //console.log("Saving ", new_name);
                    snapshots.forEach(snapshot => {
                        let doc_id = snapshot.id;
                        let updateRef = collectionRef.doc(doc_id);
                        updateRef.update({
                            'name': new_name,
                            'last_updated_timestamp': timeStamp 
                        });
                    });
                }
            }); 
            return true;
        }
        return false;
    }

    const deleteDeck = async (deck_id) => {
        if (currentUser === null){
            return false;
        }
        let collectionRef = projectFirestore.collection("decks");
        const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', deck_id);
        let id = await docRef.get().then(snapshot=> {
            return snapshot.docs[0].ref.id;
        });
        return projectFirestore.collection("decks").doc(id).delete();
    }

    const deleteDeckFromDocId = async (deckDocId) => {
        if (currentUser === null){
            return false;
        }
        let collectionRef = projectFirestore.collection("decks");
        const docRef = collectionRef.doc(deckDocId);
        return docRef.delete();
    }

    const duplicateDeckApi = async (deck_id) => {
        const duplicateDeckFunc = projectFunctions.httpsCallable('duplicateDeck');
        let results = await duplicateDeckFunc({'deck_id': deck_id});
        return results;    
    }

    const duplicateDeckToMyLibraryApi = async (deck_id, name) => {
        const addDeckToMyLibraryFunc = projectFunctions.httpsCallable('addDeckToMyLibrary');
        return addDeckToMyLibraryFunc({'deck_id': deck_id, 'name': name});
    }

    const fetchDeckFromCurrentUser = async (deck_id) => {
        let query = projectFirestore.collection("decks").where("uid", "==", currentUser.uid).where("id", "==", deck_id);
        let metadata = await query.get().then(snapshot=>{
            if (snapshot.empty){
                return null;
            }
            let data = snapshot.docs[0].data();
            data['doc_id'] = snapshot.docs[0].ref.id;
            return data;
        });
        return metadata;
    }

    const fetchPublicDeck = async (deck_id) => {
        let query = projectFirestore.collection("decks").where("privacy", "==", "public").where("id", "==", deck_id);
        let metadata = await query.get().then(snapshot=>{
            if (snapshot.empty){
                return null;
            }
            let data = snapshot.docs[0].data();
            data['doc_id'] = snapshot.docs[0].ref.id;
            return data;
        });
        return metadata;
    }

    const fetchDeckFromCurrentUserRealtime = async (deck_id, setDeck, setError) => {
        let query = projectFirestore.collection("decks").where("uid", "==", currentUser.uid).where("id", "==", deck_id);
        let unsub = await query.onSnapshot(snapshot=>{
            if (snapshot.empty){
                setError(true);
            } else {
                let data = snapshot.docs[0].data();
                data['doc_id'] = snapshot.docs[0].ref.id;
                setDeck(data);
            }
        });
        return unsub;
    }

    const duplicateDeck = async (deck_id) => {
        if (currentUser === null){
            return false;
        }
        let collectionRef = projectFirestore.collection("decks");
        const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', deck_id);
        let doc_ref_id;
        let deck_data = await docRef.get().then(snapshot=> {
            doc_ref_id = snapshot.docs[0].ref.id;
            return snapshot.docs[0].data();
        });
        let query = null;
        if (newCardStructureBool){
            query = projectFirestore.collection("deck-cards");
        } else {
            query = collectionRef.doc(doc_ref_id).collection('items');
        }
        let items = await query.get().then(snapshot=>{
            let d = [];
            if (!snapshot.empty){
                for (const i of snapshot.docs){
                    let i_data = i.data();
                    i_data['created_timestamp'] = timeStamp;
                    i_data['last_updated_timestamp'] = timeStamp;
                    d.push(i_data);
                }
            };   
            return d;
        });
        let new_doc_ref_id = await addDuplicatedDeck(deck_data);
        return addItemsToDeck(new_doc_ref_id, items);
    }

    const addDuplicatedDeck = async (data) => {
        const collectionRef = projectFirestore.collection('decks'); 
        const generateDeckId =  projectFunctions.httpsCallable('generateDeckId');
        const results = await generateDeckId();
        let id = results['data'];
        data['id'] = id;
        data['name'] = "(DUPLICATE) " + data['name']; 
        data['last_updated_timestamp'] = timeStamp;
        const created_doc = await collectionRef.add(data);
        const doc_ref = created_doc.id;
        return doc_ref;
    }

    const addItemsToDeck = async (doc_ref_id, items) => {
        if (items.length > 0){
            for (const item of items){
                let query = null;
                if (newCardStructureBool){
                    query = projectFirestore.collection("deck-cards");
                } else {
                    query = projectFirestore.collection("decks").doc(doc_ref_id).collection('items');
                }
                await query.add(item);
            }
        }
        return true;
    }

    const fetchVocabularyMetadataInTargetLanguage = async (target) => {
        let colRef = projectFirestore.collection("users").doc(currentUser.uid).collection('private-data').doc("vocabulary").collection("languages").doc(target).collection("metadata");
        let d = {
            'items_doc_ids': [],
            'number_of_items': 0, 
            'active_items_doc_ids': [],
            'deleted_items_doc_ids': [],
            'number_of_active_items': 0, 
            'number_of_deleted_items': 0
        };
        await colRef.get().then((snapshot)=>{
            if (!snapshot.empty){
                snapshot.docs.forEach((doc)=>{
                    let data = doc.data();
                    if ('items_doc_ids' in data){
                        d['items_doc_ids'] = [...d['items_doc_ids'], ...data['items_doc_ids']];
                    }
                    if ('number_of_items' in data){
                        d['number_of_items'] = d['number_of_items'] + data['number_of_items'];
                    }
                    if ('active_items_doc_ids' in data){
                        d['active_items_doc_ids'] = [...d['active_items_doc_ids'], ...data['active_items_doc_ids']];
                    }
                    if ('deleted_items_doc_ids' in data){
                        d['deleted_items_doc_ids'] = [...d['deleted_items_doc_ids'], ...data['deleted_items_doc_ids']];
                    }
                    if ('number_of_active_items' in data){
                        d['number_of_active_items'] = d['number_of_active_items'] + data['number_of_active_items'];
                    }
                    if ('number_of_deleted_items' in data){
                        d['number_of_deleted_items'] = d['number_of_deleted_items'] + data['number_of_deleted_items'];
                    }
                })
            }
        });
        return d;
    }

    const fetchVocabularyInTargetLanguage = async (target, filter, itemsPerPage, docStartPoint, type) => {
        let colRef = null;
        let limitItems = itemsPerPage;
        let firstVisible = null;
        let lastVisible = null;
        colRef = projectFirestore.collection("users").doc(currentUser.uid).collection('private-data').doc("vocabulary").collection("languages").doc(target).collection("items");
        if (filter === "best"){
            colRef = colRef.orderBy("total_statistics.percentage.correct", "desc").orderBy("total_statistics.number_of_answers", "desc");
        } else if (filter === "worst"){
            colRef = colRef.orderBy("total_statistics.percentage.correct").orderBy("total_statistics.number_of_answers", "asc");
        }
        if (docStartPoint !== undefined && type !== undefined){
            if (type === "next"){
                colRef = colRef.startAfter(docStartPoint).limit(limitItems);
            } else if (type === "last"){
                colRef = colRef.endBefore(docStartPoint).limitToLast(limitItems);
            }
        }
        else {
            colRef = colRef.limit(limitItems);
        }
        let vocabulary = [];
        let numberOfAllSourcesDeletedItems = 0;
        if (colRef !== null){
            await colRef.get().then(snapshot=>{
                if (!snapshot.empty){
                    firstVisible = snapshot.docs[0];
                    lastVisible = snapshot.docs[snapshot.docs.length-1];
                    snapshot.docs.forEach((doc)=>{
                        let docData = doc.data();
                        if (!(docData.hasOwnProperty('all_sources_deleted') && docData['all_sources_deleted'] === true)){
                            vocabulary.push(docData);
                        } else {
                            numberOfAllSourcesDeletedItems = numberOfAllSourcesDeletedItems + 1;
                        }
                    });
                }
            });
        }
        
        return {'vocabulary': vocabulary, 'firstVisible': firstVisible, 'lastVisible': lastVisible, 'numberOfAllSourcesDeletedItems': numberOfAllSourcesDeletedItems};
    }

/*     const addVocab = async () => {
        let func = projectFunctions.httpsCallable("addVocabularyToAllUsers");
        let r = await func();
        return r;
    } */

    const moveDeck = async (newCategory, filteredCategories, deckId) => {
        for (const cat of filteredCategories){
            if (cat['decks'] !== undefined && cat['decks']['id'].includes(deckId)){
                console.log(cat);
                deleteDeckIdFromCategoryForCurrentUser(deckId, cat['category_id']);
            }
        }
        await addDeckIdToCategoryForCurrentUser(deckId, newCategory);
        return true;
    }

    const fetchSpecificCardsFromDecks = async (deck, chosenCards) => {
        let items = [];
        for (const card of chosenCards) {
            let query = projectFirestore.collection('decks').doc(card['deck_doc_id']).collection("items").doc(card['card_doc_id']);
            let d = await query.get().catch((err)=>{console.log(err);});
            if (!d.empty){
                let data = d.data();
                data['id'] = d.ref.id;
                data['deck_id'] = deck.id;
                items.push(data);
            }
        }
        return items;
    }

    const saveChangesToDeck = async ({deckId, deckName, sourceLanguage, privacy, publicTitle, publicDescription, youtubeVideoId, textId, targetLanguage, sourceLanguageScript, targetLanguageScript}) => {
        if (deckId === undefined || deckId === null || currentUser === null){return false}
        console.log("save: ", sourceLanguageScript, targetLanguageScript, targetLanguage)
        const collectionRef = projectFirestore.collection('decks');
        const docRef = collectionRef.where('uid', '==', currentUser.uid).where('id', '==', deckId);

        return docRef.get().then(async (snapshots)=>{
            if (snapshots.size === 1){
                let doc_id = snapshots.docs[0].id;
                let updateRef = collectionRef.doc(doc_id);
                let obj = {
                    'last_updated_timestamp': timeStamp,
                };
                if (sourceLanguage !== undefined && sourceLanguage !== null){
                    obj['source_ISO_639-1'] = sourceLanguage;
                }
                if (targetLanguage !== undefined && targetLanguage !== null){
                    obj['target_ISO_639-1'] = targetLanguage;
                }
                if (targetLanguageScript !== undefined && targetLanguageScript !== null){
                    obj['target_script'] = targetLanguageScript;
                }
                if (sourceLanguageScript !== undefined && sourceLanguageScript !== null){
                    obj['source_script'] = sourceLanguageScript;
                }
                if (deckName !== undefined && deckName !== null && deckName !== ""){
                    obj['name'] = deckName;
                }
                if (privacy !== undefined && privacy !== null){
                    obj['privacy'] = privacy;
                }
                if (publicTitle !== null && publicTitle !== undefined){
                    obj['title'] = publicTitle.trim();
                }
                if (publicDescription !== null && publicDescription !== undefined){
                    obj['description'] = publicDescription.trim();
                }
                if (youtubeVideoId !== null && youtubeVideoId !== undefined){
                    obj['youtube_id'] = youtubeVideoId.trim();
                }
                if (textId !== null && textId !== undefined){
                    obj['yalango_text_id'] = textId.trim();
                }
                
                await updateRef.update(obj);
                return true;
            };
        }).catch(()=>{
            return false;
        });
    }

    const saveChangesToDeckCard = async ({sourceWord, targetWord, cardDocId}) => {
        if (cardDocId === undefined || currentUser === null){return false}
        if (sourceWord === "" && targetWord === ""){return false}
        console.log(sourceWord, targetWord, cardDocId);
        const collectionRef = projectFirestore.collection('deck-cards');
        const docRef = collectionRef.doc(cardDocId);
        let obj = {
            'last_updated_timestamp': timeStamp,
        };

        if (sourceWord !== undefined && sourceWord !== null && sourceWord !== ""){
            obj['source'] = sourceWord; 
        }
        if (targetWord !== undefined && targetWord !== null && targetWord !== ""){
            obj['target'] = targetWord; 
        }
        console.log("Updating ", obj);
        return docRef.update(obj)
        .then(()=>{
            console.log("Success")
            return true;
        })
        .catch((err)=>{
            console.log(err)
            return false;
        });
    }

    const deleteDeckCard = async ({cardDocId}) => {
        if (cardDocId === undefined || cardDocId === null){return false}
        const cardRef = projectFirestore.collection("deck-cards").doc(cardDocId);
        return cardRef.delete()
        .then(()=>{
            return true;
        })
        .catch(err=>{
            return false
        });
    }

    return {deleteDeck, 
        duplicateDeckApi, 
        duplicateDeck, 
        duplicateDeckToMyLibraryApi,
        fetchAllDecksFromCurrentUserInTargetLanguageInRealtime, 
        handleAddDeckForCurrentUserWithoutCategory, 
        fetchAllDecksFromCurrentUser, 
        fetchAllDecksFromCurrentUserInTargetLanguage,
        fetchDecksFromDeckIdsForCurrentUser, 
        fetchDeckIdsFromCurrentUserInCategory, 
        editSectionNameForCurrentUser, 
        editCategoryNameForCurrentUser, 
        editDeckNameForCurrentUser, 
        handleAddDeckForCurrentUser, 
        addCategoryForCurrentUser, 
        addSectionForCurrentUser, 
        swapFavoriteStatusForSection, 
        swapFavoriteStatusForDeck, 
        swapFavoriteStatusForCategory, 
        fetchDeckCategoriesFromCurrentUserInSectionInTargetLanguage, 
        fetchDecksFromCurrentUserInCategory, 
        fetchDeckSectionsFromCurrentUserInTargetLanguage, 
        fetchAllPublicDecksDataFromOneUser, 
        fetchAllPublicCommunityDecksDataFromOneSourceLanguage, 
        fetchAllPublicCommunityDecksDataFromOneTargetLanguage, 
        fetchAllPublicCommunityDecksData, 
        fetchCommunityDecksDataFromOneLanguage, 
        fetchAllPublicDecksDataFromOneSourceLanguage, 
        fetchAllPublicDecksDataFromOneTargetLanguage, 
        fetchAllPublicYalangoDecksDataFromOneTargetLanguage, 
        fetchAllPublicYalangoDecksDataFromOneSourceLanguage, 
        fetchAllPublicYalangoDecksData, 
        fetchYalangoDecksDataFromOneLanguage, 
        fetchPublicDecksDataFromOneLanguage, 
        fetchAllPublicDecksData, 
        fetchAllItemsAndMetadataInPublicDeck, 
        fetchAllItemsInPublicDeck, 
        fetchAllPublicDecks, 
        fetchAllItemsInSingleDeck, 
        fetchAllItemsInCategory, 
        fetchAllItemsInSection, 
        addItemToDeck, 
        editItemInDeck, 
        fetchAllCategoriesFromCurrentUserInTargetLanguageInRealtime, 
        moveDeck, 
        fetchVocabularyInTargetLanguage, 
        fetchVocabularyMetadataInTargetLanguage,
        fetchAllItemsInUserDeckRealtime, 
        fetchDeckFromCurrentUser, 
        fetchDeckFromCurrentUserRealtime, 
        fetchDeckCustomFieldsFromCurrentUserRealtime, 
        saveNewCustomFieldApi, 
        editExistingCustomFieldApi, 
        fetchDeckCustomFieldsFromCurrentUser, 
        fetchSpecificCardsFromDecks, 
        getDeckAndXRandomCardsFromDeckApi, 
        getDecksAndXRandomCardsFromCategoryApi, 
        getDecksAndXRandomCardsFromSectionApi, 
        getObjectAndXRandomCardsFromTypeApi, 
        refreshObjectAndXRandomCardsFromTypeApi, 
        loadAllGameData, 
        refreshAllGameData, 
        fetchAllDecksFromCurrentUserInRealtime, 
        fetchPaginatedItemsInUserDeck, 
        refreshCardInDeck, 
        renameDeck, 
        deleteDeckFromDocId, 
        fetchPublicDeck, 
        uploadMultipleCardsToDeckApi, 
        saveChangesToDeck, 
        deleteCustomFieldApi, 
        saveChangesToDeckCard, 
        deleteDeckCard, 
        getXMistakesToReviewFromLastYDaysApi

    };

}