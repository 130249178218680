import React from 'react';
import { motion } from 'framer-motion';

const Icon = ({icon, color, hover, includeOpacityChange, tapAnimation, cursor, svg, ...iconProps}) => {

    let classString = "";
    let hoverScale = 1.05;
    let tapScale = 0.9;
    let cursorPointer = "cursor-pointer";
    let opacity = "opacity-70 hover:opacity-100";

    if (includeOpacityChange === false){
        opacity = "opacity-100";
    }

    if (color === "red"){
        classString += "text-red-500 ";
    }
    else if (color === "yellow"){
        classString += "text-yellow-500 ";
    }
    else if (color === "blue"){
        classString += "text-blue-500 ";
    }
    else if (color === "green"){
        classString += "text-green-500 ";
    }
    else if (color === "purple"){
        classString += "text-purple-500 ";
    }
    else if (color=== "black"){
        classString += "text-black ";
    }
    else if (color === "primary"){
        classString += "text-primary ";
    }
    else {
        classString += "text-" + color + " ";
    }

    if (hover === "none"){
        hoverScale=1.0;
    }

    if (cursor === "normal"){
        cursorPointer = "";
    }

    if (tapAnimation === false) {
        tapScale=1.0;
    }
    return (
        <>
            <motion.div whileHover = {{scale:hoverScale}} whileTap={{scale:tapScale}} className={cursorPointer + " transition-opacity duration-500 " + opacity + " " +classString} {...iconProps}>
                {(svg === undefined || svg === false) ?
                    <>
                        {icon}
                    </>
                :
                    <>
                        <img src={icon} alt="Yalango mascot" className="w-5/6 m-auto" />
                    </>
                }
            </motion.div>
        </>
    )
}

export default Icon;
