import React, {useState, useEffect} from 'react';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlay, faHeadSideBrain } from '@fortawesome/pro-solid-svg-icons';
import YalangoJourney from '../../assets/svg/yalango/YalangoJourney.svg';

const SRCard = () => {

    const [dbLoading, setDbLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showList, setShowList] = useState(false);
    const [activeCard, setActiveCard] = useState()
    const {refreshSpacedRepetitionQueue, 
        mostUrgentNextReviewItems
    } = useUserStatisticsContext();
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();

    useEffect(()=>{
        const fetchData = async () => {
            setDbLoading(true);
            await refreshSpacedRepetitionQueue(itemsPerPage);
            setDbLoading(false);
        }

        if (globalSelectedTargetLanguage !== null){
            //fetchData(); // not needed for now
        }

    },[globalSelectedTargetLanguage]);
    
    //{dbLoading && <Skeleton count={10} height={50} />}

    /*

                <div className="flex flex-row gap-8 items-start">
                       
                <div className="flex flex-col gap-4">
                    
                        
                    <div className="flex flex-col gap-1">
                       
                        {!dbLoading && globalSelectedTargetLanguage !== null && mostUrgentNextReviewItems !== null && mostUrgentNextReviewItems.hasOwnProperty(globalSelectedTargetLanguage) && mostUrgentNextReviewItems[globalSelectedTargetLanguage].length > 0 ?
                            <>
                                    <div className="flex flex-row justify-center w-full gap-4">
                                        <div>
                                            <div onClick={()=>setShowList(!showList)} className="text-white text-sm mb-4 flex flex-row justify-center  w-full gap-3 cursor-pointer">
                                                <div>
                                                    <FontAwesomeIcon icon={showList ? faChevronUp : faChevronDown}  />
                                                </div>
                                                <div>
                                                {showList ? "Hide" : "Show"} next {mostUrgentNextReviewItems[globalSelectedTargetLanguage].length} items in your queue.
                                                </div>
                                            </div>
                                            <div className={`${showList ? 'opacity-100 max-h-full ' : 'opacity-0 max-h-0 scale-0 '} h-full transform transition-all ease-in-out duration-300 ml-5 `}>
                                            {mostUrgentNextReviewItems[globalSelectedTargetLanguage].map((item, index) => {
                                                return (
                                                    <>
                                                        {item.type === "deck" &&
                                                            <div key={index} className="flex flex-row justify-between items-center p-2 rounded-md">
                                                                <div className="flex flex-col">
                                                                    <div className="text-base text-white font-semibold"><Link to={`/my-vocabulary/${globalSelectedTargetLanguage}/item/${item.id}`}>{item.target}</Link></div>
                                                                    <div className="text-sm text-white italic">{item.source}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {item.type === "table-row" &&
                                                            <>
                                                                <div className="flex flex-row justify-start p-2 w-full overflow-auto gap-2 text-base">
                                                                    {item.hasOwnProperty('row_values') && Object.entries(item.row_values).map((key, index)=>(
                                                                        <>
                                                                            <div key={"col_"+index}>
                                                                                {key[1]}
                                                                            </div>
                                                                        </>
                                                                        ))}
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div>
                                        
                                        </div>
                                    </div>
                                </>
                            :
                            <>
                            {!dbLoading &&
                            <div className="text-center text-white ">No items to review yet.</div>
                            }
                            </>                        
                        }
                        <div className="text-white text-center">   
                         {dbLoading && "Loading..."}
                         </div>
                    </div>
                </div>
                </div>
    */

    return (
        <>

            <div className="p-8 h-full  bg-gradient-to-r from-persian-green to-green-400 shadow-xl  w-full flex flex-col gap-6 rounded-xl transition-all ease-in-out duration-300">
                <div className="font-semibold text-lg text-white  flex flex-col gap-4">
                    {globalSelectedTargetLanguage === null ? <Skeleton count={1} /> : 
                        <>
                            
                                <div className="flex flex-row justify-center gap-3">
                                    <div>
                                        <FontAwesomeIcon icon={faHeadSideBrain} className="" />
                                    </div>
                                    <div>
                                        Spaced repetition
                                    </div>
                                </div>

                        </>
                    }
                
                </div>
                
            
                <div className="flex flex-col gap-3">
                
                        <img src={YalangoJourney} alt="Tim from Yalango." className="w-32 mx-auto" />
                     
                </div>
            </div>
        </>
    )
}

export default SRCard;