import React from "react";
import LanguageTools_zh from "./zh/LanguageTools_zh";


const SpecificLanguageTools = (props) => {
    const {language} = props;

    const components = {
        "zh": <LanguageTools_zh />,
    }
    return (
        <>
            {components.hasOwnProperty(language) && components[language]}
        </>
    );
};

export default SpecificLanguageTools;