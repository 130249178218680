import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useFeedback() {
    
    let {currentUser} = useAuth();

    const isLoggedIn = () => {
        if (currentUser !== null){
            return true;
        } else {
            return false;
        }
    }

    const addFeedbackToDatabase = async (text) => {
        let uid = "anonymous";
        if (currentUser !== null){
            uid = currentUser.uid;
        };
        const query = projectFirestore.collection('feedback').doc('general').collection('items');
        await query.add({
            'uid': uid,
            'text': text.toString(),
            'created_timestamp': timeStamp, 
            'number_of_messages': 0
        })
        return true;
    }

    const getCurrentUserFeedback = async () => {
        if (!isLoggedIn()){return};
        const query = projectFirestore.collection('feedback').doc('general').collection('items').where("uid", "==", currentUser.uid).orderBy('created_timestamp', 'desc');
        let docs = [];
        await query.get().then((snapshot)=>{
            if (!snapshot.empty){
                for (const doc of snapshot.docs){
                    if (doc.data().text !== ""){
                        let d = {};
                        d = doc.data();
                        d['doc_id'] = doc.ref.id;
                        docs.push(d);
                    } 
                }
            }
        });
        return docs;
    }

    const getFeedbackMessagesInRealtime = async (docId, setMessages) => {
        const query = projectFirestore.collection('feedback').doc('general').collection('items').doc(docId).collection("messages").orderBy('created_timestamp');
        return query.onSnapshot((snapshot)=>{
            let docs = [];
            if (!snapshot.empty){
                for (const doc of snapshot.docs){
                    docs.push(doc.data());
                }
            }
            setMessages(docs);
        });
    }

    const sendMessage = async (docId, body) => {
        const query = projectFirestore.collection('feedback').doc('general').collection('items').doc(docId).collection("messages");
        console.log(body);
        return query.add({
            'body': body,
            'uid': currentUser.uid, 
            'created_timestamp': timeStamp, 
        }).catch((err)=>{
            console.log(err);
        })
    }
    
    return {
        addFeedbackToDatabase, 
        getCurrentUserFeedback, 
        getFeedbackMessagesInRealtime, 
        sendMessage
    };
}