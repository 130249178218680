import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useLanguages() {
    let {currentUser} = useAuth();

    const getAllLanguages = async () => {
        const ref = projectFirestore.collection('languages').orderBy("name.en");
        let dict = {};
        let language_codes = []; 
        await ref.get().then((docs) => {
            docs.forEach((doc) => {
                dict[`${doc.data()['ISO_639-1']}`] = doc.data();
                language_codes.push(doc.data()['ISO_639-1']);
            })
        });

        return [dict, language_codes];
    }

    const getLastActiveTargetLanguageFromCurrentUser = async () => {
        if (currentUser === null){return null;}
        const docRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages');
        let snapshot = await docRef.get();
        let lastActive = null;
        if (snapshot.exists){
            let data = snapshot.data();
            if ('last_active_ISO_639-1' in data){
                lastActive = data['last_active_ISO_639-1'];
            }
        }
        return lastActive;
    }

    const updateLastActiveTargetLanguageForCurrentUser = async (lang) => {
        if (currentUser === null){return null;}
        const docRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages');
        let obj = {
            'last_active_ISO_639-1': lang
        };
        return docRef.set(obj, {merge: true});
    }

    const getRealtimeUserLanguagesFromUid = async (uid, setStateFunc) => {
        const colRef = projectFirestore.collection('users').doc(uid).collection('private-data').doc('languages').collection('ISO_639-1'); 

        colRef.onSnapshot((snapshot) => {
            let doc_list = [];
            snapshot.docs.forEach((doc) => {
                doc_list.push(doc.data());
            });
            //console.log(doc_list);
            setStateFunc(doc_list);
            return doc_list;
        });  
    }

    const getRealtimeUserLanguagesFromCurrentUser = async (setStateFunc) => {
        const colRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1'); 

        colRef.onSnapshot((snapshot) => {
            let doc_list = [];
            snapshot.docs.forEach((doc) => {
                doc_list.push(doc.data());
            });
            //console.log(doc_list);
            setStateFunc(doc_list);
            return doc_list;
        });  
    }

    const getUserLanguagesFromUid = async (uid) => {
        const colRef = projectFirestore.collection('users').doc(uid).collection('private-data').doc('languages').collection('ISO_639-1'); 

        let doc_list = await colRef.get().then((snapshot) => {
            let doc_list = [];
            snapshot.docs.forEach((doc) => {
                doc_list.push(doc.data());
            });
            //console.log(doc_list);
            return doc_list;
        }).catch((error)=>{
            //console.log(error);
        });  

        return doc_list;
    }

    const getUserLanguages = async () => {
        const colRef = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1'); 

        let doc_list = await colRef.get().then((snapshot) => {
            let doc_list = [];
            snapshot.docs.forEach((doc) => {
                let metadata = doc.data();
                if (!('level' in metadata)){
                    metadata['level'] = null;
                }
                if (!('currently_studying' in metadata)){
                    metadata['currently_studying'] = false;
                }
                doc_list.push(metadata);
            });
            //console.log(doc_list);
            return doc_list;
        }).catch((error)=>{
            //console.log(error);
        });  

        return doc_list;
    }
    
    const updateLanguages = async (new_data, languagesToBeDeleted) => {
        console.log("TO be deleted: ", languagesToBeDeleted);
        for (let item of new_data){
            let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1').where('target_language', '==', item.target_language);
            let doc_id = await query.get().then((snapshots)=>{
                if (snapshots.docs.length>0){
                    //console.log("Found something: ", snapshots.docs[0].id, item.target_language);
                    return snapshots.docs[0].id;
                }
                else {
                    return null;
                }   
            });
            if (doc_id !== null){
                let update_query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1').doc(doc_id);
                await update_query.set(item);
            }
            else {
                // create instead
                let create_query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1');
                await create_query.add(item);
            }
        }

        for (const delItem of languagesToBeDeleted){
            let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1').where('target_language', '==', delItem);
            await query.get().then((snapshots)=>{
                if (snapshots.docs.length>0){
                    //console.log("Found something: ", snapshots.docs[0].id, item.target_language);
                    return snapshots.docs[0].ref.delete();
                }
                else {
                    return null;
                }   
            }).catch(error=>{
                console.log("Error: ", error);
            });
        }
        return true;
    }

    const addNewLanguages = async (new_data) => {
        for (let item of new_data){
            let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1').where('target_language', '==', item.target_language);
            let doc_id = await query.get().then((snapshots)=>{
                if (snapshots.docs.length>0){
                    return snapshots.docs[0].id;
                }
                else {
                    return null;
                }   
            }).catch(error=>{
                return "Something went wrong.";
            });
            if (doc_id === null){
                let create_query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1');
                await create_query.add({
                    'target_language': item.target_language, 
                    'source_language': item.source_language, 
                    'created_timestamp': timeStamp,
                    'level': 'A1',
                    'currently_studying': true,
                    'name': {
                        'en': item.name
                    }
                })
                .catch(error=>{
                    return "Something went wrong.";
                });
            }
        }
        return true;
    }

    const addNewLanguagesToWishlist = async (new_data) => {
        for (let item of new_data){
            let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1').where('target_language', '==', item.target_language);
            let doc_id = await query.get().then((snapshots)=>{
                if (snapshots.docs.length>0){
                    return snapshots.docs[0].id;
                }
                else {
                    return null;
                }   
            }).catch(error=>{
                return "Something went wrong.";
            });
            if (doc_id === null){
                let create_query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('languages').collection('ISO_639-1');
                await create_query.add({
                    'target_language': item.target_language, 
                    'source_language': item.source_language, 
                    'created_timestamp': timeStamp,
                    'level': 'zero',
                    'currently_studying': false,
                    'name': {
                        'en': item.name
                    }
                })
                .catch(error=>{
                    return "Something went wrong.";
                });
            }
        }
        return true;
    }

    return {
        getAllLanguages, 
        getRealtimeUserLanguagesFromUid, 
        getUserLanguagesFromUid, 
        getUserLanguages, 
        updateLanguages, 
        addNewLanguages, 
        getRealtimeUserLanguagesFromCurrentUser, 
        addNewLanguagesToWishlist, 
        updateLastActiveTargetLanguageForCurrentUser,
        getLastActiveTargetLanguageFromCurrentUser
    };
}
