import React, {useEffect, useState} from 'react';
import useQueryStrings from '../../hooks/useQueryStrings';
import queryString from 'query-string';
import TranslatorWidget from '../../components/widgets/translator/TranslatorWidget';
import YalangoMovie from '../../assets/svg/yalango/YalangoMovie.svg';
import Skeleton from 'react-loading-skeleton';
import {useNavigate, useLocation} from 'react-router-dom';
import ImportCSVToDeck from '../decks/mydecks/ImportCSVToDeck';
import {motion} from 'framer-motion'; 
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WatchYouTube = () => {
    //const {getQueryStrings} = useQueryStrings();
    const navigate = useNavigate();
    const location = useLocation();
    const [ytId, setYtId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [urlInput, setUrlInput] = useState("");
    const [page, setPage] = useState("search");

    const [showCreateDeckFeature, setShowCreateDeckFeature] = useState(false);
    const [showTranslator, setShowTranslator] = useState(false);

    const findYouTubeIdInUrl = (url) => {
        let normalsplit = url.split("watch?v=");
        if (normalsplit.length === 2){
            let temp_id = normalsplit[1];
            temp_id = temp_id.split("&")[0];
            return temp_id;
        } else {
            let shortersplit = url.split("youtu.be/");
            if (shortersplit.length === 2){
                let temp_id = shortersplit[1];
                temp_id = temp_id.split("?")[0];
                return temp_id; 
            }
        }
        return null;
    }

    const urlInputOnChange = (e) => {
        let url = e.target.value;
        setUrlInput(url);
        let id = findYouTubeIdInUrl(url);
        if (id !== null && id !== undefined){
            navigate("/watch?v="+id);
        }
    }

    const changeVideoClick = () => {
        navigate("/watch");
    }

    useEffect(()=>{
        const getQueryStrings = () => {
            const parsed_strings = queryString.parse(location.search);
            return parsed_strings;
        }

        const fetchData = async () => {
            setLoading(true);
            let r = getQueryStrings();
            if ('v' in r){
                setYtId(r['v'].split("&")[0]);
                setPage("video");
            } else {
                setPage("search");
                setYtId(null);
                setUrlInput("");
            }
            setLoading(false);
            setTimeout(()=>{window.scrollTo(0, 0)},100);
        }

        fetchData();

    },[location]);

    //
    return (
        <>
            <div className="  min-h-screen h-full flex flex-col gap-4">
                {page === "video" &&
                    <div onClick={changeVideoClick} className="text-xs cursor-pointer underline">
                        Change video
                    </div>
                }
                
                {!loading ?
                    <div className="flex h-full flex-col justify-center place-items-center gap-4">
                        {page === "search" &&
                            <>
                                <h1>Watch YouTube</h1>
                                <div className="h-full flex w-5/6 m-auto flex-col justify-center">
                                    <div>
                                        <input  onChange={urlInputOnChange} value={urlInput} type={"text"} className="focus:border-light-blue-500 text-center focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 px-2 m-2" placeholder={"Paste a YouTube URL"}></input>
                                    </div>
                                </div>
                                <div>
                                    <img src={YalangoMovie} alt="Yalango movie" className="w-5/6 m-auto p-4 sm:w-96 mx-auto my-0" />
                                </div>
                            </>
                        } 
                        {page === "video" &&
                            <>
                                <div className="flex w-full flex-col lg:flex-row justify-center gap-2">
                                    <div className="flex w-full flex-row justify-center my-4 h-48 sm:h-96 md:h-112 lg:h-112">
                                        <div className="rounded-2xl overflow-hidden shadow-2xl shadow-persian-green w-full sm:w-5/6 m-auto h-full">
                                            <iframe className="aspect-video w-full h-full" src={"https://www.youtube-nocookie.com/embed/"+ytId} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-4">
                                        <div className="w-full flex flex-col place-items-center gap-0">
                                            <motion.div onClick={()=>setShowTranslator(!showTranslator)} whileHover={{scale:1.01}} className="bg-charcoal cursor-pointer text-white w-full text-center p-4 rounded-xl shadow-2xl flex flex-row justify-center gap-6 place-items-center font-bold">
                                                <div>
                                                    Translator
                                                </div>
                                                <div className="opacity-70 text-xs">
                                                    <FontAwesomeIcon icon={showTranslator ? faArrowUp : faArrowDown} />
                                                </div>
                                            </motion.div>
                                            <div className={(showTranslator ? "visible opacity-100 transition-opacity  transition-duration-500 ease-in-out " : "hidden opacity-0 transition-opacity  transition-duration-500 ease-in-out ") + " w-full bg-white rounded-xl shadow-2xl p-6 sm:p-8"}>
                                                <TranslatorWidget showHeadline={false} />
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-col place-items-center gap-0">
                                            <motion.div onClick={()=>setShowCreateDeckFeature(!showCreateDeckFeature)} whileHover={{scale:1.01}} className="bg-charcoal cursor-pointer text-white w-full text-center p-4 rounded-xl shadow-2xl flex flex-row justify-center gap-6 place-items-center font-bold">
                                                <div>
                                                    Create a deck with words from this video
                                                </div>
                                                <div className="opacity-70 text-xs">
                                                    <FontAwesomeIcon icon={showCreateDeckFeature ? faArrowUp : faArrowDown} />
                                                </div>
                                            </motion.div>
                                            
                                            <div className={(showCreateDeckFeature ? "visible opacity-100 transition-opacity  transition-duration-500 ease-in-out " : "hidden opacity-0 transition-opacity  transition-duration-500 ease-in-out ") + " w-full  bg-white rounded-xl shadow-2xl p-6 sm:p-8"}>
                                                <ImportCSVToDeck type="table" ytId={ytId} hideTitles={true} mode="create-deck" design="embed" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                           
                            </>
                        }
                    </div>
                : 
                    <>
                        <div className="">
                            <Skeleton count={1} height={150} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default WatchYouTube;