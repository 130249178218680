import React, {useState, useEffect} from 'react';
import useStatistics from '../../../../hooks/useStatistics';
import useTables from '../../../../hooks/useTables';
import TableFlashcardsGame from '../../../tables/games/flashcards/TableFlashcardsGame';
import CardGrid from '../../cards/CardGrid';

const MixGame = ({reviewFinished, setReviewFinished, isPremium, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, score, setScore, gameScreen, setGameScreen, audioLoading, autoPlayPronunciations, ScoreScreen, FlashcardsGame, QuizGame, InputGame,  googleTextToSpeechClick, setCards, playMode, setPlayMode, mistakes, setMistakes, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, playModeArray, setPlayModeArray, id, type}) => {
    const [deckGames, setDeckGames] = useState(['flashcards', 'quiz', 'input']);
    const [tableGames, setTableGames] = useState(['table-flashcards']);
    const [chosenGames, setChosenGames] = useState(null);
    const [tableColumnsHelper, setTableColumnsHelper] = useState(null);
    const [tableRowsHelper, setTableRowsHelper] = useState(null);
    const [deckCardsHelper, setDeckCardsHelper] = useState(null);

    const {collectTableFlashcardsStatistics} = useStatistics();
    const [minimumNumberOfQuizItems, setMinimumNumberOfQuizItems] = useState(4);  //need this to play

    useEffect(()=>{
        const asyncClick = async () => {
            await googleTextToSpeechClick(cards[currentCardIndex]);
        }
        if (autoPlayPronunciations === true && currentCardIndex !== null && cards !== null && !audioLoading && playMode === "target_first"){
            asyncClick();
        }
    },[autoPlayPronunciations, currentCardIndex, cards, playMode]);

    const handleCorrectAnswer = () => {
        // stat collection is done in each game
        setScore(score+1);
        setNbOfAnswers(nbOfAnswers+1);
        if (cardsLeft > 1){
            setCurrentCardIndex(currentCardIndex+1);
            setCardsLeft(cardsLeft-1);
        } else {
            handleEnd();
        }
    }

    const handleWrongAnswer = () => {
        setNbOfAnswers(nbOfAnswers+1);
        if (cardsLeft > 1){
            setCurrentCardIndex(currentCardIndex+1);
            setCardsLeft(cardsLeft-1);
        } else {
            handleEnd();
        }
    }

    const handleSkipAnswer = () => {
        if (cardsLeft > 1){
            setCurrentCardIndex(currentCardIndex+1);
            setCardsLeft(cardsLeft-1);
            setNbOfAnswers(nbOfAnswers+1);
        } else {
            handleEnd();
        }
    }

    const handleEnd = () => {
        setGameScreen("endscreen");
    }

    const playAgain = async () =>{
        await refreshData();
        setCardsLeft(cards.length);
        setScore(0);
        setCurrentCardIndex(0);
        setNbOfAnswers(0);
        setGameScreen("game");
    }

useEffect(()=> {
        if (cards === null || cards === undefined ){return undefined}
        if (currentCardIndex === null || currentCardIndex === undefined){return undefined}
        if (!(cards.length > currentCardIndex)){return undefined}
        let thisCard = cards[currentCardIndex];
        console.log(thisCard);
        if (thisCard.hasOwnProperty("type")){
            if (thisCard.type === "table-row"){
                if (thisCard.hasOwnProperty("table_columns") && thisCard.hasOwnProperty("table_column_order")){
                    let orderedList = [];
                    thisCard.table_column_order.forEach((col, index)=>{
                        if (thisCard.table_columns.hasOwnProperty(col)){
                            orderedList.push({...thisCard.table_columns[col], 'id': col});
                        }
                    });
                    setTableColumnsHelper(orderedList);
                }
                //setTableRowsHelper
                let obj = {};
                if (thisCard.hasOwnProperty("row")){
                    obj = {...thisCard.row}; 
                    obj['table_id'] = thisCard['table']['id'];
                    obj['doc_id'] = thisCard['row_doc_id'];
                }
                setTableRowsHelper([obj]);
            }
        }

    },[cards, currentCardIndex]);

    useEffect(()=>{
        function randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
        console.log("Mix game cards: ", cards);
        if (cards !== null && cards !== undefined && minimumNumberOfQuizItems !== null){
            setCardsLeft(cards.length);
            let numberOfDeckCards = 0;
            let deckCardsTemp = [];
            for (const card of cards){
                if (card.hasOwnProperty("type") && card['type'] === "deck"){
                    numberOfDeckCards++;
                    deckCardsTemp.push(card);
                }
                else if (card.hasOwnProperty("deck_name") && card.hasOwnProperty('deck_id')){
                    //deck card in game outside of vocabulary games
                    numberOfDeckCards++;
                    deckCardsTemp.push(card);
                }
            }
            setDeckCardsHelper(deckCardsTemp);
            let list = [];
            console.log("Number of deck cards: ", numberOfDeckCards);
            let chosenSpacedRepetitionPlayModes = [];
            let wordLevels = [];
            let cardIndex = 0;
            for (const card of cards){
                if (card.hasOwnProperty("spaced_repetition_current_index")){
                    let spacedRepetitionIndex = card['spaced_repetition_current_index'];
                    wordLevels.push(spacedRepetitionIndex);
                    if (card.type === "deck"){
                        if (spacedRepetitionIndex === 0){
                            if (numberOfDeckCards >= minimumNumberOfQuizItems){
                                list.push("quiz");
                            } else {
                                list.push("flashcards");
                            }
                            chosenSpacedRepetitionPlayModes.push("target_first");
                        }
                        else if (spacedRepetitionIndex < 4){
                            if (numberOfDeckCards >= minimumNumberOfQuizItems){
                                let possibleGames = ["flashcards", "quiz"];
                                let random = randomIntFromInterval(0, 1);
                                console.log("Random: ", random);
                                list.push(possibleGames[random]);
                            } else {
                                list.push("flashcards");
                            }
                            chosenSpacedRepetitionPlayModes.push("target_first");
                        } 
                        else if (spacedRepetitionIndex < 7) {
                            // 4 or more
                            let possibleGames = ["flashcards", "input"];
                            let random = randomIntFromInterval(0, 1);
                            console.log("Random: ", random);
                            list.push(possibleGames[random]);
                            chosenSpacedRepetitionPlayModes.push("source_first");
                        }
                        else {
                            list.push("input");
                            chosenSpacedRepetitionPlayModes.push("source_first");
                        }
                    }
                    else if (card.type === "table-row"){
                        let random = randomIntFromInterval(0, tableGames.length-1);
                        list.push(tableGames[random]);
                    }
                } else { 
                    if (card.type === "deck" || (card.hasOwnProperty("deck_name") && card.hasOwnProperty('deck_id'))){
                        if (numberOfDeckCards >= minimumNumberOfQuizItems){
                            let random = randomIntFromInterval(0, deckGames.length-1);
                            list.push(deckGames[random]);
                        } else {
                            let possibleGames = ["flashcards", "input"];
                            let random = randomIntFromInterval(0, possibleGames.length-1);
                            console.log("Random: ", random);
                            list.push(possibleGames[random]);
                        }
                    }
                    else if (card.type === "table-row"){
                        let random = randomIntFromInterval(0, tableGames.length-1);
                        list.push(tableGames[random]);
                    }
                    chosenSpacedRepetitionPlayModes.push(playModeArray[cardIndex]);
                    wordLevels.push(null);
                }
                cardIndex++;
            }
            setPlayModeArray(chosenSpacedRepetitionPlayModes);
            console.log("Games & play modes & word levels: ", list, chosenSpacedRepetitionPlayModes, wordLevels);
            setChosenGames(list);
        }
    },[howManyItems, cards, deckGames, tableGames, minimumNumberOfQuizItems]);

    //on flashcards and input before: cards={[cards[currentCardIndex]]}

    const collectTableStatisticsHelper = async ({correct, row}) => {
        await collectTableFlashcardsStatistics({correct, row, tableColumns: tableColumnsHelper, table: cards[currentCardIndex]['table'], howManyItems, highlightColumnId: cards[currentCardIndex]['table_column_order'][0], gamePlayedFrom: "mix"});
    }

    console.log(chosenGames, deckCardsHelper, chosenGames);

    /*
<div className="flex flex-row justify-center gap-6">
                                            <div>
                                                <span className="text-gray-600 font-bold">Score</span>: {score}
                                            </div>
                                            <div>
                                                <span className="text-gray-600 font-bold">Items left</span>: {cardsLeft}
                                            </div>
                                        </div>
    */

    return (
        <>
            {(loadingItems === false && showLoadingScreen === false) &&
                <>
                    {(chosenGames !== null && deckCardsHelper !== null && chosenGames.length === cards.length) &&
                        <>
                            <div className="flex flex-col justify-start h-full gap-8">
                                {gameScreen === "game" && 
                                    <>
                                        
                                        {chosenGames[currentCardIndex] === "flashcards" &&
                                            <>
                                                <FlashcardsGame playModeArray={playModeArray} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} isPremium={isPremium} cards={cards} isInMix={true} globalHandleSkipAnswer={handleSkipAnswer} globalHandleCorrectAnswer={handleCorrectAnswer} globalHandleWrongAnswer={handleWrongAnswer} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} setCards={setCards} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} />
                                            </>
                                        }
                                        {chosenGames[currentCardIndex] === "quiz" &&
                                            <>
                                                <QuizGame playModeArray={playModeArray} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} isPremium={isPremium} isInMix={true} globalHandleSkipAnswer={handleSkipAnswer} globalHandleCorrectAnswer={handleCorrectAnswer} globalHandleWrongAnswer={handleWrongAnswer} globalChosenCard={cards[currentCardIndex]}  audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={deckCardsHelper} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} />
                                            </>
                                        }
                                        {chosenGames[currentCardIndex] === "input" &&
                                            <>
                                                <InputGame playModeArray={playModeArray} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} isPremium={isPremium} isInMix={true} cards={cards} globalHandleSkipAnswer={handleSkipAnswer} globalHandleCorrectAnswer={handleCorrectAnswer} globalHandleWrongAnswer={handleWrongAnswer}  audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type}  />
                                            </>
                                        }
                                        {chosenGames[currentCardIndex] === "table-flashcards" &&
                                            <>
                                                <TableFlashcardsGame playModeArray={playModeArray} isInMix={true} globalHandleSkipAnswer={handleSkipAnswer} globalHandleCorrectAnswer={handleCorrectAnswer} globalHandleWrongAnswer={handleWrongAnswer} id={id} itemsLeft={cardsLeft} setItemsLeft={setCardsLeft} collectStatistics={collectTableStatisticsHelper} highlightColumnId={cards[currentCardIndex]['table_column_order'][0]} setGameScreen={setGameScreen} gameScreen={gameScreen} setNbOfAnswers={setNbOfAnswers} nbOfAnswers={nbOfAnswers} setScore={setScore} score={score} table={cards[currentCardIndex]['table']} tableRows={tableRowsHelper} tableColumns={tableColumnsHelper} backToPlayPageLink={backToPlayPageLink} />
                                            </>
                                        }
                                    </>
                                }
                                {gameScreen === "endscreen" && 
                                    <>
                                        <div className="">
                                            <ScoreScreen reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} score={score} nbOfAnswers={cards.length} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgain} />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default MixGame;